import { useStateMachine } from 'little-state-machine';
import Utils from '@utils/utils';
import regex from '@utils/regex';
import { Labels } from '@/types/i18n';
import { Customer } from '@/types';

export type TranslationFunction = {
  t: (key: string, isPrivacy?: boolean, staticCustomer?: Customer) => string
}

const useTranslation = () => {
  const { state } = useStateMachine();

  return function t(key: string, isPrivacy?: boolean, staticCustomer?: Customer): string {
    const currentI18n: Labels = isPrivacy ? state.translations.privacyLabels : state.translations.lightLabels;
    // customer language is not available in fetched languages
    if (!currentI18n) {
      return key;
    }
    let translation = currentI18n[key];
    // translation map doesn't contain the translation key
    if (translation === undefined || translation === null) {
      return key;
    }
    // replace some placeholders in label
    const interpolation = Utils.buildInterpolation(state, staticCustomer);
    Object.keys(interpolation.placeholders).forEach((placeholder) => {
      translation = translation.replace(`${interpolation.interpolation.prefix}${placeholder}${interpolation.interpolation.suffix}`, interpolation.placeholders[placeholder]);
    });
    // We remove ^ at the beginning of the chain,
    // / We remove $ at the end of the chain
    // We add parenthesis to use it as a reference with $1
    // We add a g flag to replace all the occurrences found
    const globalEmailRegex = new RegExp(`(${regex.email.source.slice(1).slice(0, -1)})`, 'gi');
    translation = translation.replace(globalEmailRegex, '<span style="line-break: anywhere;">$1</span>');
    return translation;
  };
};

export default useTranslation;
