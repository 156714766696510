import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './index.css';
import App from './App';

declare global {
  interface Window {
    Kakao: {
      isInitialized: () => boolean,
      init: (kakaoJsKey: string) => boolean,
      Auth: {
        authorize: (params: { redirectUri: string }) => void,
      }
    };
  }
}

(async function boot() {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
}());
