import React from 'react';
import { StyledLogo } from '@pages/Registered/registered.styles';
import { EventStyledText, StyledText } from '@pages/Error/error.styles';
import { useStateMachine } from 'little-state-machine';
import { MainWrapper } from '@pages/Error/components/MainWrapper';
import { ThemeProvider } from 'styled-components/macro';
import QueueManagementRedirectButton, { QueueCreationState } from '@/common/components/QueueManagementRedirectButton';
import { ImgLogo } from '@/common/components';
import BrandContainer from '@/common/components/containers/BrandContainer';

export const BrandedWrapper: React.FC = ({ children }) => {
  const { state } = useStateMachine();
  const { error, event, brand } = state;
  const { defaultLogo } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  const { errorType } = error;
  return (
    <ThemeProvider theme={{ isOnErrorPage: true }}>
      <MainWrapper>
        <BrandContainer error={Boolean(errorType)}>
          <StyledLogo><ImgLogo id="brandLogo" src={defaultLogoUrl} alt="Logo" /></StyledLogo>
          {event.isCustomJourney
            ? (
              <EventStyledText id={errorType}>
                {children}
              </EventStyledText>
            )
            : <StyledText id={errorType}>{children}</StyledText>}
          <QueueManagementRedirectButton clientRegistrationStatus={QueueCreationState.ERROR} />
        </BrandContainer>
      </MainWrapper>
    </ThemeProvider>
  );
};
