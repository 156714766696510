import { UseFormMethods } from 'react-hook-form';
import React, { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import Utils from '@utils/utils';
import { Translations } from '@/types/translations';
import { InformationFormProps, KeyOfInformationFormProps } from '@/types/form/informationFormProps';

export default class IdentityService {
  static removeAlphaCharactersAndFocusNextElementOnMaxLength(event: React.ChangeEvent<HTMLInputElement>, maxLength: number, elementName: KeyOfInformationFormProps, formMethods: UseFormMethods<InformationFormProps>, setShowDateError: Dispatch<SetStateAction<boolean>>) {
    const valueWithoutAlphaCharacters = event.target.value.replace(/[^\d]/g, '');
    if (valueWithoutAlphaCharacters !== event.target.value) {
      formMethods.setValue(elementName, valueWithoutAlphaCharacters);
    }
    if (elementName === 'year') {
      setShowDateError(false);
    }
    if (event.target.value.length >= maxLength) {
      const { form } = event.target;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (elementName === 'year') {
        (form?.elements[index] as HTMLElement).blur();
      } else {
        (form?.elements[index + 1] as HTMLElement).focus();
      }
      event.preventDefault();
    }
  }

  static checkDateFormat(formMethods: UseFormMethods<InformationFormProps>): boolean {
    const day = Number(formMethods.getValues('day'));
    const month = Number(formMethods.getValues('month'));
    const year = Number(formMethods.getValues('year'));
    const dayString = String(formMethods.getValues('day'));
    const monthString = String(formMethods.getValues('month'));
    const yearString = formMethods.getValues('year');

    // Create list of days of a month
    const maxDayNumberByMonth = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const maxMonthNumber = 12;

    // If user didn't filled day or/and month inputs we don't display any error. Validation button will be disabled anyway because day and month are mandatory fields
    // Use string to check for empty input, instead of number considering it as a 0
    if (dayString === '' || monthString === '') return true;

    // max and min values allowed for month
    if (month > maxMonthNumber || month <= 0 || day <= 0) return false;

    // fix minimal birth year to 1900
    if (yearString && yearString < 1900) return false;

    // year input is not filled (yet) or month value is anything except february
    if (!year || parseInt(month.toString(), 10) === 1 || month > 2) return day <= maxDayNumberByMonth[month - 1];

    // february case
    const isLeapYear = (!(year % 4) && year % 100) || !(year % 400);
    // if current year is not a leap year, february has 28 days. Value 29 is not accepted
    if (!isLeapYear && (day >= 29)) return false;

    // if current year is a leap year, february has 29 days
    if (isLeapYear && (day > 29)) return false;

    // If input is NaN
    if (Number.isNaN(day) || Number.isNaN(month) || Number.isNaN(year)) return false;

    // date is valid
    return true;
  }

  static checkMinimalAge(formMethods: UseFormMethods<InformationFormProps>, minimalAge: number): boolean {
    const day = formMethods.getValues('day');
    const month = formMethods.getValues('month');
    const year = formMethods.getValues('year');

    // If user didn't filled day or/and month inputs we don't display any error. Validation button will be disabled anyway because day and month are mandatory fields
    if (!day || !month) {
      return true;
    }
    // Case when day, month and year are filled
    if (year) {
      const nowDate = new Date();
      const selectedDate = new Date(year, month - 1, day);
      const minDateAllowed = moment(nowDate).subtract(minimalAge, 'years');

      if (moment(selectedDate) > minDateAllowed) {
        return false;
      }
    }
    // If year is empty we don't check minimal age at all. Mandatory case is handled by year component itself
    return true;
  }

  static getCountryCodeFromNationalityTranslation(nationality: string, translations: Translations) {
    let countryCodeFromNationalityTranslation = '';
    if (nationality) {
      for (const [key, label] of Object.entries(translations.lightLabels)) {
        if (label === nationality) {
          countryCodeFromNationalityTranslation = key.split('.')[1].toUpperCase();
          break;
        }
      }
    }
    return countryCodeFromNationalityTranslation;
  }

  static avoidAlphaCharacters(event: React.KeyboardEvent<HTMLInputElement>) {
    if (!(Utils.isDigit(event.key) || Utils.isAllowedKey(event.key))) {
      event.preventDefault();
    }
  }

  static avoidInputIfTooLong(event: React.KeyboardEvent<HTMLInputElement>, value: number, length: number) {
    const stringValue = value.toString();
    if (stringValue && stringValue.length >= length && !Utils.isAllowedKey(event.key)) {
      event.preventDefault();
    }
  }

  static fillMinimalLength(elementName: KeyOfInformationFormProps, value: number, length: number, formMethods: UseFormMethods<InformationFormProps>) {
    const stringValue = value.toString();
    if (stringValue && stringValue.length < length) {
      formMethods.setValue(elementName, `0${value}`);
    }
  }
}
