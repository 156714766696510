export enum keyboardInputs {
  DELETE = 'Delete',
  BACKSPACE = 'Backspace',
  SHIFT = 'Shift',
  TAB = 'Tab',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  ENTER = 'Enter'
}

export enum keyboardDigitInputs {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  EIGHT = '8',
  NINE = '9'
}
