import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';

export const Logo = styled.div`
  position: relative;
  left: 0;
  margin-top: 50px;
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    margin-top: -27px; // must be the same height as the change language component so that they are aligned
  }

  ${theme('brand', {
    BAL: css`
      text-align: left;
      padding: 0 16px;
      margin-top: 0;
      height: 16px;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 0;
      }
    `,
    BR: css`
      margin-top: 30px;
      width: 100%;
      height: auto;
    `,
    BV: css`
      width: 100%;
    `,
    GUCCI: css`
      width: 100%;
      margin-top: 35px;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        width: 70%;
        margin-right: auto;
        margin-left: auto;
      }
    `,
    YSL: css`
      width: 100%;
      margin-top: 17vh;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 17vh;
      }
    `,
  })};
`;

// logo displayed in welcome, registered and error pages (color may be inverted)
export const ImgLogo = styled.img`
  ${theme('brand', {
    BV: css`
      filter: invert(${(props) => (props.theme.background?.logoParameter?.inversionRequired ? 1 : 0)});
      @media only screen and (min-width : ${screenSizes.desktop.width}){
        filter: invert(${(props) => (props.theme.desktopBackground?.logoParameter?.inversionRequired ? 1 : 0)});
      }
    `,
    BR: css`
      filter: invert(${(props) => (props.theme.background?.logoParameter?.inversionRequired ? 1 : 0)});
      @media only screen and (min-width : ${screenSizes.desktop.width}){
        filter: invert(${(props) => (props.theme.desktopBackground?.logoParameter?.inversionRequired ? 1 : 0)});
      }
      width: 100px;
      height: auto;
    `,
    YSL: css`
      width: 270px;
      height: auto;
      filter: invert(${(props) => (props.theme.isOnErrorPage || props.theme.background?.logoParameter?.inversionRequired ? 1 : 0)});
      @media only screen and (min-width : ${screenSizes.desktop.width}){
        width: 420px;
        filter: invert(${(props) => (props.theme.isOnErrorPage || props.theme.desktopBackground?.logoParameter?.inversionRequired ? 1 : 0)});

      }
    `,
    AMQ: css`
      width: 210px;
      height: auto;
      filter: invert(${(props) => (props.theme.background?.logoParameter?.inversionRequired ? 1 : 0)});
      @media only screen and (min-width : ${screenSizes.desktop.width}){
        filter: invert(${(props) => (props.theme.desktopBackground?.logoParameter?.inversionRequired ? 1 : 0)});
      }
    `,
    BAL: css`
      filter: invert(${(props) => (props.theme.background?.logoParameter?.inversionRequired ? 1 : 0)});
      width: 130px;
      height: 16px;
      @media only screen and (min-width : ${screenSizes.desktop.width}){
        filter: invert(${(props) => (props.theme.desktopBackground?.logoParameter?.inversionRequired ? 1 : 0)});
      }
    `,
    GUCCI: css`
      filter: invert(${(props) => (props.theme.background?.logoParameter?.inversionRequired ? 1 : 0)});
      @media only screen and (min-width : ${screenSizes.desktop.width}){
        filter: invert(${(props) => (props.theme.desktopBackground?.logoParameter?.inversionRequired ? 1 : 0)});
      }
    `,
  })};
`;

// logo displayed in country, state and form pages (original color)
export const ImgFormLogo = styled.img`
  ${theme('brand', {
    BR: css`
      filter: invert(${(props) => (props.theme.colors.COMMON.isWhiteLogo === true ? 1 : 0)});
      width: 100px;
      height: auto;
    `,
    YSL: css`
      filter: invert(${(props) => (props.theme.colors.COMMON.isWhiteLogo === true ? 1 : 0)});
      width: 270px;
      height: auto;
    `,
    AMQ: css`
      filter: invert(${(props) => (props.theme.colors.COMMON.isWhiteLogo === true ? 1 : 0)});
      width: 210px;
      height: auto;
    `,
    BAL: css`
      filter: invert(${(props) => (props.theme.colors.COMMON.isWhiteLogo === true ? 1 : 0)});
      width: 160px;
      height: auto;
    `,
    GUCCI: css`
      filter: invert(${(props) => (props.theme.colors.COMMON.isWhiteLogo === true ? 1 : 0)});
    `,
    BV: css`
      filter: invert(${(props) => (props.theme.colors.COMMON.isWhiteLogo === true ? 1 : 0)});
    `,
  })};
`;

export const LogoFormWrapper = styled.div`
  // Component called only on screens > ipad size
  margin-bottom: 20px;
  margin-top: 8px;
  text-align: center;
  ${(props) => (!props.theme.isLegal && 'display: none')};

  @media only screen and (min-width : ${screenSizes.ipad.width}){
    display: block;
  }

  ${theme('brand', {
    BAL: css`
      text-align: left;
    `,
    GUCCI: css`
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        width: 70%;
        margin-right: auto;
        margin-left: auto;
      }
    `
  })};
`;
