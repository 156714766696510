import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import Utils from '@utils/utils';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { Field, OptionalLegend } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  customerCountry: string
}

export const FuriganaLastName: React.FC<Props> = ({ customerCountry }) => (
  <ConnectForm>
    {({ register, customer, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <>
        {Utils.isFuriganaDisplayed(customerCountry)
        && (
        <NameComponentWrapper>
          <Label htmlFor="furiganaLastName">{t('dcc.furigana_lastname')}</Label>
          <OptionalLegend>{t('dcc.optional')}</OptionalLegend>
          <Field
            name="furiganaLastName"
            id="furiganaLastName"
            ref={register({ required: false })}
            defaultValue={customer.customerInfo.furigana_lastname}
            maxLength={50}
          />
        </NameComponentWrapper>
        )}
      </>
    )}
  </ConnectForm>
);
