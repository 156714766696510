import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from '@services/hooks';
import { ClassicText, StyledTitle } from '@pages/Registered/registered.styles';
import TemeraApiService from '@services/TemeraApi.services';
import { useNavigate } from 'react-router-dom';
import { CheckboxField } from '@common/components/form/index';
import { CheckBoxInputContainer } from '@pages/Legal/legal.styles';
import { useForm } from 'react-hook-form';
import { Button } from '@/common/components/form/Button';
import api from '@/api';
import ErrorMessage from '@/common/components/form/ErrorMessage';
import { TypedErrorResponseBody } from '@/types/brandedTokenError';
import { CertificateOfCraftPage } from '@/types/enum/certificateOfCraftPage';
import { Customer } from '@/types';

export const ProofOfPurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 50px auto;
`;

export const InputProofOfPurchase = styled(Button)`
  width: 50%;
  margin: 20px auto;
`;

type ImportCertificateOfCraftContainerProps = {
  staticCustomer: Customer,
  actualToken: string,
  isPoPAlreadySent: boolean,
  updateErrorTypeOnError: (payload: TypedErrorResponseBody) => void,
  certificateOfCraftPage: CertificateOfCraftPage,
  setActiveCertificateOfCraftPage: React.Dispatch<React.SetStateAction<CertificateOfCraftPage>>,
  isCertificateOfCraftWholesalerActivated: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const ImportProofOfPurchase: React.FC<ImportCertificateOfCraftContainerProps & React.HTMLProps<HTMLInputElement>> = ({
  staticCustomer,
  actualToken,
  isPoPAlreadySent,
  updateErrorTypeOnError,
  certificateOfCraftPage,
  setActiveCertificateOfCraftPage,
  isCertificateOfCraftWholesalerActivated,
  setIsLoading
}) => {
  const [uploadError, setUploadError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // Prepare translations
  const t = useTranslation();
  // Prepare navigation
  const navigate = useNavigate();

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  // Prepare the form
  const {
    register,
    handleSubmit,
    formState
  } = useForm({ mode: 'onChange' });
  const {
    isSubmitting,
    isValid
  } = formState;

  const onClick = async (proofOfPurchase: File) => {
    try {
      if (proofOfPurchase) {
        setUploadError(false);
        setSizeError(false);
        if (proofOfPurchase.size < 10000000) {
          setIsDisabled(true);
          const data = new FormData();
          data.append('file', proofOfPurchase);
          const { data: { success } } = await api.uploadPoP(actualToken, data);
          if (success) {
            try {
              await TemeraApiService.activateCertificate(actualToken);
              setActiveCertificateOfCraftPage(CertificateOfCraftPage.THANK_YOU);
            } catch (err) {
              if (err.response) {
                updateErrorTypeOnError(err.response.data);
              }
              setIsLoading(false);
              navigate('/error');
            }
          }
        } else {
          setSizeError(true);
        }
      } else {
        setIsDisabled(false);
        setUploadError(true);
      }
    } catch (e) {
      setIsDisabled(false);
      setUploadError(true);
    }
  };

  const onSubmitGift = handleSubmit(async () => {
    try {
      setIsLoading(true);
      await TemeraApiService.activateCertificate(actualToken);
      setActiveCertificateOfCraftPage(CertificateOfCraftPage.THANK_YOU);
      setIsLoading(false);
    } catch (err) {
      if (err.response) {
        updateErrorTypeOnError(err.response.data);
      }
      setIsLoading(false);
      navigate('/error');
    }
  });

  const onClickWholesaler = handleSubmit(async () => {
    if (isPoPAlreadySent) {
      try {
        setIsLoading(true);
        await TemeraApiService.activateCertificate(actualToken);
        setActiveCertificateOfCraftPage(CertificateOfCraftPage.THANK_YOU);
        setIsLoading(false);
      } catch (err) {
        if (err.response) {
          updateErrorTypeOnError(err.response.data);
        }
        setIsLoading(false);
        navigate('/error');
      }
    } else {
      setActiveCertificateOfCraftPage(CertificateOfCraftPage.UPLOAD);
    }
  });

  const uploadProofOfPurchase = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && onClick(e.target.files[0]);
    e.target.value = '';
  };

  const text = `${t('web.certificateOfCraftCheckboxText')}`;

  switch (certificateOfCraftPage) {
    case CertificateOfCraftPage.VALID_LEGAL:
      return (
        <ProofOfPurchaseContainer>
          <CheckBoxInputContainer key="certificateOfCraftLegalCheckbox">
            <CheckboxField
              name="certificateOfCraftLegalCheckbox"
              text={text}
              isTextHTML
              required
              parentRef={register({ required: true })}
            />
            <input
              type="hidden"
              name="certificateOfCraftPrivacy"
              ref={register}
              value="certificateOfCraftLegalValidation"
            />
          </CheckBoxInputContainer>
          {isCertificateOfCraftWholesalerActivated ? (
            <InputProofOfPurchase
              disabled={!isValid || isSubmitting}
              type="button"
              id="nextButton"
              name="nextButton"
              onClick={onClickWholesaler}
            >
              {t('dcc.next')}
            </InputProofOfPurchase>
          )
            : (
              <InputProofOfPurchase
                disabled={!isValid || isSubmitting}
                type="button"
                id="certificateOfCraftActivateButton"
                name="certificateOfCraftActivateButton"
                onClick={onSubmitGift}
              >
                {t('web.activateProofOfPurchase')}
              </InputProofOfPurchase>
            )}
        </ProofOfPurchaseContainer>
      );
    case CertificateOfCraftPage.UPLOAD:
      return (
        <ProofOfPurchaseContainer>
          <div>
            <ClassicText>
              {t('web.proofOfPurchaseToUploadText')}
            </ClassicText>
            <InputProofOfPurchase
              disabled={isDisabled}
              type="button"
              id="paperCardButton"
              name="paperCardButton"
              onClick={async () => {
                hiddenFileInput.current?.click();
              }}
            >
              {t('web.uploadProofOfPurchase')}
            </InputProofOfPurchase>
            <input
              type="file"
              ref={hiddenFileInput}
              id="image_uploads"
              name="image_uploads"
              hidden
              onChange={(e) => uploadProofOfPurchase(e)}
            />
            {sizeError && <ErrorMessage id="PoPSizeError">{t('web.proofOfPurchaseSizeError')}</ErrorMessage>}
            {uploadError && <ErrorMessage id="PoPGenericError">{t('web.uploadProofOfPurchaseError')}</ErrorMessage>}
          </div>
        </ProofOfPurchaseContainer>
      );
    case CertificateOfCraftPage.THANK_YOU:
      return (
        <div>
          <StyledTitle>
            {t('web.thankYouCertificateOfCraftActivation', false, staticCustomer)}
          </StyledTitle>
          <ClassicText>
            {t('web.proofOfPurchaseValidated')}
          </ClassicText>
          <ClassicText>
            {t('web.certificateOfCraftScanCard')}
          </ClassicText>
          <ClassicText>
            {t('web.certificateOfCraftWaitingActivation')}
          </ClassicText>
        </div>
      );
    default:
      return null;
  }
};

export default ImportProofOfPurchase;
