import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';
import { Button } from '@/common/components/form/Button';
import { Background, ProspectSource } from '@/types';

export const ButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 5vh;
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    margin-top: 20px;
    position: static;
  }
  
  ${theme('brand', {
    BV: css`
      position: relative;
      bottom: initial;
    `,
    AMQ: css`
     bottom: 0;
     height: 85px;
    `,
    YSL: css`
     bottom: 0;
     @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 0;
        position: absolute;
      }
    `,
    BAL: css`
     bottom: 0;
     @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 0;
        position: absolute;
      }
    `,
  })};
`;

export const ButtonWrapperPIN = styled(ButtonWrapper)`
  position: relative;
  bottom: initial;
  ${theme('brand', {
    BAL: css`
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    `
  })};
`;

export const WelcomeButton = styled(Button)`
  color: ${(props) => props.theme.background?.buttonParameter?.fontColor || props.theme.colors.WELCOME.welcomeButton.color};
  background-color: ${(props) => props.theme.background?.buttonParameter?.backgroundColor || props.theme.colors.WELCOME.welcomeButton.backgroundColor};
  border: 1px solid ${(props) => props.theme.background?.buttonParameter?.borderColor || props.theme.colors.WELCOME.welcomeButton.borderColor};
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    color: ${(props) => props.theme.desktopBackground?.buttonParameter?.fontColor || props.theme.colors.WELCOME.welcomeButton.desktop.color};
    background-color: ${(props) => props.theme.desktopBackground?.buttonParameter?.backgroundColor || props.theme.colors.WELCOME.welcomeButton.desktop.backgroundColor};
    border: 1px solid ${(props) => props.theme.desktopBackground?.buttonParameter?.borderColor || props.theme.colors.WELCOME.welcomeButton.desktop.borderColor};
  }
  ${theme('brand', {
    BAL: css`
     border: none;
     border-top: 1px solid ${(props) => props.theme.background?.buttonParameter?.borderColor || props.theme.colors.WELCOME.welcomeButton.borderColor};
     @media only screen and (min-width : ${screenSizes.desktop.width}){
       border: none;
       border-top: 1px solid ${(props) => props.theme.desktopBackground?.buttonParameter?.borderColor || props.theme.colors.WELCOME.welcomeButton.desktop.borderColor};
     }
    `,
    YSL: css`
     border: none;
     border-top: 1px solid ${(props) => props.theme.background?.buttonParameter?.borderColor || props.theme.colors.WELCOME.welcomeButton.borderColor};
     @media only screen and (min-width : ${screenSizes.desktop.width}){
       border: none;
       border-top: 1px solid ${(props) => props.theme.desktopBackground?.buttonParameter?.borderColor || props.theme.colors.WELCOME.welcomeButton.desktop.borderColor};
     }
    `
  })};
`;

export const WelcomeContainer = styled.div`
  overflow-y: scroll;
`;

type DesktopBackgroundContainerProps = {
  desktopBackground: Background,
  prospectSource: ProspectSource
}

export const DesktopBackgroundContainer = styled.div.attrs((props: DesktopBackgroundContainerProps) => ({
  desktopBackground: props.desktopBackground,
  prospectSource: props.prospectSource,
}))`
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    background-image: ${(props) => (props.desktopBackground.backgroundUrl ? `url(${props.desktopBackground.backgroundUrl})` : 'none')};
    background-position: ${(props) => props.desktopBackground.backgroundPosition};
    background-attachment: fixed;
    // Added white background color to avoid having a transparent one
    // Note: it does not override the background image/color associated to a brand/campaign
    background-color: #FFFFFFFF;
  }
`;
