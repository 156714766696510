import { AddressFlow } from '@/types/enum/addressFlow';

export default class MixPanelFormatterService {
  static getAddressFlow(isContactFormExtended: boolean, displayAddressPage: boolean) {
    if (isContactFormExtended) return AddressFlow.MIXED;
    if (displayAddressPage) return AddressFlow.SEPARATED;
    return AddressFlow.NONE;
  }

  static getFillFieldInfos(fields: Object): {[key: string]: boolean} {
    const fillFieldsInfos: {[key: string]: boolean} = {};
    // convert object --> key: field, value: fieldValue INTO key: fieldFilled, value: Boolean(fieldValue)
    Object.entries(fields).forEach(([key, value]) => { fillFieldsInfos[`${key}Filled`] = Boolean(value); });
    return fillFieldsInfos;
  }
}
