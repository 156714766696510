import React from 'react';
import { useTranslation } from '@services/hooks';
import { PageID } from '@/types/enum/pageID';
import {
  IButtonContainerProps,
  IContactButtonContainerProps,
  ILegalButtonContainerProps
} from '@/types/form/buttonContainerProps';

type Props = {
  pageId: PageID,
  props: IButtonContainerProps | ILegalButtonContainerProps | IContactButtonContainerProps
}

const TextButtonToDisplay: React.FC<Props> = ({ pageId, props }) => {
  const { LEGAL, UPDATE_ADDRESS, CONTACT } = PageID;
  const t = useTranslation();
  const nextText = <>{t('dcc.next')}</>;

  switch (pageId) {
    case LEGAL:
      if ('isCustomTranslationUsed' in props) {
        return props.isCustomTranslationUsed ? <>{t('web.updateMyProfile')}</>
          : <div dangerouslySetInnerHTML={{ __html: t(props.privacy.buttonTextKey, true) }} />;
      }
      break;
    case UPDATE_ADDRESS:
      return <>{t('web.updateAddressValidation')}</>;
    case PageID.CONTACT:
    case PageID.INFORMATION:
      return ('isConfirmButton' in props) && props.isConfirmButton ? <>{t('web.confirm')}</> : nextText;
    default:
      return nextText;
  }

  return (nextText);
};

export default TextButtonToDisplay;
