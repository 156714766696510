import regex from '@utils/regex';
import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { Country } from '@/types';
import { Field, OptionalLegend } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form';
import { TranslationFunction } from '@/services/hooks/useTranslation';

type Props = {
  currentCountry: Country
}

export const PoBox: React.FC<Props> = ({ currentCountry }) => (
  <ConnectForm>
    {({ register, customer, t }: UseFormMethods & GlobalState & TranslationFunction) => (
      <>
        { // Toggle or not the pobox field if country has a displayPobox: true
          currentCountry?.displayPobox
          && (
            <>
              <Label htmlFor="pobox">{t('dcc.po-box')}</Label>
              <OptionalLegend>{t('dcc.optional')}</OptionalLegend>
              <Field
                name="pobox"
                id="pobox"
                ref={register({ pattern: regex.pobox })}
                defaultValue={customer.customerContact.address.pobox}
                maxLength={120}
              />
            </>
          )
        }
      </>
    )}
  </ConnectForm>
);
