import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';
import Utils from '@utils/utils';

export const InvitationText = styled.div`
  position: relative;
  color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.WELCOME.labelColor.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.WELCOME.labelColor.desktop.color)};

  ${theme('brand', {
    AMQ: css`
      margin: 30vh auto 0;
      line-height: 30px;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 36vh;
      }
    `,
    BAL: css`
      font-size: 12pt;
      padding: 0 16px;
      text-align: left;
    `,
    BR: css`
      margin: 15vh auto 0;
      letter-spacing: 2.6px;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 36vh;
      }
    `,
    BV: css`
      margin: 20vh 35px 40px;
      padding-top: 20px;
      font-size: 14pt;
      text-align: center;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 36vh;
      }
    `,
    GUCCI: css`
      margin: 20vh 35px 40px;
      font-size: 16pt;
      text-align: center;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 36vh;
      }
    `,
    YSL: css`
      font-size: 11pt;
      font-weight: bold;
      margin-top: 30%;
      left: 20%;
      width: 210px;
      text-align: left;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 20%;
        width: 40%;
      }
    `,
  })};
`;

export const EventInvitationText = styled(InvitationText)`  
  ${theme('brand', {
    BV: css`
      color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.WELCOME.labelColor.event.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.WELCOME.labelColor.event.desktop.color)};
    `,
    GUCCI: css`
      margin: 5vh 35px 40px;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 28vh;
      }
    `,
  })};
`;

export const ReCaptchaDisclaimerText = styled(EventInvitationText)`  
  font-size: 12pt;
  margin-bottom: 85px;
  margin-top: 5vh !important;
  ${theme('brand', {
    YSL: css`
      bottom: 80px;  // <!> Should not be lower than the ButtonBottomWrapper height component (53px). Otherwise this component will overlay it <!>
      margin-bottom: 0;
      font-size: 7pt;
      position: absolute;
    `,
  })};
`;

export const WelcomeTextComponent = styled(InvitationText)`
  margin: 30px 0;
`;
