import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { useStateMachine } from 'little-state-machine';
import Utils from '@utils/utils';
import { Country, Page, ProspectSource, StateCountry } from '@/types';
import {
  updateBrandCountryConfiguration,
  updateCustomerInformation,
  updateErrorTypeOnError,
  updateFeaturesActivationAndPageFlow,
  updateIsLoading
} from '@/littleStateMachine/actions';
import { CountryStateButton, SearchBar } from '@/common/components';
import { PageContainer } from '@/common/components/containers';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import mp from '@/services/mixpanel/mixpanel.service';
import { PageID } from '@/types/enum/pageID';
import { ImgFormLogo, LogoFormWrapper } from '@/common/components/Logo';
import { Widget } from '@/common/components/SearchBar';
import FlowService from '@/services/flow.service';
import { ThemeType } from '@/types/enum/themeType';
import api from '@/api';
import { FullPageLoader } from '@/common/components/Loader';

type Props = {
  setOpenStateModal?: Dispatch<SetStateAction<boolean>>,
  setChangeState?: Dispatch<SetStateAction<boolean>>,
}

const StatePage: React.FunctionComponent<Props> = ({ setOpenStateModal, setChangeState }) => {
  useScrollToTop();
  useLastPageVisited(PageID.STATE);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  const localStep = PageID.STATE;
  // Retrieve current state from store
  const { actions, state } = useStateMachine({ updateCustomerInformation, updateBrandCountryConfiguration, updateErrorTypeOnError, updateFeaturesActivationAndPageFlow, updateIsLoading });
  const { brand, countries, customer, flow, isLoading, prospectSource, translations, mixPanelToken, actualToken } = state;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  useThemeColor(brand, ThemeType.FORM);

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  const currentCountry = countries.find((country: Country) => country.code === customer.customerContact.address.country);
  const statesFromCurrentCountry = currentCountry ? currentCountry.states : [];
  const translatedAndSortedStatesFromCurrentCountry = statesFromCurrentCountry.map((countryState: StateCountry) => ({
    ...countryState,
    libelle: t(`state.${countryState.code.toLocaleLowerCase()}`),
  })).sort((a: StateCountry, b: StateCountry) => {
    if (a.displayOrder && b.displayOrder) {
      return a.displayOrder - b.displayOrder;
    }
    return a.libelle.localeCompare(b.libelle);
  });

  const [search, setSearch] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onClick = async (countryState: StateCountry) => {
    try {
      actions.updateIsLoading(true);
      const { data: { featuresActivated, pageFlow } } = await api.getFeatureActivationAndPageFlow(token, customer.customerContact.address.country);
      pageFlow.pages.splice(pageFlow.pages.findIndex((page: Page) => page.name === PageID.COUNTRY) + 1, 0, { name: PageID.STATE, stepValue: 0 });
      actions.updateFeaturesActivationAndPageFlow({
        featuresActivated,
        pageFlow
      });
      // We add the State page in the flow pages and remove the State page from the forbidden pages because updateFeaturesActivationAndPageFlow erased the previous action
      FlowService.addPageInFlow(state, { name: PageID.STATE, stepValue: 0 }, FlowService.getIndex(state, PageID.COUNTRY) + 1);
      const { data: payload } = await api.fetchBrandCountryConf(token, customer.customerContact.address.country, countryState.code);
      actions.updateBrandCountryConfiguration(payload);
      actions.updateIsLoading(false);
      actions.updateCustomerInformation({ state: countryState.code });
      mp.saveState();
      const nextPage = FlowService.nextPage(state, localStep, undefined, pageFlow.pages);
      if ((prospectSource === ProspectSource.UPDATE_ADDRESS) && setOpenStateModal && setChangeState) {
        setOpenStateModal(false);
        setChangeState(false);
      } else {
        navigate(`/${token}/${nextPage}`);
      }
    } catch (err) {
      if (err?.response?.data) {
        actions.updateErrorTypeOnError(err.response.data);
      }
      actions.updateIsLoading(false);
      navigate('/error');
    }
  };

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
  }, [navigate]);

  if (brand.code && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, language: translations.lightLanguageCode }}>
        { isLoading ? <FullPageLoader />
          : (
            <PageContainer>
              <LogoFormWrapper><ImgFormLogo id="brandLogo" src={defaultLogoUrl} alt="Logo" /></LogoFormWrapper>
              <SearchBar
                windowWidth={windowWidth}
                id="searchFieldState"
                name="searchFieldState"
                placeholder={t(currentCountry?.hasStates ? 'dcc.searchState' : 'dcc.searchProvince')}
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(Utils.normalize(e.target.value))}
                onCrossClick={() => setSearch('')}
                widget={Widget.CROSS}
              />
              { translatedAndSortedStatesFromCurrentCountry
                .filter((countryState: StateCountry) => RegExp(search, 'i').test(Utils.normalize(countryState.libelle)))
                .map((countryState: StateCountry) => (
                  <CountryStateButton value={countryState.code} key={countryState.code} onClick={() => onClick(countryState)}>
                    {Utils.toPartialLowerCase(countryState.libelle)}
                  </CountryStateButton>
                )) }
            </PageContainer>
          )}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default StatePage;
