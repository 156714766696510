export enum EmailValidityStatusEnum {
    VALID='valid',
    ACCEPT_ALL='accept_all',
    UNKOWN = 'unknown',
    EMAIL_ADDRESS_INVALID = 'email_address_invalid',
    EMAIL_DOMAIN_INVALID = 'email_domain_invalid',
    EMAIL_ACCOUNT_INVALID = 'email_account_invalid',
    MAILBOX_FULL = 'mailbox_full',
    DISPOSABLE = 'disposable',
    ROLE_ADDRESS = 'role_address',
}
