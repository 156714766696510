import React, { useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import { StyledText } from '@pages/Error/error.styles';
import { MainWrapper } from '@pages/Error/components/MainWrapper';
import { BrandedWrapper } from '@pages/Error/components/BrandWrapper';
import { useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import { ErrorType, ErrorTypeMixPanel } from '@/types/brandedTokenError';
import mp from '@/services/mixpanel/mixpanel.service';
import { ThemeType } from '@/types/enum/themeType';
import api from '@/api';
import { resetCustomerData, updateFrontErrorSent } from '@/littleStateMachine/actions';
import { PageID } from '@/types/enum/pageID';

const Error = () => {
  useScrollToTop();

  // Prepare translations
  const t = useTranslation();

  const { actions, state } = useStateMachine({ updateFrontErrorSent, resetCustomerData });
  const { brand, actualToken, error, featuresActivated } = state;
  const { code, defaultLogo } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  const { errorType, isBackError, lastPageVisited, isFrontErrorAlreadySent, frontErrorPageSent } = error;
  useThemeColor(brand, ThemeType.ERROR);

  // translation key used on error if light conf has been downloaded (translations are available)
  const [errorTranslationKey, setErrorTranslationKey] = useState('');
  // translation used on error if light conf has not been downloaded yet (translations are not available)
  const [errorDefaultTranslation, setErrorDefaultTranslation] = useState('');

  useEffect(() => {
    mp.pageView(PageID.ERROR);
    (async function fetch() {
      switch (errorType) {
        case ErrorType.ALREADY_USED:
          setErrorTranslationKey('web.alreadyUsed');
          setErrorDefaultTranslation('Sorry, the link has already been used to register.');
          mp.errorTriggered(ErrorTypeMixPanel.ALREADY_USED);
          break;
        case ErrorType.EXPIRED:
          setErrorTranslationKey('web.error');
          setErrorDefaultTranslation('Sorry, the link has expired.');
          mp.errorTriggered(ErrorTypeMixPanel.EXPIRED);
          break;
        case ErrorType.LOCKED:
          setErrorTranslationKey('web.locked');
          setErrorDefaultTranslation('Sorry, the link has been locked after too many tentatives of registering an existing client.');
          mp.errorTriggered(ErrorTypeMixPanel.LOCKED);
          break;
        case ErrorType.MAINTENANCE_PERIOD:
          setErrorTranslationKey('web.maintenancePeriod');
          setErrorDefaultTranslation('We will be back soon');
          mp.errorTriggered(ErrorTypeMixPanel.MAINTENANCE_PERIOD);
          break;
        case ErrorType.NOT_AUTHORIZED:
          if (featuresActivated.IS_OUT_OF_STORE_PROSPECT_SOURCE_ACTIVATED) {
            setErrorTranslationKey('web.notAuthorizedOutOfStore');
            setErrorDefaultTranslation('Sorry, we are experiencing temporary technical problems.'); // todo update when we have translations
          } else {
            setErrorTranslationKey('web.notAuthorized');
            setErrorDefaultTranslation('Please refer to your Sale Assistant.');
          }
          mp.errorTriggered(ErrorTypeMixPanel.NOT_AUTHORIZED);
          break;
        case ErrorType.TOO_MANY_OTP_ATTEMPTS:
          setErrorTranslationKey('web.tooManyOtpAttempts');
          setErrorDefaultTranslation('Maximum number of attempts reached');
          mp.errorTriggered(ErrorTypeMixPanel.TOO_MANY_OTP_ATTEMPTS);
          break;
        default:
          setErrorTranslationKey('web.wrong');
          setErrorDefaultTranslation('Sorry, something went wrong.');
          mp.errorTriggered(ErrorTypeMixPanel.DEFAULT);
      }
      // delete customer data for confidentiality
      actions.resetCustomerData(true);
    }());
  }, [error]);

  useEffect(() => {
    (async function fetch() {
      const shouldSentFrontError = !isFrontErrorAlreadySent || (isFrontErrorAlreadySent && lastPageVisited !== frontErrorPageSent);
      if (!isBackError && shouldSentFrontError) {
        actions.updateFrontErrorSent({ frontErrorPageSent: lastPageVisited });
        await api.logFrontError(actualToken, lastPageVisited);
      }
    }());
  }, []);

  // case of an existing token that was valid and served to load conf
  if (code) {
    return (
      <BrandedWrapper>
        {t(errorTranslationKey)}
      </BrandedWrapper>
    );
  }
  // case of a non-existing token in database but branded anyway
  if (defaultLogoUrl) {
    return (
      <BrandedWrapper>
        {errorDefaultTranslation}
      </BrandedWrapper>
    );
  }
  // case of an unbranded non-existing token
  return (
    <MainWrapper>
      <StyledText>
        {errorDefaultTranslation}
      </StyledText>
    </MainWrapper>
  );
};

export default Error;
