import React from 'react';
import styled from 'styled-components';
import Utils from '@utils/utils';
import { TextComponent } from '@/types/textComponent';
import { useTranslation } from '@/services/hooks';

type Props = {
  textComponent: Array<TextComponent>
}
const TextComponentContainer: React.FC<Props> = ({ textComponent }) => {
  const t = useTranslation();

  return (
    <>
      {
        textComponent.map((text) => (
          <StyledTextComponent key={text.translationKey} id="registeredText" fontWeight={text.fontWeight} fontStyle={text.fontStyle} textDecoration={text.textDecoration} textAlign={text.textAlign} fontSize={text.fontSize}>
            {t(text.translationKey)}
          </StyledTextComponent>
        ))
      }
    </>
  );
};

export default TextComponentContainer;

type StyledTextComponentProps = {
  fontWeight: string,
  fontStyle: string,
  textDecoration: string,
  textAlign: string,
  fontSize: number
}

const StyledTextComponent = styled.div.attrs((props: StyledTextComponentProps) => ({
  fontWeight: props.fontWeight,
  fontStyle: props.fontStyle,
  textDecoration: props.textDecoration,
  textAlign: props.textAlign,
  fontSize: props.fontSize,
}))`
  font-weight: ${(props) => props.fontWeight};
  font-style: ${(props) => props.fontStyle};
  text-decoration: ${(props) => props.textDecoration};
  text-align: ${(props) => props.textAlign};
  font-size: ${(props) => props.fontSize};
  color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.desktop.color)};
  margin: 30px 0;
`;
