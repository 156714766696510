import React, { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { useStateMachine } from 'little-state-machine';
import { HelpVerification } from '@pages/Verification/verification.styles';
import mp from '@services/mixpanel/mixpanel.service';
import { Title } from '@/common/components';
import { PageContainer } from '@/common/components/containers';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import { PreferredMethodOfContact, TYPE_DROPDOWN } from '@/types';
import FormHeader from '@/common/components/form/FormHeader';
import { ThemeType } from '@/types/enum/themeType';
import { FullPageLoader } from '@/common/components/Loader';
import OtpForm from '@/common/components/form/OtpForm';
import { contactMethods } from '@/types/enum/contactMethods.enum';

const Verification: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.VERIFICATION);

  // Prepare translations
  const t = useTranslation();

  // Prepare navigation
  const navigate = useNavigate();

  const localStep = PageID.VERIFICATION;
  // Retrieve current state from store
  const { state } = useStateMachine();
  const { brand, prospectSource, flow, translations, languages, customer, isLoading, mixPanelToken, actualToken } = state;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);
  useThemeColor(brand, ThemeType.FORM);

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();
  const otpTextPrefix = customer.customerContact.preferred === PreferredMethodOfContact.PHONE ? contactMethods.SMS : contactMethods.EMAIL;

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
  }, [navigate]);

  if (brand.code && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, language: translations.lightLanguageCode, prospectSource }}>
        { isLoading ? <FullPageLoader /> : (
          <PageContainer>
            <FormHeader numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} languages={languages} type={TYPE_DROPDOWN.DEFAULT} defaultLogoUrl={defaultLogoUrl} />
            <Title>{t(`web.${otpTextPrefix}VerificationSent`)}</Title>
            <HelpVerification>
              {t(`web.${otpTextPrefix}HelpVerification`)}
            </HelpVerification>
            <OtpForm otpTextPrefix={otpTextPrefix} />
          </PageContainer>
        )}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default Verification;
