import React from 'react';
import { useStateMachine } from 'little-state-machine';
import { RedirectQueueButton } from '@pages/Registered/registered.styles';
import { useTranslation } from '@/services/hooks';
import { ProspectSource } from '@/types';

type Props = {
  clientRegistrationStatus?: QueueCreationState
}

// todo should we create a ALREADY_EXISTS status to use in contact page ?
export enum QueueCreationState {
  SUCCESS = 'success',
  ERROR = 'error'
}

const QueueManagementRedirectButton: React.FC<Props> = ({ clientRegistrationStatus = QueueCreationState.SUCCESS }) => {
  const t = useTranslation();
  const { state } = useStateMachine();
  const { queueManagement, prospectSource } = state;

  let callbackUrl = '';
  if (queueManagement?.redirectionUrl) {
    // new way to handle queue management
    if (queueManagement.useKafkaNotification) {
      const callbackUrlObject = new URL(queueManagement.redirectionUrl);
      callbackUrlObject.searchParams.append('clientRegistrationStatus', clientRegistrationStatus);
      callbackUrl = callbackUrlObject.href;
    } else {
      // old way to handle queue management (no info on creation status)
      callbackUrl = queueManagement.redirectionUrl;
    }
  }

  if (queueManagement?.redirectionUrl) {
    return (
      <RedirectQueueButton
        id="queueButton"
        type="button"
        onClick={() => { document.location.href = callbackUrl; }}
      >
        {t(prospectSource === ProspectSource.WEBDCC_APPT ? 'web.redirectClient.appointment' : 'web.redirectClient')}
      </RedirectQueueButton>
    );
  }
  return <> </>;
};

export default QueueManagementRedirectButton;
