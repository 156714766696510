import React, { Dispatch, SetStateAction, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import styled from 'styled-components/macro';
import { useStateMachine } from 'little-state-machine';
import Utils from '@utils/utils';
import { updateBackground } from '@/littleStateMachine/actions';
import { Track } from '@/types/form/track';
import mp from '@/services/mixpanel/mixpanel.service';
import { BackgroundEnum } from '@/types/enum/background';

type PlayerProps = {
  tracks: Track[],
  setVisibilityAudioText: Dispatch<SetStateAction<string>>
}

const Player = ({ tracks, setVisibilityAudioText } : PlayerProps) => {
  const { actions } = useStateMachine({ updateBackground });
  const { updateBackground: updateCurrentBackgroundAction } = actions;
  const [currentMusicIndex, setCurrentMusicIndex] = useState(0);

  const handleClickPrevious = async () => {
    if (currentMusicIndex > 0 && currentMusicIndex < tracks.length) {
      const previousIndex = currentMusicIndex - 1;
      updateCurrentBackgroundAction({ background: tracks[previousIndex], backgroundName: BackgroundEnum.currentBackground });
      setCurrentMusicIndex(previousIndex);
    } else if (currentMusicIndex === 0) {
      updateCurrentBackgroundAction({ background: tracks[tracks.length - 1], backgroundName: BackgroundEnum.currentBackground });
      setCurrentMusicIndex(tracks.length - 1);
    }
    mp.audioPlayerBack();
  };

  const handleClickNext = async () => {
    if (currentMusicIndex >= 0 && currentMusicIndex < tracks.length - 1) {
      const nextIndex = currentMusicIndex + 1;
      updateCurrentBackgroundAction({ background: tracks[nextIndex], backgroundName: BackgroundEnum.currentBackground });
      setCurrentMusicIndex(nextIndex);
    } else {
      updateCurrentBackgroundAction({ background: tracks[0], backgroundName: BackgroundEnum.currentBackground });
      setCurrentMusicIndex(0);
    }
    mp.audioPlayerForward();
  };

  const handlePlay = async () => {
    await displayAssociatedImageAndLoadImages();
    mp.audioPlayerPlay();
  };

  const handlePause = async () => {
    mp.audioPlayerPause();
  };

  const displayAssociatedImageAndLoadImages = async () => {
    const updatePromises: Promise<string>[] = [];
    tracks.forEach((media: Track) => updatePromises.push(Utils.loadImage(media.backgroundUrl)));
    await Promise.all(updatePromises);
    if (currentMusicIndex === 0) {
      updateCurrentBackgroundAction({ background: tracks[0], backgroundName: BackgroundEnum.currentBackground });
    }
    setVisibilityAudioText('hidden');
  };

  return (
    <AudioContainer id="registeredMusic">
      <AudioPlayer
        autoPlay={false}
        loop={tracks.length === 1}
        showSkipControls={tracks.length > 1}
        showJumpControls={false}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        src={tracks[currentMusicIndex].audioUrl}
        onClickPrevious={handleClickPrevious}
        onClickNext={handleClickNext}
        onEnded={handleClickNext}
        onPlay={handlePlay}
        onPause={handlePause}
      />
    </AudioContainer>
  );
};

export default Player;

export const AudioContainer = styled.div`
  div, input, label, h1, h2, h3, h4 {
    direction: ltr;
  }
 .rhap_container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 1;
  font-family: inherit;
  width: 100%;
  padding: 10px 15px;
}
.rhap_container:focus:not(:focus-visible) {
  outline: 0;
}
.rhap_container svg {
  vertical-align: initial;
  color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.colors.REGISTERED.player.controls.color, props.theme.colors.REGISTERED.player.controls.desktop.color)};
}

.rhap_header {
  margin-bottom: 10px;
}

.rhap_footer {
  margin-top: 5px;
}

.rhap_main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 8px;
}

.rhap_horizontal {
  flex-direction: row;
}
.rhap_horizontal .rhap_controls-section {
  margin-left: 8px;
}

.rhap_horizontal-reverse {
  flex-direction: row-reverse;
}
.rhap_horizontal-reverse .rhap_controls-section {
  margin-right: 8px;
}

.rhap_stacked-reverse {
  flex-direction: column-reverse;
}
.rhap_stacked-reverse .rhap_controls-section {
  margin-bottom: 8px;
}

.rhap_progress-section {
  display: flex;
  flex: 3 1 auto;
  align-items: center;
}

.rhap_progress-container {
  display: flex;
  align-items: center;
  height: 20px;
  flex: 1 0 auto;
  align-self: center;
  margin: 0 calc(10px + 1%);
  cursor: pointer;
  -webkit-user-select: none;
}
.rhap_progress-container:focus:not(:focus-visible) {
  outline: 0;
}

.rhap_time {
  color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.colors.REGISTERED.player.time.color, props.theme.colors.REGISTERED.player.time.desktop.color)};
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  width: 50px;
}

.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 5px;
  background-color: ${(props) => props.theme.colors.REGISTERED.player.progressBar.backgroundColor};
  border-radius: 2px;
}

.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.REGISTERED.player.progressBar.filled.backgroundColor};
  border-radius: 2px;
}

.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  top: -8px;
  background-color: ${(props) => props.theme.colors.REGISTERED.player.progressIndicator.backgroundColor};
  border-radius: 50px;
  box-shadow: ${(props) => props.theme.colors.REGISTERED.player.progressIndicator.shadow.color} 0 0 5px;
}

.rhap_controls-section {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
}

.rhap_additional-controls {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
}

.rhap_main-controls {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhap_main-controls-button {
  margin: 0 3px;
  font-size: 35px;
  width: 35px;
  height: 35px;
}

.rhap_play-pause-button {
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.rhap_volume-controls {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
}

/* Utils */
.rhap_button-clear {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
}
.rhap_button-clear:hover {
  opacity: 0.9;
  transition-duration: 0.2s;
}
.rhap_button-clear:active {
  opacity: 0.95;
}
.rhap_button-clear:focus:not(:focus-visible) {
  outline: 0;
}
`;
