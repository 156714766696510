import Utils from '@utils/utils';
import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { ProspectSource } from '@/types';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { Field } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  prospectSource: ProspectSource
  isDisabled: boolean
}

export const FirstName: React.FC<Props> = ({ prospectSource, isDisabled }) => (
  <ConnectForm>
    {({ register, customer, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="firstName">{t('dcc.firstname')}</Label>
        <Field
          name="firstName"
          id="firstName"
          ref={register({ required: true, minLength: 1, validate: (value) => Utils.doesNotContainOnlySpaces(value) })}
          defaultValue={customer.customerInfo.firstname}
          autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'given-name'}
          maxLength={50}
          disabled={isDisabled}
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
