import React from 'react';
import styled from 'styled-components';
import { useScrollToTop, useTranslation } from '@/services/hooks';

interface Props {
  onClose: () => void,
  id?: string
}

const ConfirmModal: React.FC<Props> = ({
  children,
  onClose,
  id
}) => {
  useScrollToTop();
  const closeModal = () => onClose();
  const t = useTranslation();

  return (
    <StyledModal id={id}>
      <WrapperModal>
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter className="modal-footer">
          <ConfirmButton
            id="modalButton"
            onClick={closeModal}
          >
            {t('dcc.next')}
          </ConfirmButton>
        </ModalFooter>
      </WrapperModal>
    </StyledModal>
  );
};

export default ConfirmModal;

const StyledModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.COMMON.modal.backgroundColor};
  z-index: 100;
`;

const ConfirmButton = styled.button`
  background-color: ${(props) => props.theme.colors.COMMON.modal.confirm.wrapper.footer.button.backgroundColor};
  border: none;
  color: ${(props) => props.theme.colors.COMMON.modal.confirm.wrapper.footer.button.color};
  cursor: pointer;
`;

const WrapperModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  transform: translate(20%, 250%);
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.COMMON.modal.confirm.wrapper.backgroundColor};
  font-size: 12px;
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  width: 100%;
`;

const ModalFooter = styled.div`
  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  border-top: 1px solid ${(props) => props.theme.colors.COMMON.modal.confirm.wrapper.footer.borderTopColor};
`;
