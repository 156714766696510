import React, { RefObject, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import Caret from '@assets/img/icons/Caret';
import Utils from '@utils/utils';
import screenSizes from '@utils/screenSizes';
import { useStateMachine } from 'little-state-machine';
import { SearchBar } from '@/common/components';
import { SearchType, Widget } from '@/common/components/SearchBar';

interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
  parentRef?: ((instance: HTMLSelectElement) => void) | RefObject<HTMLSelectElement>,
  customText?: string,
  hasCustomText?: boolean,
}

interface SelectSearchProps extends React.HTMLProps<HTMLInputElement> {
  parentRef?: ((instance: HTMLInputElement) => void) | RefObject<HTMLInputElement>,
  selectOptions: { value: string, name: string }[],
  selectOptionsValues: string[],
  setFormValue: Function,
  getFormValue: Function,
  triggerFormValidation: Function,
  defaultValue: string,
  widget: Widget,
  disabledPrefix: boolean
}

const Select : React.FC<SelectProps> = ({ children,
  customText, parentRef,
  name, id,
  required, disabled,
  defaultValue, onChange,
}) => {
  const { state: { brand: { style: { colors } } } } = useStateMachine();
  const selectProps = {
    name, id, required, disabled, defaultValue, onChange,
  };
  return (
    <Wrapper>
      { customText && <CustomSelectText>{customText}</CustomSelectText> }
      <StyledSelect ref={parentRef} hasCustomText={customText !== ''} {...selectProps}>
        {children}
      </StyledSelect>
      { !disabled && (<StyledCaret color={colors.COMMON.caret.form.color} />) }
    </Wrapper>
  );
};

export const SelectSearch : React.FC<SelectSearchProps> = ({
  className,
  parentRef,
  name,
  defaultValue,
  selectOptions,
  selectOptionsValues,
  setFormValue,
  getFormValue,
  triggerFormValidation,
  widget,
  disabledPrefix,
}) => {
  const [focus, setFocus] = useState(false);
  const [currentValue, setCurrentValue] = useState(getFormValue());

  const handleBlur = () => {
    setFocus(false);
    if (!selectOptionsValues.includes(getFormValue())) {
      setFormValue(defaultValue);
      setCurrentValue(defaultValue);
    }
    triggerFormValidation();
  };

  const handleMouseDown = (value: string) => {
    setFormValue(value);
    setCurrentValue(value);
    triggerFormValidation();
  };

  return (
    <Wrapper className={className}>
      <StyledSelectSearchCustom disabled={disabledPrefix}>
        <SearchBar
          id="searchBar"
          onChange={() => setCurrentValue(Utils.normalize(getFormValue()))}
          onFocus={() => { setFocus(true); setCurrentValue(''); }}
          onBlur={handleBlur}
          onCrossClick={() => { setFormValue(''); setCurrentValue(''); triggerFormValidation(); }}
          name={name}
          autoComplete={Utils.isChromeBrowser() ? 'chrome-off' : 'off'}
          parentRef={parentRef}
          searchType={SearchType.FORM}
          defaultValue={defaultValue || ''}
          widget={widget}
          disabledPrefix={disabledPrefix}
        />
      </StyledSelectSearchCustom>
      {focus && (
      <OptionContainer>
        {
          selectOptions
            .filter((option) => RegExp(Utils.escapeRexExSpecialChars(currentValue), 'i').test(Utils.normalize(option.name)))
            .map((option) => (
              <SelectSearchCustomButton type="button" value={option.value} key={option.value} onMouseDown={() => handleMouseDown(option.name)}>{option.name}</SelectSearchCustomButton>
            ))
        }
      </OptionContainer>
      )}
    </Wrapper>
  );
};

Select.defaultProps = {
  customText: '',
};

export default Select;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  ${theme('language', {
    ar: css`
       direction: rtl;
      `,
  })};
`;

export const StyledCaret = styled(Caret)`
  position: absolute;
  top: 15px;
  right: 2vw;
  ${theme('language', {
    ar: css`
       right: initial;
       left: 2vw;
      `,
  })};
`;

const selectStyle = css`
  background-color: ${(props) => props.theme.colors.COMMON.form.select.backgroundColor};
  border: 1px solid ${(props) => props.theme.colors.COMMON.form.select.borderColor};
`;

const StyledSelect = styled.select < SelectProps >`
  color: ${(props) => props.theme.colors.COMMON.form.select.color};
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  padding: 0 10px;
  border-radius: 0;
  font-size: 12pt;
  overflow: hidden;
  text-overflow: ellipsis;
  
  // Remove dropdown arrow
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  
  &:disabled {
    background-color: ${(props) => props.theme.colors.COMMON.form.select.disabled.backgroundColor};
    border: none;
  }
  
  opacity: ${(props) => (props.hasCustomText ? 0 : 1)};
  
  ${selectStyle}
`;

type StyledSelectSearchCustomProps = {
  disabled: boolean
}

const StyledSelectSearchCustom = styled.div<StyledSelectSearchCustomProps>`
  display: inline-block;
  width: 100%;
  min-height: 40px;
  padding: 0 10px 0 10px;
  margin-bottom: 15px;
  border-radius: 0;
  font-size: 12pt;
  overflow: hidden;
  text-overflow: ellipsis;
  
  // Remove dropdown arrow
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  
  // for the phone prefix wrapper to be no border
  background-color: ${(props) => props.disabled ? props.theme.colors.COMMON.form.select.searchCustom.disabled.backgroundColor : props.theme.colors.COMMON.form.select.backgroundColor};
  border: ${(props) => props.disabled ? 'none' : `1px solid ${props.theme.colors.COMMON.form.select.borderColor}`};
`;

export const SelectSearchCustomButton = styled.button`
   color: ${(props) => props.theme.colors.COMMON.form.select.searchCustom.button.color};
   cursor: pointer;
   background: none;
   border: none;
   padding: 5px 0 5px;
   width: 100%;
   text-align: left;
   ${theme('language', {
    ar: css`
      text-align: right;
      padding-right: 10px;
    `,
  })};
`;

const CustomSelectText = styled.div`
  position: absolute;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 12pt;
  
  ${selectStyle}
`;

export const OptionContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: 40px; // <!> Should not be lower than StyledSelectSearchCustom height component. Otherwise this component will overlay it <!>
  width: 100%;
  max-height: 150px;
  padding-left: 10px;
  overflow: scroll;
  background-color: ${(props) => props.theme.colors.COMMON.form.select.optionContainer.backgroundColor};
  border: 1px solid ${(props) => props.theme.colors.COMMON.form.select.optionContainer.borderColor};
  border-radius: 0;
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    max-height: 315px;
  }
`;

export const SelectSearchPrefixNumber = styled(SelectSearch)`
  width: 30%;
  @media only screen and (min-width : 401px) and (max-width : 500px){
    width: 40%;
  }
  @media only screen and (min-width : 321px) and (max-width : 400px){
    width: 60%;
  }
  @media only screen and (max-width : 320px){
    width: 80%; 
  }
`;
