import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';

type Props = {
  className?: string,
  minHeight?: number,
  kakaoModalDisplayed?: boolean,
}

export const FullPageContainer = styled.div < Props >`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${theme('language', {
    ar: css`
      text-align: right;
      text-align: -moz-right;
      text-align: -webkit-right;
      div, input, label, h1, h2, h3, h4 {
        direction: rtl;
      }
    `,
  })};
  
  ${theme('brand', {
    AMQ: css`font-family: "bemboregular", sans-serif;`,
    BAL: css`font-family: "universltcyr", sans-serif;`,
    BR: css`
      font-family: "labgrotesque", sans-serif;
      letter-spacing: 2px;
    `,
    BV: css`font-family: "bv", sans-serif;`,
    GUCCI: css`font-family: "guccisans", sans-serif;`,
    YSL: css`font-family: "HelveNeu", sans-serif;`,
  })};
  min-height: ${(props) => props.minHeight}px;
  // specific fix for iPad rotation (don't use vh in other cases because of iOS15 Safari behaviour)
  ${theme('prospectSource', {
    DCC: css`
        min-height: 100vh;
      `,
  })};
  max-width: 1024px;
  margin: auto;
  padding: 15px;
  background-color: ${(props) => props.theme.colors.COMMON.pageContainer.backgroundColor};
  box-sizing: border-box;
  @media only screen and (min-width: ${screenSizes.ipad.width}) {
    padding: 15px 125px;
  }
`;
