import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import { Button } from '@/common/components/form/Button';

export const IndexLetter = styled.div`
 color : ${(props) => props.theme.colors.COUNTRY.indexLetter.color}; // default input placeholder color
 margin: 17px 0 17px;

 ${theme('brand', {
    AMQ: css`
      font-size: 18pt
    `,
    BAL: css`
      font-size: 14pt;
    `,
    BR: css`
      font-size: 14pt;
      font-weight: bold;
    `,
    BV: css`
      font-size: 18pt;
    `,
    GUCCI: css`
      font-size: 18pt;
    `,
    YSL: css`
      font-size: 12pt;
    `,
  })};
`;

export const LegalReasons = styled.div`
  font-size: 10pt;
  color: ${(props) => props.theme.colors.COUNTRY.labelColor.color};
  margin-bottom: 10px;
`;

export const AlternativeSearchButton = styled(Button)`
margin-top: 100px;
`;

export const CountriesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
