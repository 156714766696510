import { Brand, Customer, PreferredMethodOfContact } from '@/types';
import api from '@/api';
import { ICheckCustomerResponseV2 } from '@/types/api';
import { EmailValidityStatusEnum } from '@/types/enum/email-validity-status.enum';

export default class InformationService {
  static async checkSecondPointOfContact(preferredMethodOfContact: PreferredMethodOfContact, customer: Customer, phoneNum: string, phonePrefix: string, email: string, brand: Brand, token?: string): Promise<ICheckCustomerResponseV2> {
    let checkResult: ICheckCustomerResponseV2;
    if (preferredMethodOfContact === PreferredMethodOfContact.EMAIL) {
      // if the preferred method is the email, we need to check the second point of contact => phone
      checkResult = await api.checkCustomerV2(
        customer,
        {
          prefix: phonePrefix,
          number: phoneNum,
        },
        '',
        brand.codeSiebel,
        PreferredMethodOfContact.PHONE,
        token,
        '',
        true
      ).then((result) => result.data);
    } else {
      // if the preferred method is the phone, we need to check the second point of contact => email
      checkResult = await api.checkCustomerV2(
        customer,
        {
          prefix: '',
          number: '',
        },
        email,
        brand.codeSiebel,
        PreferredMethodOfContact.EMAIL,
        token,
        '',
        true
      ).then((result) => result.data);
    }
    return checkResult;
  }

  static hasSecondPointOfContact(preferredMethodOfContact: PreferredMethodOfContact, number?: string, email?: string) {
    return Boolean((preferredMethodOfContact === PreferredMethodOfContact.EMAIL && number) || (preferredMethodOfContact === PreferredMethodOfContact.PHONE && email));
  }

  static hasNonCertifiedSecondPointOfContact(preferredMethodOfContact: PreferredMethodOfContact, shouldPhoneBeDisabled: boolean, shouldEmailBeDisabled: boolean, number?: string, email?:string) {
    return Boolean((preferredMethodOfContact === PreferredMethodOfContact.EMAIL && number && !shouldPhoneBeDisabled) || (preferredMethodOfContact === PreferredMethodOfContact.PHONE && email && !shouldEmailBeDisabled));
  }

  static splitPrefixAndCountryFromPrefixLabel(prefixLabel: string) {
    return ({
      phonePrefix: prefixLabel?.split(' ')?.[0],
      prefixCountry: /\((.*)\)/g.exec(prefixLabel)?.[1]
    });
  }
}
