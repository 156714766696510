import { useStateMachine } from 'little-state-machine';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { LogoAndChangeLanguage } from '@pages/Welcome/components/LogoAndChangeLanguage';
import { useBackgroundLoading } from '@services/hooks/useBackgroundLoading';
import {
  ClassicText,
  ThanksTextUpdateMarketingPage,
  UpdateMarketingConsentTextWrapper
} from '@pages/Registered/registered.styles';
import mp from '@services/mixpanel/mixpanel.service';
import { ProgressBar } from '@/common/components';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import { FullPageLoader } from '@/common/components/Loader';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import { ThemeType } from '@/types/enum/themeType';
import api from '@/api';
import {
  updateAlreadyExistingCustomer,
  updateAlreadyRegistered,
  updateCustomerInformation,
  updateErrorTypeOnError,
  updateIsLoading,
  updateLightLabels,
  updatePrivacyConf,
} from '@/littleStateMachine/actions';
import BrandContainer from '@/common/components/containers/BrandContainer';

const UpdateMarketingConsentThankYou: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.UPDATE_MARKETING_CONSENT_THANK_YOU);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state and actions from store
  const { actions, state } = useStateMachine({ updateErrorTypeOnError, updatePrivacyConf, updateLightLabels, updateCustomerInformation, updateAlreadyRegistered, updateAlreadyExistingCustomer, updateIsLoading });
  const { brand, prospectSource, translations, customer, flow, isLoading, backgrounds } = state;
  const { style: { colors } } = brand;
  useThemeColor(brand, ThemeType.FORM);

  // current page
  const localStep = PageID.UPDATE_MARKETING_CONSENT_THANK_YOU;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // custom hooks
  const [isBackgroundLoading, setIsBackgroundLoading] = useState(false);
  const loadBackgrounds = useBackgroundLoading(setIsBackgroundLoading, backgrounds?.registeredBackground?.backgroundUrl, backgrounds?.registeredDesktopBackground?.backgroundUrl);

  useEffect(() => {
    mp.pageView(localStep);
    (async function fetch() {
      if (brand.code && flow.authorizedPages.includes(localStep)) {
        try {
          actions.updateIsLoading(true);
          const { data: updateInformationPayload } = await api.getPartialUpdateInformation(token);
          actions.updateAlreadyRegistered(true);
          actions.updateAlreadyExistingCustomer(updateInformationPayload);
          // The privacy language drives the application language
          const { data: lightPayload } = await api.fetchConf(token, translations.lightLanguageCode);
          actions.updateLightLabels(lightPayload);
          actions.updateIsLoading(false);
          await loadBackgrounds(brand.backgrounds?.welcomePage?.backgroundUrl, brand.desktopBackgrounds?.welcomePage?.backgroundUrl);
        } catch (err) {
          if (err?.response?.data) {
            actions.updateErrorTypeOnError(err.response.data);
          }
          navigate('/error');
        }
      } else {
        navigate(`/${token}`);
      }
    }());
  }, [navigate, token]);

  if (brand.code && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, language: translations.lightLanguageCode, country: customer.customerContact.address.country, prospectSource, isLegal: true, background: backgrounds.currentBackground, desktopBackground: backgrounds.currentDesktopBackground, hasDesktopBackground: Boolean(backgrounds.currentDesktopBackground?.backgroundUrl) && Boolean(backgrounds.currentDesktopBackground?.backgroundPosition) }}>
        { !isLoading && !isBackgroundLoading ? (
          <BrandContainer>
            <ProgressBar numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} />
            <LogoAndChangeLanguage brandCode={brand.code} />
            <UpdateMarketingConsentTextWrapper>
              <ClassicText>{t('web.updateMarketingConsent.thankYou')}</ClassicText>
              <ClassicText>{t('web.updateMarketingConsent.member')}</ClassicText>
              <ThanksTextUpdateMarketingPage>{t('web.updateMarketingConsent.contact')}</ThanksTextUpdateMarketingPage>
            </UpdateMarketingConsentTextWrapper>
          </BrandContainer>
        ) : <FullPageLoader />}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default UpdateMarketingConsentThankYou;
