import React from 'react';
import styled from 'styled-components';
import useScrollToTop from '@/services/hooks/useScrollToTop';
import { Background } from '@/types';

interface Props {
  id?: string,
  onClose: () => void,
  timeout?: number,
  toggleFadeOut?: boolean,
  background?: Background,
}

const Modal: React.FC<Props> = ({
  id,
  children,
  onClose,
  timeout,
  toggleFadeOut,
  background,
}) => {
  useScrollToTop();
  const closeModal = () => onClose();
  if (timeout) {
    setTimeout(closeModal, timeout * 1000);
  }
  return (
    <StyledModal id={id} onClick={closeModal} toggleFadeOut={toggleFadeOut} background={background}>
      <ModalBody>
        {children}
      </ModalBody>
    </StyledModal>
  );
};

export default Modal;

type StyledModalProps = {
  toggleFadeOut?: boolean,
  background?: Background
}

const StyledModal = styled.div.attrs((props: StyledModalProps) => ({
  toggleFadeOut: props.toggleFadeOut,
  background: props.background,
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.COMMON.modal.backgroundColor};
  z-index: 101;
  opacity: ${(props) => (props.toggleFadeOut ? 0 : 1)};
  transition: opacity linear 0.5s;  
  background-position: ${(props) => props.background?.backgroundPosition};
  background-image: ${(props) => `url(${props.background?.backgroundUrl})`};
  background-attachment: fixed;
  background-size: cover;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
