import React from 'react';
import { SAContactIcon, SAContactInfoWrapper } from '@pages/Registered/registered.styles';
import { SalesAdvisor } from '@/types';

interface SAContactInfoProps {
  salesAdvisor: SalesAdvisor;
  phoneIcon: string;
  mailIcon: string;
}

export const SAContactInfo: React.FC<SAContactInfoProps> = ({ salesAdvisor, phoneIcon, mailIcon }) => (
  <SAContactInfoWrapper>
    <div id="MailInfo">
      <SAContactIcon src={mailIcon} alt="Mail" />
      {salesAdvisor.email}
    </div>
    <div id="PhoneInfo">
      <SAContactIcon src={phoneIcon} alt="Phone" />
      {salesAdvisor.phoneNumber}
    </div>
  </SAContactInfoWrapper>
);
