import React from 'react';
import Utils from '@utils/utils';
import { useStateMachine } from 'little-state-machine';
import Title from '@/common/components/Title';
import { useTranslation } from '@/services/hooks';
import { ProspectSource } from '@/types';

type Props = {
  prospectSource: ProspectSource,
}

export const ContactTitle: React.FC<Props> = ({ prospectSource }) => {
  const t = useTranslation();
  const { state } = useStateMachine();
  const { featuresActivated } = state;
  if (prospectSource === ProspectSource.NQP_WIRE) {
    return (
      <Title>
        {t('web.nqpContactWelcome')}
      </Title>
    );
  }
  if (featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED || featuresActivated.IS_GUCCI_CREATE_USED) {
    return (
      <Title>
        {t('web.contactWelcome')}
      </Title>
    );
  }
  return (
    <Title>
      {Utils.isLuceProspectSource(prospectSource)
        ? t('web.contactQuestionLuce')
        : t('dcc.contactQuestion')}
    </Title>
  );
};
