import React from 'react';
import { StyledContainer } from '@pages/Error/error.styles';
import { ThemeProvider } from 'styled-components/macro';
import { useStateMachine } from 'little-state-machine';

export const MainWrapper: React.FC = ({ children }) => {
  const { state } = useStateMachine();
  const { translations, brand, backgrounds } = state;
  const { code, codeTriggeredByAnError, style: { colors } } = brand;
  return (
    <ThemeProvider theme={{ colors, brand: code || codeTriggeredByAnError, background: backgrounds.currentBackground, language: translations.lightLanguageCode, isOnErrorPage: true }}>
      <StyledContainer>
        {children}
      </StyledContainer>
    </ThemeProvider>
  );
};
