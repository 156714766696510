import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { FullPageLoader } from '@common/components/Loader';
import FormHeader from '@common/components/form/FormHeader';
import FlowService from '@services/flow.service';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ButtonContainer, FormContainer } from '@common/components/containers';
import Utils from '@utils/utils';
import { SectionTitle } from '@pages/Information/information.styles';
import { useStateMachine } from 'little-state-machine';
import { useLastPageVisited, useScrollToTop, useTranslation } from '@services/hooks';
import { Title } from '@common/components';
import { CompanyName } from '@pages/UpdateNewPosCustomer/components/CompanyName';
import { CivilTitle } from '@common/components/form/information/CivilTitle';
import { Names } from '@common/components/form/information/Names';
import { BirthDate } from '@common/components/form/information/BirthDate';
import { CustomerType } from '@pages/UpdateNewPosCustomer/components/CustomerType';
import { CustAnnivDate } from '@pages/UpdateNewPosCustomer/components/CustAnnivDate';
import { SaComment } from '@pages/UpdateNewPosCustomer/components/Comment';
import { ErrorMessage } from '@common/components/form';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { PageID } from '@/types/enum/pageID';
import { TYPE_DROPDOWN } from '@/types';
import PageContainer from '@/common/components/containers/PageContainer';
import api from '@/api';
import {
  updateAlreadyExistingCustomer,
  updateAlreadyRegistered,
  updateBrandCountryConfiguration,
  updateCustomerInformation,
  updateErrorTypeOnError,
  updatePrivacyConf
} from '@/littleStateMachine/actions';
import mp from '@/services/mixpanel/mixpanel.service';

const UpdateNewPosCustomer: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.UPDATE_NEW_POS_CUSTOMER);
  const navigate = useNavigate();
  const t = useTranslation();
  const { state, actions } = useStateMachine({ updateAlreadyRegistered, updateAlreadyExistingCustomer, updateErrorTypeOnError, updateBrandCountryConfiguration, updatePrivacyConf, updateCustomerInformation });
  const { brand, prospectSource, flow, languages, mixPanelToken, actualToken, brandCountryConfiguration } = state;
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useParams<{ token: string }>();
  const localStep = PageID.UPDATE_NEW_POS_CUSTOMER;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Wrong usage. Component to be migrated.
  const [showDateError, setShowDateError] = useState(false);

  const formMethods = useForm<InformationFormProps>({ mode: 'onChange' });

  const { formState: { isSubmitting, isValid } } = formMethods;

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
    (async function fetch() {
      if (brand.code && flow.authorizedPages.includes(localStep)) {
        try {
          setIsLoading(true);
          const { data } = await api.getPartialUpdateInformation(token);
          actions.updateAlreadyRegistered(true);
          actions.updateAlreadyExistingCustomer(data);
          const customerAddress = data.customerContact.address;
          const { data: payload } = await api.fetchBrandCountryConf(token, customerAddress.country, customerAddress.state);
          actions.updateBrandCountryConfiguration(payload);
          setIsLoading(false);
        } catch (err) {
          if (err?.response?.data) {
            actions.updateErrorTypeOnError(err.response.data);
          }
          navigate('/error');
        }
      } else {
        navigate(`/${token}`);
      }
    }());
  }, [navigate, token]);

  const onSubmit = formMethods.handleSubmit(async ({
    title = '',
    firstName,
    lastName,
    middleName,
    furiganaFirstName,
    furiganaLastName,
    day,
    month,
    year
  }) => {
    const informationInfos = {
      title,
      firstName,
      lastName,
      middleName,
      furiganaFirstName,
      furiganaLastName,
      day: String(day),
      month: String(month),
      year: String(year)
    };
    actions.updateCustomerInformation(informationInfos);
    const nextPage = FlowService.nextPage(state, localStep);
    navigate(`/${token}/${nextPage}`);
  });

  if (brand && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, prospectSource }}>

        { isLoading ? <FullPageLoader />
          : (
            <PageContainer>
              <FormHeader
                numberOfSteps={FlowService.getTotalNumberOfSteps(state)}
                currentStep={currentStep}
                languages={languages}
                type={TYPE_DROPDOWN.DEFAULT}
                defaultLogoUrl={defaultLogoUrl}
              />
              <Title>{t('aboutClient.edit')}</Title>
              <FormProvider {...formMethods}>
                <form>
                  <FormContainer onKeyDown={(keyDownEvent) => Utils.validateForm(keyDownEvent, isValid, onSubmit)}>
                    <SectionTitle>{t('dcc.personalInformation')}</SectionTitle>
                    <CivilTitle />
                    <Names />
                    <BirthDate formMethods={formMethods} setShowDateError={setShowDateError} showDateError={showDateError} />
                    {showDateError && formMethods.errors.day?.type === 'dateFormatError' && <ErrorMessage>{t('dcc.dateOfBirthError')}</ErrorMessage>}
                    {showDateError && formMethods.errors.day?.type === 'minimalAgeError' && <ErrorMessage>{t('web.minimalAge')}</ErrorMessage>}
                    <CustAnnivDate />
                    <CompanyName />
                    <CustomerType />

                    <SectionTitle>{t('dcc.comment')}</SectionTitle>
                    <SaComment />

                  </FormContainer>
                  <ButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.UPDATE_NEW_POS_CUSTOMER} onSubmit={onSubmit} />
                </form>
              </FormProvider>
            </PageContainer>
          )}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default UpdateNewPosCustomer;
