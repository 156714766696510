import Utils from '@utils/utils';
import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { ProspectSource } from '@/types';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { Field } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  prospectSource: ProspectSource
}

export const MiddleName: React.FC<Props> = ({ prospectSource }) => (
  <ConnectForm>
    {({ register, customer, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="middleName">{t('dcc.middleName')}</Label>
        <Field
          id="middleName"
          name="middleName"
          ref={register({ required: true, minLength: 1, validate: (value: string) => Utils.doesNotContainOnlySpaces(value) })}
          defaultValue={customer.customerInfo.middleName}
          autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'additional-name'}
          maxLength={50}
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
