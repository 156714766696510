import { useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { PageID } from '@/types/enum/pageID';
import { updateLastPageVisited } from '@/littleStateMachine/actions';

const useLastPageVisited = (pageId: PageID) => {
  const { actions } = useStateMachine({ updateLastPageVisited });
  useEffect(() => {
    actions.updateLastPageVisited({ lastPageVisited: pageId });
  }, []);
};

export default useLastPageVisited;
