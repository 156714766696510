import React from 'react';

const CrossIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    version="1"
    viewBox="0 0 225 225"
  >
    <path
      d="M367 1931c-99-100-109-120-85-164 6-12 146-160 311-329 164-168 299-309 300-311 1-3-133-144-299-314-165-169-306-318-312-330-24-44-14-64 85-164 84-86 101-99 130-99 31 0 57 24 297 268 145 147 276 282 291 300 15 17 33 32 40 32s30-19 50-42c21-24 152-159 292-300 229-234 256-258 286-258 29 0 46 13 130 99 106 108 113 125 75 179-13 18-154 166-314 329l-292 298 295 300c162 165 303 314 313 330 35 53 28 70-77 176-84 86-101 99-130 99-30 0-57-24-286-257-140-142-271-277-292-300-20-24-43-43-50-43s-25 15-40 33c-15 17-146 152-291 300-241 244-266 267-297 267-29 0-46-13-130-99z"
      transform="matrix(.1 0 0 -.1 0 225)"
    />
  </svg>
);

export default CrossIcon;
