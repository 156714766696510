import { useStateMachine } from 'little-state-machine';
import { useTranslation } from '@/services/hooks/index';
import { Country } from '@/types';

const useTranslatedCountries = () => {
  const { state } = useStateMachine();
  const { countries } = state;
  const t = useTranslation();

  return countries.map((country: Country) => ({
    ...country,
    name: t(`country.${country.code.toLocaleLowerCase()}`),
  })).sort((a: Country, b: Country) => a.name.localeCompare(b.name));
};

export default useTranslatedCountries;
