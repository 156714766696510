import { AxiosResponse } from 'axios';
import { IResponseQRCode } from '@/types/api';

export default class RequestService {
  static async tryToFetchQrCode(fetchQrCodeFunction: (token: string) => Promise<AxiosResponse<IResponseQRCode>>, numberOfTries: number, timeToWaitInMsAtEachTries: number, token: string = '') {
    let qrCodePayload;
    for (let i = 0; i < numberOfTries; i += 1) {
      try {
        qrCodePayload = (await fetchQrCodeFunction(token)).data;
        break;
      } catch (e) {
        qrCodePayload = null;
        // wait timeToWaitInMs ms (wait for createCustomer callback url to be called by Siebel - will upsert the clientId that we need)
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, timeToWaitInMsAtEachTries));
      }
    }
    return qrCodePayload;
  }

  static async tryToFetchQrCodeWithBody(fetchQrCodeFunction: (token: string, body: { vCardFormattedString: string }) => Promise<AxiosResponse<IResponseQRCode>>, body: { vCardFormattedString: string }, numberOfTries: number, timeToWaitInMsAtEachTries: number, token: string = '') {
    let qrCodePayload;
    for (let i = 0; i < numberOfTries; i += 1) {
      try {
        qrCodePayload = (await fetchQrCodeFunction(token, body)).data;
        break;
      } catch (e) {
        qrCodePayload = null;
        // wait timeToWaitInMs ms (wait for createCustomer callback url to be called by Siebel - will upsert the clientId that we need)
        // eslint-disable-next-line no-promise-executor-return
        await new Promise((resolve) => setTimeout(resolve, timeToWaitInMsAtEachTries));
      }
    }
    return qrCodePayload;
  }
}
