import { useStateMachine } from 'little-state-machine';
import React, { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ThemeProvider } from 'styled-components/macro';
import { CheckBoxInputContainer, GdprContainer, GdprFooter } from '@pages/Legal/legal.styles';
import { PrivacyPolicyAndChangeLanguage } from '@pages/Legal/components/PrivacyPolicyAndChangeLanguage';
import api from '@/api';
import {
  updateAlreadyExistingCustomer,
  updateAlreadyRegistered,
  updateCustomerInformation,
  updateErrorTypeOnError,
  updateIsLoading,
  updateLightLabels,
  updatePrivacyConf,
} from '@/littleStateMachine/actions';
import { ProgressBar } from '@/common/components';
import { ButtonContainer, PageContainer } from '@/common/components/containers';
import { CheckboxField, ErrorMessage } from '@/common/components/form';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import mp from '@/services/mixpanel/mixpanel.service';
import { ImgFormLogo, LogoFormWrapper } from '@/common/components/Logo';
import { FullPageLoader } from '@/common/components/Loader';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import { ThemeType } from '@/types/enum/themeType';
import { Checkbox } from '@/types';
import { Privacy } from '@/types/privacy';
import utils from '@/utils/utils';

const UpdateMarketingConsent: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.UPDATE_MARKETING_CONSENT);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state and actions from store
  const { actions, state } = useStateMachine({ updateErrorTypeOnError, updatePrivacyConf, updateLightLabels, updateCustomerInformation, updateAlreadyRegistered, updateAlreadyExistingCustomer, updateIsLoading });
  const { brand, privacy, prospectSource, translations, customer, flow, isLoading, mixPanelToken, actualToken } = state;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  useThemeColor(brand, ThemeType.FORM);

  // current page
  const localStep = PageID.UPDATE_MARKETING_CONSENT;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // Prepare the form
  const { register, handleSubmit, formState, errors } = useForm({ mode: 'onChange' });
  const { isSubmitting, isValid } = formState;

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
    (async function fetch() {
      if (brand.code && flow.authorizedPages.includes(localStep)) {
        try {
          actions.updateIsLoading(true);
          const { data: updateInformationPayload } = await api.getPartialUpdateInformation(token);
          actions.updateAlreadyRegistered(true);
          actions.updateAlreadyExistingCustomer(updateInformationPayload);
          const { data: privacyPayload } = await api.fetchPrivacyConf(token, updateInformationPayload.customerContact.address.country, updateInformationPayload.customerContact.address.state, translations.lightLanguageCode);
          actions.updatePrivacyConf(privacyPayload);
          // The privacy language drives the application language
          const { data: lightPayload } = await api.fetchConf(token, privacyPayload.privacyLanguageCode);
          actions.updateLightLabels(lightPayload);
          actions.updateIsLoading(false);
        } catch (err) {
          if (err?.response?.data) {
            actions.updateErrorTypeOnError(err.response.data);
          }
          navigate('/error');
        }
      } else {
        navigate(`/${token}`);
      }
    }());
  }, [navigate, token]);

  const onSubmit = handleSubmit(async (data) => {
    const privacies : Privacy[] = Object.values(data);
    actions.updateCustomerInformation({ privacies });
    const privacyForMarketingActivities = utils.isCustomerMarketingConsentChecked(privacies, state.privacy.checkboxes);
    mp.acceptPrivacy(privacyForMarketingActivities);
    const nextPage = FlowService.nextPage(state, localStep);
    navigate(`/${token}/${nextPage}`);
  });

  if (brand.code && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, language: translations.lightLanguageCode, country: customer.customerContact.address.country, prospectSource, isLegal: true }}>
        { !isLoading ? (
          <PageContainer>
            <ProgressBar numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} />
            <PrivacyPolicyAndChangeLanguage brandCode={brand.code} />
            <LogoFormWrapper><ImgFormLogo id="brandLogo" src={defaultLogoUrl} alt="Logo" /></LogoFormWrapper>
            <form>
              <GdprContainer>
                {privacy.checkboxes.map((checkbox: Checkbox, i: number) => (
                  <CheckBoxInputContainer key={checkbox.textKey}>
                    <CheckboxField
                      name={`privacy${i}.state`}
                      text={t(checkbox.textKey, true)}
                      isTextHTML
                      required={checkbox.mandatory}
                      parentRef={register({ required: checkbox.mandatory })}
                      defaultChecked={checkbox.preChecked}
                    />
                    <input type="hidden" name={`privacy${i}.fieldCode`} ref={register} value={checkbox.key} />
                  </CheckBoxInputContainer>
                ))}
                {errors.global && <ErrorMessage>{errors.global.message}</ErrorMessage> }
              </GdprContainer>
              <GdprFooter>
                <div dangerouslySetInnerHTML={{ __html: t(privacy.footerTextKey, true) }} />
              </GdprFooter>
              <ButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.LEGAL} privacy={privacy} onSubmit={onSubmit} />
            </form>
          </PageContainer>
        ) : <FullPageLoader />}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${token}`} replace />;
};

export default UpdateMarketingConsent;
