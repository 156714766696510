import { useEffect } from 'react';

const useExternalScripts = (url: string, integrity?: string, crossorigin?: string) => {
  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.setAttribute('src', url);
    integrity && script.setAttribute('integrity', integrity);
    crossorigin && script.setAttribute('crossorigin', crossorigin);
    head?.appendChild(script);

    return () => {
      head?.removeChild(script);
    };
  }, [url]);
};

export default useExternalScripts;
