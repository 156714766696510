import { FieldPhone, FormGroup, OptionalLegendPhone } from '@pages/Contact/contact.styles';
import regex from '@utils/regex';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import ContactService from '@pages/Contact/contact.service';
import { UseFormMethods } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import Utils from '@utils/utils';
import { FormWarningContext } from '@common/components/form/FormWarningContext';
import { Country, PreferredMethodOfContact, PrefixObject, TranslatedCountry } from '@/types';
import { ConnectForm } from '@/common/components/form';
import Label from '@/common/components/form/Label';
import { SelectSearchPrefixNumber } from '@/common/components/form/Select';
import { Widget } from '@/common/components/SearchBar';
import ErrorMessage from '@/common/components/form/ErrorMessage';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { PageID } from '@/types/enum/pageID';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  preferredMethodOfContact: PreferredMethodOfContact,
  showPhoneError: boolean,
  phonePrefixLabel: string,
  setPhonePrefixLabel: Dispatch<SetStateAction<string>>,
  translatedCountries: Country[],
  phoneUserInputValue: string,
  setPhoneUserInputValue: Dispatch<SetStateAction<string>>,
  phoneError: boolean,
  formMethods: UseFormMethods<InformationFormProps>,
  setPhoneError: Dispatch<SetStateAction<boolean>>,
  setEmailError: Dispatch<SetStateAction<boolean>>,
  setShowPhoneError: Dispatch<SetStateAction<boolean>>,
  localStep: PageID,
  shouldBeDisabled?: boolean,
}

export const Phone: React.FC<Props> = ({
  preferredMethodOfContact,
  phonePrefixLabel,
  setPhonePrefixLabel,
  translatedCountries,
  showPhoneError,
  phoneUserInputValue,
  setPhoneUserInputValue,
  phoneError,
  formMethods,
  setPhoneError,
  setEmailError,
  setShowPhoneError,
  localStep,
  shouldBeDisabled,
}) => {
  const { state } = useStateMachine();
  const { featuresActivated } = state;
  const selectSearchOptions = translatedCountries.map((country: Country) => ({ value: country.code, name: `${country.phonePrefix} (${country.code})` })).sort((a: TranslatedCountry, b: TranslatedCountry) => a.value.localeCompare(b.value));
  const selectOptionsValues = selectSearchOptions.map((option: PrefixObject) => (option.name));
  const isFieldDisabled = shouldBeDisabled || (localStep === PageID.INFORMATION && featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && preferredMethodOfContact === PreferredMethodOfContact.PHONE);
  const { cancelProceedAttempt } = useContext(FormWarningContext);
  // Issue 1 : prefixNumber container shouldn't extend width size
  return (
    <ConnectForm>
      {({ register, t, setValue, getValues, trigger }: UseFormMethods<InformationFormProps> & TranslationFunction) => (
        <>
          <Label htmlFor="phoneNumber">{t('dcc.phoneTitle')}</Label>
          {preferredMethodOfContact === PreferredMethodOfContact.EMAIL && <OptionalLegendPhone>{t('dcc.optional')}</OptionalLegendPhone>}
          <FormGroup>
            <SelectSearchPrefixNumber
              name="phonePrefixLabel"
              id="phonePrefixLabel"
              parentRef={register({
                validate: {
                  required: (value: string) => (preferredMethodOfContact !== PreferredMethodOfContact.EMAIL ? Boolean(value) : true),
                  doValueExist: (value: string) => selectOptionsValues.includes(value),
                },
              })}
              defaultValue={phonePrefixLabel}
              selectOptions={selectSearchOptions}
              selectOptionsValues={selectOptionsValues}
              setFormValue={(newValue: string) => {
                setValue('phonePrefixLabel', newValue);
                setPhonePrefixLabel(newValue);
              }}
              getFormValue={() => getValues('phonePrefixLabel')}
              triggerFormValidation={trigger}
              widget={Widget.DEFAULT}
              disabledPrefix={isFieldDisabled}
            />
            <FieldPhone
              name="phoneNum"
              id="phoneNum"
              type="tel"
              autoComplete={Utils.isFirefoxBrowser() ? 'tel' : 'tel-national'}
              ref={register({
                pattern: regex.phone,
                required: preferredMethodOfContact !== PreferredMethodOfContact.EMAIL,
              })}
              defaultValue={phoneUserInputValue}
              hasError={phoneError && showPhoneError}
              onBlur={() => {
                ContactService.handlePhoneBlur(setShowPhoneError);
                ContactService.checkInputFormat(formMethods, setPhoneError, setEmailError, preferredMethodOfContact);
              }}
              onFocus={() => ContactService.handlePhoneFocus(formMethods, setShowPhoneError, cancelProceedAttempt)}
              maxLength={40}
              onKeyDown={(event) => { ContactService.avoidAlphaCharacters(event); ContactService.blurField(event); }}
              onChange={(event) => { ContactService.removeAlphaCharacters(event, 'phoneNum', setValue); setPhoneUserInputValue(getValues().phoneNum); }}
              disabled={isFieldDisabled}
            />
          </FormGroup>
          {phoneError && showPhoneError && <ErrorMessage>{t('dcc.phoneErrorDetailed')}</ErrorMessage>}
        </>
      )}
    </ConnectForm>
  );
};
