const regex = {
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(((?!gmal\.|gmai\.|gmaill\.|gamil\.|hotnail\.|hotmaol\.|hotmai\.|yahooo\.|yhoo\.|ickoud\.|yhaoo\.|hitmail\.|hotmil\.|hoymail\.)[a-zA-Z\-0-9]+\.)+(?!con$|comn$|cpm$|cmo$|come$|om$)[a-zA-Z]{2,}))$/i,

  phone: /^\d{4,12}$/,
  pobox: /^.{0,200}$/,
  zipcode: /^.{0,30}$/,
  zipcodeCheck: /^.{4,30}$/,
  zipcodeCheckTW: /^.{3,30}$/,
  city: /^.{2,50}$/,
  street: /^.{4,200}$/im,
};

export default regex;
