import { GlobalState } from 'little-state-machine';
import { Page } from '@/types';
import { PageID } from '@/types/enum/pageID';

export default class FlowService {
  // pageName param allows to skip several pages at the same time if needed
  static nextPage = (state: GlobalState, currentPageName: PageID, pageNameToJumpIn? : PageID, pages?: Page[]) => {
    const indexNextPage = FlowService.getIndex(state, currentPageName) + 1;
    const nextPage = pages ? pages[indexNextPage].name : pageNameToJumpIn || state.flow.pages[indexNextPage].name;
    if (nextPage && !state.flow.authorizedPages.includes(nextPage)) {
      state.flow.authorizedPages.push(nextPage);
    }
    return nextPage.toLowerCase();
  };

  static addPageInFlow = (state: GlobalState, pageToAdd: Page, indexToInsert: number) => {
    if (FlowService.getIndex(state, pageToAdd.name) === -1) {
      state.flow.pages.splice(indexToInsert, 0, pageToAdd);
    }
  };

  static addPageInAuthorizedPages = (state: GlobalState, pageName: PageID) => {
    if (state.flow.authorizedPages.indexOf(pageName) === -1) {
      state.flow.authorizedPages.push(pageName);
    }
  };

  static removePageFromFlow = (state: GlobalState, pageNameToRemove: PageID) => {
    const authorizedPageToRemoveIndex = state.flow.authorizedPages.indexOf(pageNameToRemove);
    if (authorizedPageToRemoveIndex !== -1) {
      state.flow.authorizedPages.splice(authorizedPageToRemoveIndex, 1);
    }
    const pageToRemoveIndex = FlowService.getIndex(state, pageNameToRemove);
    if (pageToRemoveIndex !== -1) {
      state.flow.pages.splice(pageToRemoveIndex, 1);
    }
  };

  static getIndex = (state: GlobalState, pageName: PageID): number => state.flow.pages.findIndex((page: Page) => page.name === pageName);

  static getCurrentStepNumber = (state: GlobalState, pageName: PageID): number => {
    const currentIndex = FlowService.getIndex(state, pageName);
    const viewedPages = [...state.flow.pages];
    viewedPages.splice(currentIndex + 1);
    return viewedPages.reduce((acc: number, page: Page) => acc + page.stepValue, 0);
  };

  static getTotalNumberOfSteps = (state: GlobalState): number => state.flow.pages.reduce((acc: number, page: Page) => acc + page.stepValue, 0);
}
