import { useEffect } from 'react';

const useScrollToTop = () => {
  // Scroll to top of window when the modal is mounted or unmounted
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
};

export default useScrollToTop;
