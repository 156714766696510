export type SetModal = {
  open: boolean,
  socialNetwork: SocialNetwork
}

export enum SocialNetwork {
  Line = 'line',
  WeChat = 'weChat',
  EMPTY = ''
}
