import mixpanel, { Dict } from 'mixpanel-browser';
import { LightConfiguration, PreferredMethodOfContact, SalesAdvisor } from '@/types';
import Config from '@/config';
import { ErrorTypeMixPanel } from '@/types/brandedTokenError';
import mpFormatter from '@/services/mixpanel/mixpanel.formatter.service';
import { CustomerFlow, MixpanelCustomerFlow } from '@/types/enum/customerFlow';
import api from '@/api';
import { PageID } from '@/types/enum/pageID';
import { EmailValidityStatusEnum } from '@/types/enum/email-validity-status.enum';

let pageCloseListenerAdded = false;

export default class MixPanelService {
  static init(mixPanelToken: string, dynamicToken: string, payload: LightConfiguration | null, resetStorage: boolean = false) {
    try {
      // init a mix panel session and change storage from cookies to local storage
      mixpanel.init(mixPanelToken, { persistence: 'localStorage' });

      // In order to delete superProperties that persist between sessions.
      if (resetStorage) mixpanel.reset();

      // set up super properties sent at each mixpanel.track
      if (!payload) {
        MixPanelService.register({ dynamicToken });
      } else {
        const {
          prospectSource,
          i18n: { code: language },
          brand: { code: brand },
          store: { countryCode: storeCountry, storeCode: storeId, region: storeRegion },
        } = payload;
        const departmentStoreCard = payload.event?.departmentStoreCard ?? '';
        const addressFlow = mpFormatter.getAddressFlow(payload.featuresActivated.IS_CONTACT_FORM_EXTENDED, payload.featuresActivated.IS_ADDRESS_PAGE_DISPLAYED);
        MixPanelService.register({ dynamicToken, prospectSource, language, brand, storeCountry, storeId, version: Config.version, storeRegion, departmentStoreCard, addressFlow });
      }
    } catch (e) {
      // mixpanel error
    }
  }

  static register(superProperties: Dict) {
    try {
      // set up super properties sent at each mixpanel.track
      mixpanel.register(superProperties);
    } catch (e) {
      // mixPanel error
    }
    this.trackClosePageEvent();
  }

  static trackClosePageEvent() {
    if (!pageCloseListenerAdded) {
      window.addEventListener('beforeunload', () => {
        this.pageView();
      });
      pageCloseListenerAdded = true;
    }
  }

  static track(eventName: string, properties?: Dict) {
    try {
      mixpanel.track(eventName, properties);
    } catch (e) {
      // mixPanel error
    }
  }

  static async updateCustomerFlowGlobally(token: string) {
    const { data: { customerFlow } } = await api.getCustomerFlow(token);
    // the metric will be reset to an empty string in case of token change implying a new flow
    let mixpanelCustomerFlow;
    if (customerFlow === CustomerFlow.WIRE_EDIT_UPDATE) mixpanelCustomerFlow = MixpanelCustomerFlow.UPDATE;
    if (customerFlow === CustomerFlow.WIRE_EDIT_CREATION) mixpanelCustomerFlow = MixpanelCustomerFlow.CREATION;
    if (mixpanelCustomerFlow) this.register({ customerFlow: mixpanelCustomerFlow });
  }

  static open(payload: LightConfiguration, dynamicToken: string) {
    this.init(payload.mixPanelToken, dynamicToken, payload, true);
    this.track('Open');
    this.pageView(PageID.WELCOME);
  }

  static startRegister(salesAdvisorId: string, isWireEditActivated: boolean) {
    MixPanelService.track('StartRegister', { salesAdvisorId, isWireEditActivated });
  }

  static openLanguages() {
    MixPanelService.track('OpenLanguages');
  }

  static changeLanguage(language: string) {
    MixPanelService.register({ language });
    MixPanelService.track('ChangeLanguage');
  }

  static saveCountry() {
    MixPanelService.track('SaveCountry');
  }

  static saveState() {
    MixPanelService.track('SaveState');
  }

  static saveIdentity(fields: Object) {
    const fillFieldInfos = mpFormatter.getFillFieldInfos(fields);
    MixPanelService.track('SaveNames', fillFieldInfos);
  }

  static saveContacts(fields: Object, preferredMethodOfContact: PreferredMethodOfContact) {
    const fillFieldInfos = mpFormatter.getFillFieldInfos(fields);
    MixPanelService.track('SaveContacts', { ...fillFieldInfos, preferredMethodOfContact });
  }

  static saveInformation(fields: Object, salesAdvisor?: SalesAdvisor) {
    const fillFieldInfos = mpFormatter.getFillFieldInfos(fields);
    MixPanelService.track('SaveInformation', { fillFieldInfos, CASelected: !!salesAdvisor });
  }

  static existingClient() {
    MixPanelService.track('ExistingClient');
  }

  static saveAddress(fields: Object) {
    const fillFieldInfos = mpFormatter.getFillFieldInfos(fields);
    MixPanelService.track('SaveAddress', fillFieldInfos);
  }

  static saveSocialAccounts(fields: Object) {
    const fillFieldInfos = mpFormatter.getFillFieldInfos(fields);
    MixPanelService.track('SaveSocialContacts', fillFieldInfos);
  }

  // only for Luce
  static passedPhone() {
    MixPanelService.track('PassedPhone');
  }

  static acceptPrivacy(PrivacyforMarketingActivities: boolean) {
    MixPanelService.track('AcceptPrivacy', { PrivacyforMarketingActivities });
  }

  static registrationCompleted(fields: Object, isOktaCustomerCreated: boolean, customerFlow: MixpanelCustomerFlow, privacyforMarketingActivities: boolean, salesAdvisor?: SalesAdvisor) {
    const fillFieldInfos = mpFormatter.getFillFieldInfos(fields);
    MixPanelService.track('RegistrationCompleted', { ...fillFieldInfos, isOktaCustomerCreated, customerFlow, privacyforMarketingActivities, CASelected: !!salesAdvisor });
  }

  static otpVerified(customerFlow: MixpanelCustomerFlow) {
    MixPanelService.track('OtpVerified', { customerFlow });
  }

  static saveSA() {
    MixPanelService.track('SaveSA');
  }

  static audioPlayerPlay() {
    MixPanelService.track('AudioPlayerPlay');
  }

  static audioPlayerPause() {
    MixPanelService.track('AudioPlayerPause');
  }

  static audioPlayerBack() {
    MixPanelService.track('AudioPlayerBack');
  }

  static audioPlayerForward() {
    MixPanelService.track('AudioPlayerForward');
  }

  static backgroundDownload() {
    MixPanelService.track('BackgroundDownload');
  }

  // only for ipad
  static connectLine() {
    MixPanelService.track('ConnectLine');
  }

  // only for ipad
  static connectWeChat() {
    MixPanelService.track('ConnectWeChat');
  }

  static clickCta() {
    MixPanelService.track('ClickCta');
  }

  static errorTriggered(type: ErrorTypeMixPanel) {
    MixPanelService.track('Error', { type });
  }

  static wrongPhoneFormat() {
    MixPanelService.track('WrongPhoneFormat');
  }

  static wrongEmailFormat() {
    MixPanelService.track('WrongEmailFormat');
  }

  static SLAppDownload() {
    MixPanelService.track('SLAppDownload');
  }

  static clickKakaoCta() {
    MixPanelService.track('ClickKakaoCta');
  }

  static pageView(page?: PageID) {
    MixPanelService.track('viewScreen', { pageName: page ?? 'CLOSING' });
  }

  static validityEmailChecked(page: PageID, emailValidityStatus: EmailValidityStatusEnum) {
    if (emailValidityStatus) {
      MixPanelService.track('ValidityEmailCheckService', { pageName: page, validityApiResponse: emailValidityStatus });
    }
  }
}
