import axios, { AxiosResponse } from 'axios';
import { KakaoUserResponse } from '@/types/kakao';

const ws = axios.create({ withCredentials: false });

const kakaoApiService = {
  retrieveKakaoJsKey: async (token: string, brandCode: string): Promise<string> => ws.get(`/api/kakao/getKakaoJsKey/${token}?brandCode=${brandCode}`).then((response) => response.data),
  refreshKakaoToken: (kakaoJsKey: string) => { if (!window.Kakao.isInitialized()) window.Kakao.init(kakaoJsKey); },
  loginKakaoV2: (redirectUri: string) => window.Kakao.Auth.authorize({
    redirectUri
  }),
  retrieveKakaoUser: async (token: string, brandCode: string, code: string, redirectUri: string): Promise<AxiosResponse<KakaoUserResponse>> => ws.get(`/api/kakao/getKakaoUser/${token}?brandCode=${brandCode}&code=${code}&redirectUri=${redirectUri}`),
};

export default kakaoApiService;
