export enum PageID {
  WELCOME = 'WELCOME',
  VERIFICATION = 'VERIFICATION',
  COUNTRY = 'COUNTRY',
  STATE = 'STATE',
  IDENTITY = 'IDENTITY',
  INFORMATION = 'INFORMATION',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  CONTACT = 'CONTACT',
  ADDRESS = 'ADDRESS',
  UPDATE_ADDRESS = 'UPDATE_ADDRESS',
  SOCIAL = 'SOCIAL',
  LEGAL = 'LEGAL',
  UPDATE_MARKETING_CONSENT = 'UPDATE_MARKETING_CONSENT',
  ERROR = 'ERROR',
  UPDATE_MARKETING_CONSENT_THANK_YOU = 'UPDATE_MARKETING_CONSENT_THANK_YOU',
  REGISTERED = 'REGISTERED',
  UPDATE_NEW_POS_CUSTOMER = 'UPDATE_NEW_POS_CUSTOMER'
}
