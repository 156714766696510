import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';

export const GdprContainer = styled.div`
  margin: 20px 0 20px;
  background-color: ${(props) => props.theme.colors.LEGAL.labelColor.backgroundColor};
  ${theme('country', {
    VN: css`
      font-size: 12pt;
      `,
  })};

  ${theme('brand', {
    AMQ: css`
      font-size: 12pt;
    `,
    BAL: css`
      position: relative;
      left: -15px;
      width: calc(100% + 30px); // Add 2 * 15 (page container padding) to have full width container
      border-bottom: 1px solid ${(props) => props.theme.colors.LEGAL.labelColor.borderBottomColor};
      margin-top: 0;
      padding: 15px;
    `,
    BR: css`
      font-size: 12pt;
    `,
    BV: css`
      font-size: 12pt;
    `,
    YSL: css`
    `,
  })};

  ${theme('country', {
    KR: css`
      u, strong, a{
        font-size: 14pt;
      };
      `,
  })};
`;

export const GdprFooter = styled.div`
  margin: 0 0 120px; // margin bottom should be > than the height of ButtonBottomWrapper component + his bottom position to prevent overlaps from next component
  font-size: 10pt;
  color: ${(props) => props.theme.colors.LEGAL.labelColor.color};
  ${theme('country', {
    VN: css`
      font-size: 12pt;
      `,
  })};

  ${theme('brand', {
    YSL: css`
      margin: 0 0 60px; // margin bottom should be > than the height of ButtonBottomWrapper component + his bottom margin to prevent overlaps from next component
      font-weight: bold;
      text-transform: uppercase;
    `,
  })};

  ${theme('country', {
    KR: css`
      u, strong, a{
        font-size: 14pt;
      };
      `,
  })};
`;

export const SecondaryTextContainer = styled.div`
  margin-bottom: 20px;
    ${theme('brand', {
    YSL: css`
      margin-top: 30px;
      text-transform: uppercase;
    `,
  })};
  ${theme('country', {
    VN: css`
      font-size: 12pt;
      `,
  })};

  ${theme('country', {
    KR: css`
      u, strong, a{
        font-size: 14pt;
      };
      `,
  })};
`;

export const MainTextContainer = styled.div`
  ${theme('language', {
    ar: css`
      table {
        direction: ltr;
      }
      th, td {
        direction: rtl;
    }
    `,
  })};
  table, th, td {
    border-collapse: collapse;
    border: 1px solid ${(props) => props.theme.colors.LEGAL.labelColor.borderColor};
  }
  td {
    vertical-align: top;
    text-align: justify;
    padding: 0 5px;
  }
  ${theme('country', {
    VN: css`
      font-size: 12pt;
      `,
  })};
  ${theme('brand', {
    YSL: css`
      margin-top: 30px;
      text-transform: uppercase;
    `,
  })};

  ${theme('country', {
    KR: css`
      u, strong, a, span {
        font-size: 14pt;
      };
      span {
        text-decoration: underline;
      }
      `,
  })};
`;

export const CheckBoxInputContainer = styled.div`
  display: flex;
  ${theme('language', {
    ar: css`
     direction: rtl;
    `,
  })};
`;
