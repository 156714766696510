export enum ProspectSource {
    DEFAULT = '',
    // client device
    // For the link shared from LUCE in the event sections or the check-in app
    WEBDCC_EVENT = 'WEBDCC_EVENT',
    // For the link shared from LUCE
    WEBDCC_LINK = 'WEBDCC_LINK',
    // For the QR codes scanned from LUCE
    WEBDCC_QRCODE = 'WEBDCC_QRCODE',
    // For the QR codes scanned from LUCE Wire Store
    WEBDCC_IPAD = 'WEBDCC_IPAD',
    // For the QR code scanned from a PIN store
    WEBDCC_PIN = 'WEBDCC_PIN',
    // For the QR code scanned from a permanent store
    WEBDCC_STATIC = 'WEBDCC_STATIC',
    // For the links opened from the queue management app
    WEBDCC_QUEUE = 'WEBDCC_QUEUE',
    // For the links opened from the appointment app
    WEBDCC_APPT = 'WEBDCC_APPT',
    // For the links sent from SFSC
    WEBDCC_SFSC = 'WEBDCC_SFSC',
    // For the links sent from the PowerFront app.
    WEBDCC_PWF_APP = 'WEBDCC_PWF_APP',
    // For the links sent from the PowerFront desktop version.
    WEBDCC_PWF_CS = 'WEBDCC_PWF_CS',
    // For the QR code scanned from a franchisee store
    WEBDCC_FRANCHISEE = 'WEBDCC_FRANCHISEE',
    // For the new AMQ prospect source
    SELFRIDGE_QRC = 'SELFRIDGE_QRC',
    // For the DISCORD campaign
    WEBDCC_DIGITAL = 'WEBDCC_DIGITAL',
    // For wechat Id / open id registration
    WEBDCC_SOCIAL = 'WEBDCC_SOCIAL',
    // Just Pose registration flow
    WEBDCC_EXP = 'WEBDCC_EXP',
    // Certificate of craft wholesaler registration flow
    WIRE_BVCRAFT_WHLSLR = 'WIRE_BVCRAFT_WHLSLR',
    // Certificate of craft gift registration flow
    WIRE_BVCRAFT_GIFT = 'WIRE_BVCRAFT_GIFT',
    // Static url with reCAPTCHA, same flow / logic as PIN
    WEBDCC_WECOM = 'WEBDCC_WECOM',
    // Static url with reCAPTCHA and Kakao, same flow / logic as PIN
    EVENT_KR_PINAULT_COLLECT_24 = 'EVENT_KR_PINAULT_COLLECT_24',
    // For Xstore registration
    WEBDCC_POS = 'WEBDCC_POS',

    // SA device
    // For the clients created with LUCE Wire SA, manually in LUCE
    LUCE = 'LUCE',
    // For the clients created with LUCE Wire SA, from the event sections
    LUCE_EVENT = 'LUCE_EVENT',
    // For the clients created through the Queue Management Assisted Registration
    LUCE_QUEUE = 'LUCE_QUEUE',
    // For the clients created on a Paper Card
    WEBDCC_SCAN = 'WEBDCC_SCAN',

    // Store device
    // For the clients registered with LUCE Wire Store
    DCC = 'DCC',

    // Internal Psources (never sent to CDB or Siebel)
    // Internal ProspectSource for update address flow
    UPDATE_ADDRESS= 'UPDATE_ADDRESS',
    // Internal ProspectSource for update marketing consent flow
    UPDATE_MARKETING_CONSENT = 'UPDATE_MARKETING_CONSENT',
    // QP registration flow
    QP_WIRE = 'QP_WIRE',

    // Others
    // NQP registration flow
    NQP_WIRE = 'NQP_WIRE',

    UPDATE_NEW_POS_CUSTOMER = 'UPDATE_NEW_POS_CUSTOMER'
}

/**
 * Return true if prospect source is a partial update prospect source
 * @param prospectSource
 */
export function isPartialUpdateProspectSource(prospectSource: ProspectSource): boolean {
  return [ProspectSource.UPDATE_ADDRESS, ProspectSource.UPDATE_MARKETING_CONSENT, ProspectSource.UPDATE_NEW_POS_CUSTOMER].includes(prospectSource);
}
