import { GlobalState } from 'little-state-machine';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch, SetStateAction } from 'react';
import { Country, FeatureActivated, PageFlow } from '@/types';
import mp from '@/services/mixpanel/mixpanel.service';
import FlowService from '@/services/flow.service';
import { PageID } from '@/types/enum/pageID';
import api from '@/api';
import { TypedErrorResponseBody } from '@/types/brandedTokenError';
import { BrandCountryConfiguration } from '@/types/brandCountryConfiguration';

export default class CountryService {
  static onCountryButtonClick = async (
    country: Country,
    updateCustomerCountry: (payload: { country: string, hasStates: boolean }) => void,
    updateBrandCountryConfiguration: (payload: BrandCountryConfiguration) => void,
    updateErrorTypeOnError: (payload: TypedErrorResponseBody) => void,
    updateFeaturesActivationAndPageFlow: (payload: { featuresActivated: { [key in FeatureActivated]: boolean }, pageFlow: PageFlow }) => void,
    updateIsLoading: (payload: boolean) => void,
    state: GlobalState,
    localStep: PageID,
    token: string,
    navigate: NavigateFunction,
    setKakaoModalDisplayed: Dispatch<SetStateAction<boolean>>,
  ) => {
    const hasStates = country.states.length > 0;
    if (!hasStates) {
      try {
        updateIsLoading(true);
        const {
          data: {
            featuresActivated,
            pageFlow
          }
        } = await api.getFeatureActivationAndPageFlow(token, country.code);
        updateFeaturesActivationAndPageFlow({
          featuresActivated,
          pageFlow
        });
        const { data: payload } = await api.fetchBrandCountryConf(token, country.code);
        updateBrandCountryConfiguration(payload);
        updateIsLoading(false);
        updateCustomerCountry({
          country: country.code,
          hasStates
        });
        mp.saveCountry();
        if (featuresActivated.IS_KAKAO_FLOW_ACTIVATED) {
          // fix to wait for script init in window
          updateIsLoading(true);
          setTimeout(() => {
            setKakaoModalDisplayed(true);
            updateIsLoading(false);
          }, 1000);
        } else {
          const nextPage = FlowService.nextPage(state, localStep, undefined, pageFlow.pages);
          navigate(`/${token}/${nextPage}`);
        }
      } catch (err) {
        if (err?.response?.data) {
          updateErrorTypeOnError(err.response.data);
        }
        updateIsLoading(false);
        navigate('/error');
      }
    } else {
      updateCustomerCountry({
        country: country.code,
        hasStates
      });
      mp.saveCountry();
      const nextPage = FlowService.nextPage(state, localStep);
      navigate(`/${token}/${nextPage}`);
    }
  };
}
