import React from 'react';
import styled from 'styled-components';
import { StyledLink } from '@pages/Registered/registered.styles';
import { useTranslation } from '@/services/hooks';
import { CarouselImg } from '@/types/form/carouselImg';
import mp from '@/services/mixpanel/mixpanel.service';

type CarouselProps = {
  images: CarouselImg[],
  token: string,
}

const Carousel = ({ images, token } : CarouselProps) => {
  const t = useTranslation();

  const handleDownloadClicked = async () => {
    mp.backgroundDownload();
  };

  return (
    <CarouselContainer>
      {
        images.map((image, index) => (
          <WallpaperCard key={image.imageUrl}>
            <Image src={image.imageUrl} alt={image.imageName} />
            <SaveWallpaperButtonCard>
              <StyledLink
                id={`linkDownload${index}`}
                href={`/api/DownloadFile/${token}?url=${image.downloadUrl}&name=${image.imageName}`}
                onClick={handleDownloadClicked}
              >
                {t('web.save')}
              </StyledLink>
            </SaveWallpaperButtonCard>
          </WallpaperCard>
        ))
      }
    </CarouselContainer>
  );
};
export default Carousel;

export const CarouselContainer = styled.div`
    max-width: 100%;
    margin: 20px auto;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
`;

export const WallpaperCard = styled.div`
    margin-right: 10px;
    width: 200px;
    flex-shrink: 0;
`;

export const SaveWallpaperButtonCard = styled.button`
    display: block;
    width: 100%;
    min-height: 40px;
    margin: 0 auto;
    cursor: pointer;
    color: ${(props) => props.theme.colors.REGISTERED.carousel.saveWallpaperButtonCard.color};
    background-color: ${(props) => props.theme.colors.REGISTERED.carousel.saveWallpaperButtonCard.backgroundColor};
    border: 1px solid ${(props) => props.theme.colors.REGISTERED.carousel.saveWallpaperButtonCard.borderColor};
    text-transform: uppercase;
    font-size: 11pt;
    a {
      text-decoration: none;
      color: ${(props) => props.theme.colors.REGISTERED.carousel.saveWallpaperButtonCard.color};
    }
`;

export const Image = styled.img`
    width: 100%;
    margin: 0;
    border: 2px solid ${(props) => props.theme.colors.REGISTERED.carousel.image.borderColor};
`;
