import Utils from '@utils/utils';
import React from 'react';
import { useStateMachine } from 'little-state-machine';
import { ProspectSource } from '@/types';
import { useTranslation } from '@/services/hooks';
import { EventKey } from '@/types/enum/eventKey';
import {
  EventInvitationText,
  InvitationText,
  WelcomeTextComponent
} from '@/common/components/ReCaptchaDisclaimerText.style';

export const Invitation: React.FC = () => {
  // Prepare translations
  const t = useTranslation();

  // Retrieve current state from store
  const { state } = useStateMachine();
  const { salesAdvisor, prospectSource, event, featuresActivated } = state;
  const { translationKeySuffixComponent } = event;

  if (event.isCustomJourney) {
    // use right translationKey
    const translationKeyInvitation = Utils.overloadTranslationKey(translationKeySuffixComponent, EventKey.INVITATION_KEY_SUFFIX);
    const translationKeyWelcomeText = Utils.overloadTranslationKey(translationKeySuffixComponent, EventKey.WELCOME_KEY);
    const shouldDisplayWelcomeTextComponent = Utils.shouldDisplayText(translationKeySuffixComponent, EventKey.WELCOME_KEY, false, false);

    return (
      <EventInvitationText>
        {shouldDisplayWelcomeTextComponent && (
          <WelcomeTextComponent id="welcomeTextComponent">
            {t(`web.pin.welcomeTextComponent.${translationKeyWelcomeText}`)}
          </WelcomeTextComponent>
        )}
        {t(`web.pin.invitation.${translationKeyInvitation}`)}
      </EventInvitationText>
    );
  }

  if (featuresActivated.IS_PREFERRED_SA_ACTIVATED && salesAdvisor) {
    return (
      <InvitationText>
        {`${salesAdvisor.firstName} ${salesAdvisor.lastName} ${t('dcc.invitationSARegister')}`}
      </InvitationText>
    );
  }

  if (prospectSource === ProspectSource.DCC) {
    return (
      <InvitationText>
        {t('dcc.invitation')}
      </InvitationText>
    );
  }

  if (salesAdvisor?.firstName && salesAdvisor?.lastName) {
    return (
      <InvitationText>
        {t('web.invitation')}
      </InvitationText>
    );
  }

  return (
    <InvitationText>
      {t('dcc.invitation')}
    </InvitationText>
  );
};
