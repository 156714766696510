import React from 'react';
import { useStateMachine } from 'little-state-machine';
import Utils from '@utils/utils';
import LanguagesDropdown from '@common/components/dropdown/LanguagesDropdown';
import { Language, ProspectSource } from '@/types';
import { ImgFormLogo, LogoFormWrapper } from '@/common/components/Logo';
import { ProgressBar } from '@/common/components';

type Props = {
  currentStep: number,
  numberOfSteps: number,
  languages: Array<Language>,
  type: string,
  defaultLogoUrl: string
}

const FormHeader: React.FC<Props> = ({
  currentStep,
  numberOfSteps,
  languages,
  type,
  defaultLogoUrl,
}) => {
  const { state } = useStateMachine();
  const { prospectSource } = state;
  return (
    <>
      {prospectSource === ProspectSource.UPDATE_ADDRESS && <LanguagesDropdown languages={languages} type={type} />}
      <LogoFormWrapper><ImgFormLogo id="brandLogo" src={defaultLogoUrl} alt="Logo" /></LogoFormWrapper>
      {!Utils.shouldDisplayProgressBar(prospectSource) && <ProgressBar numberOfSteps={numberOfSteps} currentStep={currentStep} />}
    </>
  );
};

export default FormHeader;
