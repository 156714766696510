import axios from 'axios';
import {
  LineUserResponse
} from '@/types/line';

const ws = axios.create({ withCredentials: false });

export const lineApiService = {
  retrieveLineUser: async (token: string, code: string, redirectUri: string, brandCode: string, countryCode: string): Promise<LineUserResponse> => ws.get(
    `/api/line/getLineUser/${token}?code=${code}&redirectUri=${redirectUri}&brandCode=${brandCode}&countryCode=${countryCode}`,
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
  ).then((response) => response.data)
};
