import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';

const CountryStateButton = styled.button`
  color: ${(props) => props.theme.colors.COMMON.countryStateButton.color};
  cursor: pointer;
  text-align: left;
  background: none;
  border: none;
  margin: 12px 0 12px;
  padding: 0;
  min-height: 25px;
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    :hover {
      font-weight: bold;
    };
  }
  ${theme('language', {
    ar: css`
      text-align: right;
    `,
  })};
  ${theme('brand', {
    YSL: css`
      text-transform: uppercase;
    `,
    GUCCI: css`
      font-size: 14pt;
    `,
    AMQ: css`
      font-size: 14pt;
    `,
    BV: css`
      font-size: 14pt;
    `,
    BR: css`
      font-size: 14pt;
    `,
  })};
`;

export default CountryStateButton;
