import React, { Dispatch, SetStateAction, useEffect } from 'react';
import Utils from '@utils/utils';
import { UseFormMethods } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { CivilTitle } from '@common/components/form/information/CivilTitle';
import { Names } from '@common/components/form/information/Names';
import { BirthDate } from '@common/components/form/information/BirthDate';
import { ConnectForm } from '@/common/components/form';
import ErrorMessage from '@/common/components/form/ErrorMessage';
import { Nationality } from '@/common/components/form/information/Nationality';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import useTranslatedCountries from '@/services/hooks/useTranslatedCountries';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  formMethods: UseFormMethods<InformationFormProps>,
  setShowDateError: Dispatch<SetStateAction<boolean>>,
  showDateError: boolean,
}

export const IdentityForm: React.FC<Props> = ({ formMethods, setShowDateError, showDateError }) => {
  const { state } = useStateMachine();
  const translatedCountries = useTranslatedCountries();
  const { customer, brandCountryConfiguration, prospectSource, featuresActivated, alreadyRegistered } = state;
  const customerCountry = customer.customerContact.address.country;
  const customerTitle = customer.customerInfo.title;
  useEffect(() => {
    (async function fetch() {
      // It prevents the submit button to be disabled if user goes back from the next page or if he refreshes the page.
      await formMethods.trigger();
    }());
  }, []);
  return (
    <ConnectForm>
      {({ t }: UseFormMethods<InformationFormProps> & TranslationFunction) => (
        <>
          {Utils.shouldShowTitleField(prospectSource, brandCountryConfiguration, customerTitle, featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED, alreadyRegistered) && (<CivilTitle />)}
          <Names />
          <BirthDate formMethods={formMethods} setShowDateError={setShowDateError} showDateError={showDateError} />
          {showDateError && formMethods.errors.day?.type === 'dateFormatError' && <ErrorMessage>{t('dcc.dateOfBirthError')}</ErrorMessage>}
          {showDateError && formMethods.errors.day?.type === 'minimalAgeError' && <ErrorMessage>{t('web.minimalAge')}</ErrorMessage>}
          {translatedCountries && <Nationality translatedCountries={translatedCountries} displayNationality={brandCountryConfiguration.displayNationality} mandatoryNationality={brandCountryConfiguration.mandatoryNationality} defaultNationality={customerCountry} />}
        </>
      )}
    </ConnectForm>
  );
};
