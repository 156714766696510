import { Logo } from '@/types/brand';
import { PageID } from '@/types/enum/pageID';
import { Colors } from '@/types/colors';
import { LightConfiguration } from '@/types/lightConfiguration';

export enum ErrorType {
  DEFAULT = 'DEFAULT',
  EXPIRED = 'EXPIRED',
  ALREADY_USED = 'ALREADY_USED',
  LOCKED = 'LOCKED',
  MAINTENANCE_PERIOD = 'MAINTENANCE_PERIOD',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  TOO_MANY_OTP_ATTEMPTS = 'TOO_MANY_OTP_ATTEMPTS',
  TOO_MANY_OTP_CHALLENGES = 'TOO_MANY_OTP_CHALLENGES',
  TOO_MANY_2ND_POINT_OF_CONTACT_CHECKS = 'TOO_MANY_2ND_POINT_OF_CONTACT_CHECKS'
}

export enum ErrorTypeMixPanel {
  DEFAULT = 'other',
  EXPIRED = 'timeExpired',
  ALREADY_USED = 'alreadyUsed',
  LOCKED = 'tooManyTentatives',
  MAINTENANCE_PERIOD = 'maintenancePeriod',
  NOT_AUTHORIZED = 'notAuthorized',
  OKTA_CREATION_FAILED = 'oktaCreationFailed',
  TOO_MANY_OTP_ATTEMPTS = 'tooManyOtpAttempts'
}

export type BrandedTokenErrorResponseBody = {
  defaultLogo: Logo,
  title: string,
  status: number,
  detail: string,
  path: string,
  message: string,
  codeTriggeredByAnError: string,
  errorType: ErrorType,
  mixPanelToken?: string,
  confLight?: LightConfiguration,
  style: {
    colors: Colors
  }
}

export type ErrorHttpResponse = {
  response: {
    data: BrandedTokenErrorResponseBody
  }
}

export type TypedErrorResponseBody = {
  errorType: ErrorType
}

export type LastPageVisitedResponseBody = {
  lastPageVisited: PageID
}

export type AlreadySentErrorResponseBody = {
  frontErrorPageSent: PageID,
}
