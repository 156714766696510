import { GlobalState } from 'little-state-machine';
import {
  Background,
  Brand,
  Country,
  Customer,
  FeatureActivated,
  Flow,
  FlowName,
  Language,
  PreferredMethodOfContact,
  Privacy,
  ProspectSource,
  QueueManagement,
  SalesAdvisor
} from '@/types';
import { Store } from '@/types/store';
import { BrandCountryConfiguration } from '@/types/brandCountryConfiguration';
import { ErrorType } from '@/types/brandedTokenError';
import { EventType } from '@/types/Event';
import { Translations } from '@/types/translations';
import { PageID } from '@/types/enum/pageID';
import { BackgroundEnum } from '@/types/enum/background';
import { PreciselyAuth } from '@/types/precisely';

declare module 'little-state-machine' {
  interface GlobalState {
    actualToken: string,
    flow: Flow,
    alreadyRegistered: boolean,
    precisely: PreciselyAuth,
    isLoading: boolean,
    dateSendingOtp: string,
    isKakaoUserRetrieved: boolean,
    prospectSource: ProspectSource,
    isPoPAlreadySent: boolean,
    brand: Brand,
    brandCountryConfiguration: BrandCountryConfiguration,
    store: Store,
    countries: Array<Country>,
    dropdownSearchValues: {
      prefixNumber: string
    },
    customer: Customer,
    privacy: Privacy,
    translations: Translations,
    languages: Array<Language>,
    privacyLanguages: Array<Language>,
    salesAdvisor: SalesAdvisor,
    error: {
      errorType: ErrorType,
      isBackError: boolean,
      lastPageVisited: PageID,
      isFrontErrorAlreadySent: boolean,
      frontErrorPageSent: PageID
    },
    backgrounds: {[key in BackgroundEnum]?: Background},
    queueManagement?: QueueManagement,
    event: EventType,
    featuresActivated : {[key in FeatureActivated]: boolean},
    mixPanelToken: string
  }
}

export const emptyCustomer = {
  customerContact: {
    address: {
      pobox: '',
      street: '',
      zipcode: '',
      country: '',
      city: '',
    },
    phone: {
      number: '',
      prefix: '',
      prefixCountry: '',
    },
    email: '',
    emailValidity: undefined,
    isSecondPointOfContactDisabled: false,
    privacies: [],
    preferred: PreferredMethodOfContact.PHONE_AND_EMAIL,
    socialAccounts: {
      instagram: '',
      tiktok: '',
      weChat: {
        userId: '',
        weChatLastSubscriptionDate: ''
      },
      weChatPlatform: '',
      okta: '',
      kakao: '',
      line: ''
    },
  },
  customerInfo: {
    month: '',
    lastname: '',
    middleName: '',
    year: '',
    title: '',
    nationality: '',
    firstname: '',
    day: '',
    furigana_firstname: '',
    furigana_lastname: '',
    customerType: '',
    companyName: '',
  },
  brand: '',
  storeid: '',
  brandEdw: '',
  inputDelay: 0,
  registrationDateInStore: '',
  language: '',
  registrationSACode: '',
  saComment: {
    content: '',
    createdOn: '',
    createdBy: ''
  }
};

export const authorizedPagesInitValue = [PageID.WELCOME];

const state: GlobalState = {
  actualToken: '',
  flow: {
    flow: FlowName.CREATE,
    pages: [
      { name: PageID.WELCOME, stepValue: 0 },
      { name: PageID.COUNTRY, stepValue: 0 },
      { name: PageID.IDENTITY, stepValue: 1 },
      { name: PageID.CONTACT, stepValue: 1 },
      { name: PageID.LEGAL, stepValue: 1 },
      { name: PageID.REGISTERED, stepValue: 0 },
    ],
    authorizedPages: [...authorizedPagesInitValue],
  },
  alreadyRegistered: false,
  precisely: {
    authToken: '',
    instanceKey: ''
  },
  isLoading: false,
  dateSendingOtp: '',
  isKakaoUserRetrieved: false,
  prospectSource: ProspectSource.DEFAULT,
  isPoPAlreadySent: false,
  brand: {
    id: '',
    active: false,
    code: '',
    codeTriggeredByAnError: '',
    codeSiebel: '',
    defaultLogo: {
      logoUrl: ''
    },
    edw: '',
    logoPath: '',
    luceAndSalesTicketSaMethod: false,
    name: '',
    backgroundUrl: '',
    backgroundPosition: '',
    style: {
      colors: {
        CONTACT: {
          labelColor: {
            borderColor: '',
            backgroundColor: '',
            checked: {
              backgroundColor: ''
            }
          }
        },
        COUNTRY: {
          labelColor: {
            color: ''
          },
          indexLetter: {
            color: ''
          }
        },
        ERROR: {
          labelColor: {
            color: '',
            borderTopColor: '',
            borderBottomColor: '',
            event: {
              color: '',
              borderTopColor: '',
              desktop: {
                color: '',
                borderTopColor: ''
              }
            },
            backgroundColor: ''
          }
        },
        IDENTITY: {
          labelColor: {
            color: ''
          }
        },
        LEGAL: {
          labelColor: {
            backgroundColor: '',
            borderBottomColor: '',
            color: '',
            borderColor: '',
          }
        },
        REGISTERED: {
          labelColor: {
            color: '',
            desktop: {
              color: ''
            },
            luce: {
              color: ''
            }
          },
          textWrapper: {
            borderTopColor: '',
            borderBottomColor: ''
          },
          saveButton: {
            color: '',
            backgroundColor: '',
            desktop: {
              color: '',
              backgroundColor: ''
            }
          },
          qrCodeContainer: {
            backgroundColor: ''
          },
          carousel: {
            saveWallpaperButtonCard: {
              color: '',
              backgroundColor: '',
              borderColor: ''
            },
            image: {
              borderColor: ''
            }
          },
          player: {
            controls: {
              color: '',
              desktop: {
                color: ''
              }
            },
            time: {
              color: '',
              desktop: {
                color: ''
              }
            },
            progressBar: {
              backgroundColor: '',
              filled: {
                backgroundColor: ''
              }
            },
            progressIndicator: {
              backgroundColor: '',
              shadow: {
                color: ''
              }
            }
          }
        },
        VERIFICATION: {
          labelColor: {
            color: ''
          }
        },
        WELCOME: {
          labelColor: {
            color: '',
            desktop: {
              color: ''
            },
            event: {
              color: '',
              desktop: {
                color: ''
              }
            }
          },
          theme: {
            color: ''
          },
          welcomeButton: {
            color: '',
            backgroundColor: '',
            borderColor: '',
            desktop: {
              color: '',
              backgroundColor: '',
              borderColor: ''
            }
          }
        },
        COMMON: {
          isWhiteLogo: false,
          qrCodeLoader: {
            color: ''
          },
          fullPageLoader: {
            color: ''
          },
          title: {
            color: ''
          },
          searchBarPlaceholder: {
            color: ''
          },
          progressBar: {
            inactive: {
              backgroundColor: ''
            },
            active: {
              backgroundColor: ''
            }
          },
          languageDropDown: {
            color: '',
            container: {
              color: '',
              borderTopColor: '',
              borderBottomColor: ''
            },
            desktop: {
              color: ''
            },
            labels: {
              color: '',
              desktop: {
                color: ''
              }
            },
            option: {
              color: ''
            }
          },
          countryStateButton: {
            color: ''
          },
          modal: {
            backgroundColor: '',
            countryState: {
              backgroundColor: ''
            },
            confirm: {
              backgroundColor: '',
              wrapper: {
                backgroundColor: '',
                borderColor: '',
                footer: {
                  borderTopColor: '',
                  button: {
                    color: '',
                    backgroundColor: ''
                  }
                }
              }
            }
          },
          form: {
            select: {
              color: '',
              backgroundColor: '',
              borderColor: '',
              disabled: {
                backgroundColor: ''
              },
              searchCustom: {
                disabled: {
                  backgroundColor: ''
                },
                button: {
                  color: ''
                }
              },
              optionContainer: {
                backgroundColor: '',
                borderColor: ''
              }
            },
            theme: {
              color: ''
            },
            otp: {
              resend: {
                color: ''
              },
              statusInformation: {
                color: ''
              }
            }
          },
          brandContainer: {
            color: '',
            backgroundOverride: {
              backgroundColor: '',
              desktop: {
                backgroundColor: ''
              }
            },
            error: {
              backgroundColor: ''
            },
            temporary: {
              backgroundColor: ''
            },
            theme: {
              color: ''
            }
          },
          pageContainer: {
            backgroundColor: ''
          },
          button: {
            color: '',
            backgroundColor: '',
            borderColor: '',
            borderTopColor: '',
            borderBottomColor: '',
            disabled: {
              color: '',
              backgroundColor: '',
              borderColor: ''
            },
            form: {
              color: '',
              borderTopColor: '',
              borderBottomColor: '',
              disabled: {
                color: '',
                backgroundColor: ''
              }
            }
          },
          frame: {
            backgroundColor: '',
            borderColor: ''
          },
          checkbox: {
            checked: {
              color: '',
              backgroundColor: ''
            }
          },
          errorMessage: {
            color: ''
          },
          warningMessage: {
            color: ''
          },
          field: {
            backgroundColor: '',
            borderColor: '',
            error: {
              borderColor: ''
            },
            disabled: {
              color: ''
            },
            warning: {
              borderColor: ''
            },
          },
          optionalLegend: {
            color: '',
            backgroundColor: ''
          },
          label: {
            color: ''
          },
          caret: {
            color: '',
            desktop: {
              color: ''
            },
            form: {
              color: ''
            },
            labels: {
              color: '',
              desktop: {
                color: ''
              }
            }
          }
        }
      },
    },
    // todo: delete after end of Japan Wire customization ALW-2129
    jpCustomCarousel: {
      imageName: '',
      imageUrl: '',
      downloadUrl: '',
      translationKey: ''
    },
  },
  brandCountryConfiguration: {
    courtesies: [],
    displayNationality: false,
    mandatoryNationality: false,
    minimalAge: 0,
    mandatoryYearOfBirth: true,
    displayAddress: true,
    displaySocialAccounts: true,
  },
  store: {
    storeCode: '',
    countryCode: '',
    address: '',
    name: '',
    phone: '',
  },
  countries: [],
  dropdownSearchValues: {
    prefixNumber: '',
  },
  customer: emptyCustomer,
  privacy: {
    titleTextKey: '',
    mainTextKey: '',
    secondaryTextKey: '',
    checkboxes: [],
    buttonTextKey: '',
    footerTextKey: '',
  },
  translations: {
    lightLanguageCode: '',
    privacyLanguageCode: '',
    lightLabels: {},
    privacyLabels: {},
  },
  languages: [],
  privacyLanguages: [],
  salesAdvisor: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    vendorId: '',
  },
  error: {
    errorType: ErrorType.DEFAULT,
    isBackError: false,
    lastPageVisited: PageID.WELCOME,
    isFrontErrorAlreadySent: false,
    frontErrorPageSent: PageID.WELCOME,
  },
  backgrounds: {
    currentBackground: {
      backgroundUrl: '',
      backgroundPosition: '',
    },
    registeredBackground: {
      backgroundUrl: '',
      backgroundPosition: '',
    },
    welcomeBackground: {
      backgroundUrl: '',
      backgroundPosition: '',
    },
    currentDesktopBackground: {
      backgroundUrl: '',
      backgroundPosition: '',
    },
    registeredDesktopBackground: {
      backgroundUrl: '',
      backgroundPosition: '',
    },
    welcomeDesktopBackground: {
      backgroundUrl: '',
      backgroundPosition: '',
    },
  },
  event: {
    isCustomJourney: false,
    musics: [],
    translationKeySuffixComponent: {
      defaultTranslationKeysSuffix: '',
      overload: false,
      translationKeysSuffixOverloaded: {
        welcomeKey: {
          suffix: '',
          shouldDisplay: false,
        },
        invitationKeySuffix: '',
        thanksTextKeySuffix: '',
        registeredKey: {
          suffix: '',
          shouldDisplay: true,
        },
      },
    },
    wallpaperComponent: {
      imageName: '',
      imageUrl: '',
    },
    eventRedirectLink: '',
    ctaComponent: {
      translationKey: '',
      ctaUrl: '',
      placeHolderComponent: {
        placeHolder: '',
        path: '',
        mapper: [{ key: '', value: '' }],
      },
    },
    textComponent: [],
    carouselComponent: [],
    videoComponent: [],
    gifComponent: {
      gifUrl: '',
      gifPosition: '',
      displayTime: 0,
    },
  },
  featuresActivated: {
    IS_CONTACT_FORM_EXTENDED: false,
    IS_ADDRESS_PAGE_DISPLAYED: false,
    IS_ADDRESS_SEARCH_ACTIVATED: false,
    IS_WIRE_EDIT_FLOW_ACTIVATED: false,
    IS_KAKAO_FLOW_ACTIVATED: false,
    IS_CERTIFICATE_OF_CRAFT_FLOW_ACTIVATED: false,
    IS_WECHAT_QRCODE_DISPLAYED: false,
    IS_WECHAT_MODAL_DISPLAYED: false,
    IS_LINE_QRCODE_DISPLAYED: false,
    ARE_JPTHTW_LINE_QRCODES_DISPLAYED: false,
    IS_SA_QRCODE_DISPLAYED: false,
    IS_OUT_OF_STORE_PROSPECT_SOURCE_ACTIVATED: false,
    IS_WELCOME_PAGE_SKIPPED: false,
    IS_PHONE_CONTACT_METHOD_DISABLED: false,
    IS_SL_APP_CTA_DISPLAYED: false,
    IS_CERTIFICATE_OF_CRAFT_WHOLESALER_FLOW_ACTIVATED: false,
    IS_CERTIFICATE_OF_CRAFT_GIFT_FLOW_ACTIVATED: false,
    IS_WIRE_EDIT_PHASE_2_ACTIVATED: false,
    // todo: delete after end of Japan Wire customization ALW-2129
    IS_JP_CUSTOM_CAROUSEL_ACTIVATED: false,
    IS_REDIRECT_URL_ACTIVATED: false,
    IS_EMAIL_VALIDITY_CHECKED: false,
    IS_PREFERRED_SA_ACTIVATED: false,
    IS_GUCCI_CREATE_USED: false,
    IS_GUCCI_SERVICES_USED: false,
    ARE_ADDRESS_FIELDS_DISPLAYED_ON_INFORMATION_PAGE: false
  },
  mixPanelToken: ''
};

export default state;
