import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import screenSizes from '@utils/screenSizes';
import Utils from '@utils/utils';

type SetIsLoadingFunction = Dispatch<SetStateAction<boolean>> | ((payload: boolean) => void);

export const useBackgroundLoading = (setIsLoading: SetIsLoadingFunction, mobileBackground?: string, desktopBackground?: string) => {
  const [backgroundsLoaded, setBackgroundsLoaded] = useState({ mobile: false, desktop: false });
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= screenSizes.desktop.widthNumber);

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth >= screenSizes.desktop.widthNumber);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const loadBackgrounds = useCallback(async (mobileBackgroundUrl?: string, desktopBackgroundUrl?: string) => {
    if (!isDesktop && mobileBackgroundUrl && !backgroundsLoaded.mobile) {
      setIsLoading(true);
      setBackgroundsLoaded((prevState) => ({
        ...prevState,
        mobile: true
      }));
      await Utils.loadImage(mobileBackgroundUrl);
      setIsLoading(false);
    }
    if (isDesktop && desktopBackgroundUrl && !backgroundsLoaded.desktop) {
      setIsLoading(true);
      setBackgroundsLoaded((prevState) => ({
        ...prevState,
        desktop: true
      }));
      await Utils.loadImage(desktopBackgroundUrl);
      setIsLoading(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    (async function load() {
      await loadBackgrounds(mobileBackground, desktopBackground);
    }());
  }, [loadBackgrounds]);

  return loadBackgrounds;
};
