import React, { RefObject } from 'react';
import theme from 'styled-theming';
import styled, { css, ThemeProvider } from 'styled-components/macro';

interface Props {
  text: string;
  parentRef: ((instance: HTMLInputElement) => void) | RefObject<HTMLInputElement>;
  isTextHTML?: boolean;
  isLockedAndChecked?: boolean;
}

const CheckboxField: React.FC<Props & React.HTMLProps<HTMLInputElement>> = ({
  isTextHTML,
  name,
  parentRef,
  required,
  text,
  defaultChecked,
  isLockedAndChecked
}) => {
  if (required) {
    text += ' *';
  }
  return (
    <ThemeProvider theme={{ isLockedAndChecked }}>
      <StyledLabel htmlFor={name}>
        <StyledCheckBox
          id={name}
          type="checkbox"
          name={name}
          required={required}
          ref={parentRef}
          defaultChecked={defaultChecked || isLockedAndChecked}
          disabled={isLockedAndChecked}
        />
        <Frame />
        {isTextHTML ? <Text dangerouslySetInnerHTML={{ __html: text }} /> : <span>{text}</span>}
      </StyledLabel>
    </ThemeProvider>
  );
};

export default CheckboxField;

const Frame = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  float: left;
  
  text-align: center;
  font-weight: bold;
  
  box-sizing: border-box;
  cursor: ${(props) => props.theme.isLockedAndChecked ? 'default' : 'pointer'};  
  
  border: 1px solid ${(props) => props.theme.colors.COMMON.frame.borderColor};
  background-color: ${(props) => props.theme.colors.COMMON.frame.backgroundColor};  
`;

const StyledCheckBox = styled.input`  
  width: 1px;
  height: 1px;
  display: none;
  color: ${(props) => props.theme.isLockedAndChecked ? props.theme.colors.LEGAL.checkboxLabelDisabled.color : ''};
  
  &:checked + ${Frame} {
    color: ${(props) => props.theme.isLockedAndChecked ? props.theme.colors.LEGAL.checkboxLabelDisabled.color : props.theme.colors.COMMON.checkbox.checked.color};
    border-color: ${(props) => props.theme.isLockedAndChecked ? props.theme.colors.LEGAL.checkboxLabelDisabled.borderColor : ''};
    
    &:before {
      content: '✓';
    }
  }
  ${theme('brand', {
    YSL: css`
      &:checked + ${Frame} {
      background-color: ${(props) => props.theme.isLockedAndChecked ? props.theme.colors.LEGAL.checkboxLabelDisabled.backgroundColor : props.theme.colors.COMMON.checkbox.checked.backgroundColor};
  }
    `,
  })};
`;

const Text = styled.div`
  margin-left: 35px;
  cursor: ${(props) => props.theme.isLockedAndChecked ? 'default' : 'pointer'};  
  
  ${theme('language', {
    ar: css`
     margin-left: 0;
     margin-right: 35px;
    `,
  })};
`;

const StyledLabel = styled.label`
  display: block;
  padding: 10px 0;

  // Override HTML tags from GDPR translation keys
  p {
    margin: 0;
  }
  a:link {
    color: ${(props) => props.theme.isLockedAndChecked ? props.theme.colors.LEGAL.checkboxLabelDisabled.color : ''};
  }
  a:visited {
    color: ${(props) => props.theme.isLockedAndChecked ? props.theme.colors.LEGAL.checkboxLabelDisabled.color : ''};
  }
  color: ${(props) => props.theme.isLockedAndChecked ? props.theme.colors.LEGAL.checkboxLabelDisabled.color : ''};
  
  ${theme('brand', {
    YSL: css`
     font-size: 10pt;
     text-transform: uppercase;
    `,
    BAL: css`
     font-size: 11pt;
    `,
  })};
`;
