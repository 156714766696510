import React from 'react';
import { GlobalState } from 'little-state-machine';
import screenSizes from '@utils/screenSizes';
import { Brand, Checkbox, Country, Customer, FeatureActivated, PreferredMethodOfContact, ProspectSource } from '@/types';
import { keyboardDigitInputs, keyboardInputs } from '@/types/enum/keyboardInputs';
import { IPlaceHolderComponent } from '@/types/placeHolder';
import { Key, TranslationKeySuffixComponent } from '@/types/translationKeySuffixComponent';
import { EventKey } from '@/types/enum/eventKey';
import { BrandCountryConfiguration } from '@/types/brandCountryConfiguration';
import { BuildInterpolation } from '@/types/buildInterpolation';
import { Privacy } from '@/types/privacy';
import { IResponseLineQrCodes } from '@/types/api';
import { BrandCode } from '@/types/enum/brand-code.enum';

export default class Utils {
  static toTitleCase = (string: string) => (
    string.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  );

  // remove accents from a string
  static normalize(label: string) {
    return label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  // see https://confluence.keringapps.com/pages/viewpage.action?spaceKey=APPDCC&title=APAC+Country+Layout+Adjustment for APAC rules
  static isFuriganaDisplayed(customerCountry: string): boolean {
    return ['JP'].includes(customerCountry);
  }

  static isFirstNameAndLastNameReversed(customerLanguage: string, customerCountry: string): boolean {
    return ['jp', 'zh', 'zhs', 'zht', 'ko'].includes(customerLanguage) || ['CN', 'HK', 'MO', 'KR', 'TW', 'JP'].includes(customerCountry);
  }

  static isMonthAndDayReversed(customerCountry: string): boolean {
    return ['US'].includes(customerCountry);
  }

  static isAddressReversed(customerLanguage: string, customerCountry: string): boolean {
    return ['jp', 'zh', 'zhs', 'zht'].includes(customerLanguage) || ['CN', 'HK', 'MO', 'KR', 'TW'].includes(customerCountry);
  }

  static isChangeLanguageReversed(brandCode: string): boolean {
    return ['BAL'].includes(brandCode);
  }

  static isWebSuccessKeyDisplayed(brandCode: string): boolean {
    return !(['GUCCI'].includes(brandCode));
  }

  static isEmailAndPhoneReversed(preferredMethodOfContact: PreferredMethodOfContact) {
    return preferredMethodOfContact === PreferredMethodOfContact.PHONE;
  }

  static buildInterpolation(state: GlobalState, staticCustomer?: Customer): BuildInterpolation {
    const { customer, salesAdvisor, brand, brandCountryConfiguration, translations: { lightLabels } } = state;
    const customerFirstName = customer.customerInfo.firstname || staticCustomer?.customerInfo?.firstname || '';
    const customerLastName = customer.customerInfo.lastname || staticCustomer?.customerInfo?.lastname || '';
    const certificateOfCraftLegalLinkedText = Utils.buildCertificateOfCraftLegalLinkTextInterpolation(lightLabels['web.certificateOfCraftLegalLinkText']);
    const placeholders = {
      CUSTOMER_FIRSTNAME: customerFirstName,
      CUSTOMER_LASTNAME: customerLastName,
      CUSTOMER_FULL_NAME: `${customerFirstName} ${customerLastName}`,
      SA_FULL_NAME: `${salesAdvisor?.firstName} ${salesAdvisor?.lastName}`,
      SA_FIRSTNAME: `${salesAdvisor?.firstName}`,
      SA_LASTNAME: `${salesAdvisor?.lastName}`,
      PHONE_NUMBER: `${customer.customerContact.phone.prefix}${customer.customerContact.phone.number}`,
      CUSTOMER_EMAIL: customer.customerContact.email,
      BRAND_TM: brand.name,
      MINIMAL_AGE: `${brandCountryConfiguration.minimalAge}`,
      TERMS_AND_CONDITIONS: `${certificateOfCraftLegalLinkedText}`
    };
    return {
      placeholders,
      interpolation: { prefix: '[', suffix: ']' },
    };
  }

  static placeHolderComponentInterpolation(text: string, state: GlobalState, placeHolderComponent?: IPlaceHolderComponent) {
    if (placeHolderComponent) {
      const { placeHolder, path, mapper } = placeHolderComponent;
      const arrayPath = path.split('.');
      let step: GlobalState | string = state;
      let error = false;
      arrayPath.forEach((children) => {
        try {
          step = step[children as keyof typeof step];
        } catch (e) {
          error = true;
        }
      });
      if (error) return text;
      const key: string = step as unknown as string;
      const pair = mapper?.find((p) => p.key === key);
      if (pair) {
        return text.replace(placeHolder, pair.value);
      }
      return text.replace(placeHolder, key);
    }
    return text;
  }

  static isSocialNetworkLogosBlack(brand: string): boolean {
    return ['BAL', 'BV'].includes(brand);
  }

  static isAutoCompleteOff(prospectSource: ProspectSource): boolean {
    return [ProspectSource.LUCE, ProspectSource.LUCE_EVENT, ProspectSource.LUCE_QUEUE, ProspectSource.DCC, ProspectSource.WEBDCC_SCAN].includes(prospectSource);
  }

  static isPassPhoneModalDisplayed(prospectSource: ProspectSource): boolean {
    return [ProspectSource.LUCE, ProspectSource.LUCE_EVENT, ProspectSource.LUCE_QUEUE].includes(prospectSource);
  }

  static getPhonePrefixAndCountry(currentCountry: string, countries: Country[]): { phonePrefixInitValue: string; phoneCountryInitValue: string } {
    const targetedCountry = countries.find((country: { code: string; }) => country.code === currentCountry);
    const phonePrefixValue = targetedCountry ? targetedCountry.phonePrefix : null;
    return phonePrefixValue && targetedCountry ? { phonePrefixInitValue: phonePrefixValue, phoneCountryInitValue: targetedCountry.code }
      : { phonePrefixInitValue: '+1', phoneCountryInitValue: 'US' };
  }

  static isGenderDisplayedInsteadOfSalutation(customerCountry: string, brandCode: string): boolean {
    if (brandCode === 'GUCCI') return ['JP', 'KR'].includes(customerCountry);
    return ['JP'].includes(customerCountry);
  }

  static escapeRexExSpecialChars(regex: string) {
    return regex ? regex.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1') : '';
  }

  static toPartialLowerCase(label: string) {
    if (label.includes('-')) {
      // ex: ÎLES VIERGES DES ÉTATS-UNIS
      let wordsDash = label.split('-');
      wordsDash = wordsDash.map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1).toLowerCase()}`);
      // ex: Îles vierges des états-Unis
      const resultDash = wordsDash.join('-');
      let wordsSpace = resultDash.split(' ');
      wordsSpace = wordsSpace.map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`);
      // ex: Îles Vierges Des États-Unis
      return wordsSpace.join(' ');
    }
    let words = label.split(' ');
    words = words.map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1).toLowerCase()}`);
    return words.join(' ');
  }

  static loadImage(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const loadImg = new Image();
      loadImg.src = url;
      loadImg.onload = () => resolve(url);
      loadImg.onerror = (err) => reject(err);
    });
  }

  static getTokenFromUrl(location: Location): string {
    if (location.pathname === '/line') {
      const urlParams = new URLSearchParams(location.search);
      return urlParams.get('state') ?? '';
    }
    const tabPathName = location.pathname.split('/');
    return tabPathName[1];
  }

  static getLineCodeFromUrl(location: Location): string {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get('code') ?? '';
  }

  static isLuceProspectSource(prospectSource: ProspectSource) {
    return [ProspectSource.LUCE, ProspectSource.LUCE_EVENT, ProspectSource.LUCE_QUEUE, ProspectSource.WEBDCC_SCAN].includes(prospectSource);
  }

  static isAndroidDevice() {
    return /android/i.test(navigator.userAgent);
  }

  static isWeChatBrowser() {
    return /MicroMessenger/.test(navigator.userAgent);
  }

  static isKakaoBrowser() {
    return /KAKAOTALK/i.test(navigator.userAgent);
  }

  static isIPhoneDevice() {
    return /iPhone/i.test(navigator.userAgent);
  }

  static isChromeBrowser() {
    return /CriOS/i.test(navigator.userAgent) || /Chrome/i.test(navigator.userAgent);
  }

  static isFirefoxBrowser() {
    return /FxiOS/i.test(navigator.userAgent) || /Firefox/i.test(navigator.userAgent);
  }

  static isDigit(value: string) {
    // We cannot use regex because of japanese keyboard (iOS 11 and 12)
    return Object.values(keyboardDigitInputs).includes(value as keyboardDigitInputs);
  }

  static isAllowedKey(value: string) {
    return Object.values(keyboardInputs).includes(value as keyboardInputs);
  }

  static shouldDisplayText(translationKeySuffixComponent: TranslationKeySuffixComponent, key: EventKey, displayedByDefaultByCampaign: boolean, displayedByDefaultByApp: boolean): boolean {
    const textKey = translationKeySuffixComponent?.translationKeysSuffixOverloaded?.[key] as Key;
    const shouldDisplay = translationKeySuffixComponent?.overload && textKey ? textKey.shouldDisplay : displayedByDefaultByCampaign;
    return shouldDisplay || displayedByDefaultByApp;
  }

  static async validateForm(event: React.KeyboardEvent<HTMLDivElement>, isValid: boolean, onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>) {
    if (event.key === 'Enter' && isValid) {
      event.preventDefault();
      await onSubmit();
    }
  }

  static shouldShowTitleField(prospectSource: ProspectSource, brandCountryConfiguration: BrandCountryConfiguration, customerTitle: string, isWireEditActivated: boolean, alreadyRegistered: boolean) {
    return brandCountryConfiguration.courtesies?.includes(customerTitle) || !alreadyRegistered;
  }

  static doesNotContainOnlySpaces(value: string) {
    return value.trim().length > 0;
  }

  static shouldDisplayRecaptcha(prospectSource: ProspectSource) {
    return [ProspectSource.NQP_WIRE, ProspectSource.WEBDCC_PIN, ProspectSource.WEBDCC_STATIC, ProspectSource.WEBDCC_FRANCHISEE, ProspectSource.SELFRIDGE_QRC, ProspectSource.WEBDCC_DIGITAL, ProspectSource.WEBDCC_EXP, ProspectSource.WEBDCC_WECOM, ProspectSource.EVENT_KR_PINAULT_COLLECT_24].includes(prospectSource);
  }

  static shouldDisplayProgressBar(prospectSource: ProspectSource) {
    return [ProspectSource.NQP_WIRE, ProspectSource.UPDATE_ADDRESS].includes(prospectSource);
  }

  static isRecaptchaDisclaimerTextAndButtonInverted(brandCode: string): boolean {
    return ['YSL'].includes(brandCode);
  }

  static overloadTranslationKey(translationKeySuffixComponent: TranslationKeySuffixComponent, key: EventKey): string | undefined {
    const translationKey: Key | string = translationKeySuffixComponent?.overload && translationKeySuffixComponent?.translationKeysSuffixOverloaded?.[key] ? translationKeySuffixComponent?.translationKeysSuffixOverloaded?.[key] : translationKeySuffixComponent?.defaultTranslationKeysSuffix;
    if (typeof translationKey === 'string') {
      return translationKey;
    }
    if (typeof translationKey === 'object') {
      const { suffix } = translationKey;
      return suffix || translationKeySuffixComponent?.defaultTranslationKeysSuffix;
    }
    return undefined;
  }

  /**
   * Returns the value of the first element in the array where the first condition of conditions are true, and undefined
   * otherwise.
   * @param array the array that will be used to find the element.
   * @param conditions functions that will be tested to find the element.
   * The first condition that returns an element will return it. If no conditions are met, undefined is returned.
   */
  static findWithMultipleConditions<T>(array: T[], ...conditions: ((element: typeof array[number]) => boolean)[]): typeof array[number] | undefined {
    for (const cond of conditions) {
      const result = array.find(cond);
      if (result) {
        return result;
      }
    }
    return undefined;
  }

  static splitPhoneAndPrefix(number: string) {
    const formattedNumber = number.replaceAll(/\s/g, '');
    const phonePrefix = number.split(' ')[0] ?? '';
    const phoneNumber = formattedNumber.substr(phonePrefix.length, formattedNumber.length - phonePrefix.length).replaceAll('-', '') ?? '';
    return { phonePrefix, phoneNumber };
  }

  static mapGenderToTitle(gender: string): string {
    switch (gender) {
      case 'male':
        return 'MR.';
      case 'female':
        return 'MS.';
      default:
        return '';
    }
  }

  static mapPhonePrefixToCountry(state: GlobalState, phonePrefix: string) {
    return state.countries.filter((country) => country.phonePrefix === phonePrefix)[0].code;
  }

  static isKoreanLanguage(languageCode: string): boolean {
    return languageCode === 'ko';
  }

  static splitFirstAndLastName(name: string) {
    // Verify if name is written in Korean
    if (/^[\u3131-\uD79D ]+$/gi.test(name)) {
      // Split first name and last name
      const [lastName, ...firstName] = name
        .trim()
        .split('')
        .map((part: string) => part.trim());
      return { lastName, firstName: firstName.join('') };
    }
    // Return original name is it is not written in Korean
    return { firstName: '', lastName: name };
  }

  static setLabelColorBasedOnDevice(hasDesktopBackground: boolean, labelColor: string, desktopLabelColor: string) : string {
    if (window.matchMedia(`only screen and (min-width : ${screenSizes.desktop.width})`).matches) {
      if (hasDesktopBackground) {
        return labelColor;
      }
      return desktopLabelColor;
    }
    return labelColor;
  }

  static isCustomerMarketingConsentChecked(privacies: Privacy[], checkboxes: Checkbox[]): boolean {
    let PrivacyforMarketingActivities = false;
    if (privacies) {
      for (const pr of privacies) {
        if (pr.state) {
          for (const checkbox of checkboxes) {
            if (pr.fieldCode === checkbox.key && checkbox.isMarketingConsentChecked) {
              PrivacyforMarketingActivities = true;
            }
          }
        }
      }
    }
    return PrivacyforMarketingActivities;
  }

  static buildCertificateOfCraftLegalLinkTextInterpolation(certificateOfCraftLegalLinkText: string) {
    return `<a href="https://www.bottegaveneta.com/legal-pages/certificate-of-craft-terms-and-conditions/certificate-of-craft-terms-and-conditions.html" rel="noopener noreferrer" target="_blank">${certificateOfCraftLegalLinkText}</a>`;
  }

  static isCountryJapanThailandOrTaiwan(country: string): boolean {
    return ['JP', 'TH', 'TW'].includes(country);
  }

  static getLineQrCodeJapanThailandOrTaiwan(country: string, lineQrCode: IResponseLineQrCodes | undefined): string | undefined {
    switch (country) {
      case 'JP':
        return lineQrCode?.jpQrCode;
      case 'TH':
        return lineQrCode?.thQrCode;
      case 'TW':
        return lineQrCode?.twQrCode;
      default:
        return undefined;
    }
  }

  static exist(variable: any): boolean {
    return typeof variable !== 'undefined';
  }
 
  static isEmailFirstOnWireEditFlow = (featuresActivated: { [key in FeatureActivated]: boolean}, brand: Brand) => (featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && brand.code === BrandCode.GUCCI) || featuresActivated.IS_GUCCI_CREATE_USED;
}
