export enum PreferredMethodOfContact {
  PHONE_AND_EMAIL = 'PHONE_AND_EMAIL',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL'
}

export const radioInputs: { translationKey: string, value: string, prechecked?: boolean }[] = [
  {
    translationKey: 'dcc.phoneAndEmail',
    value: PreferredMethodOfContact.PHONE_AND_EMAIL,
  },
  {
    translationKey: 'dcc.phone',
    value: PreferredMethodOfContact.PHONE,
  },
  {
    translationKey: 'dcc.email',
    value: PreferredMethodOfContact.EMAIL,
  },
];
