import { GlobalState } from 'little-state-machine';
import Utils from '@utils/utils';
import { InformationAction } from '@/types';
import { KakaoUser, KakaoUserResponse } from '@/types/kakao';

export default class KakaoUtils {
  static retrieveCode(): string {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('code') ?? '';
  }

  static prefillWithKakaoData(state: GlobalState, updateCustomerInformation: (payload: InformationAction) => void, data: KakaoUserResponse) {
    const { phonePrefix, phoneNumber } = Utils.splitPhoneAndPrefix(data.kakao_account.phone_number);
    const { firstName, lastName } = Utils.splitFirstAndLastName(data.kakao_account.name);
    const user: KakaoUser = {
      title: Utils.mapGenderToTitle(data.kakao_account.gender),
      email: data.kakao_account.email,
      prefixCountry: Utils.mapPhonePrefixToCountry(state, phonePrefix),
      phonePrefix,
      phoneNum: phoneNumber,
      day: data.kakao_account.birthday ? data.kakao_account.birthday.substring(2, 4) : '',
      month: data.kakao_account.birthday ? data.kakao_account.birthday.substring(0, 2) : '',
      year: data.kakao_account.birthyear,
      firstName,
      lastName
    };
    updateCustomerInformation(user);
    return { email: user.email, phone: { number: user.phoneNum, prefix: user.phonePrefix, prefixCountry: user.prefixCountry } };
  }
}
