import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { GlobalState } from 'little-state-machine';
import { UseFormMethods } from 'react-hook-form';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { Field } from '@common/components/form/Field';
import { ConnectForm, Label } from '@common/components/form';
import { InformationFormProps } from '@/types/form/informationFormProps';

export const CustomerType: React.FC = () => (
  <ConnectForm>
    {({ register, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="customerType">{t('dcc.customerType')}</Label>
        <Field
          id="customerType"
          name="customerType"
          ref={register}
          disabled
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
