import Utils from '@utils/utils';
import {
  ConnectWithUsText,
  LineCountryLabelsWrapper,
  LineOrWeChatQrCodeContainer,
  LineOrWeChatQrCodeImg,
  LineOrWeChatQrCodePreview,
  LineOrWeChatQrCodePreviewContainer,
  LineQrCodeModalContainer,
  QrCodeContainer,
  QrCodeImg,
  QrCodeText,
  QrCodeTitle,
  SocialNetworkLogo,
  SocialNetworks,
  WeChatQrCodeImg,
  WeChatQrCodeModalContainer,
  WeChatQrCodePreview,
  WeChatQrCodePreviewContainer,
  WeChatQrCodeText,
  WeChatQrCodeTitle,
} from '@pages/Registered/registered.styles';
import React, { Dispatch, SetStateAction, useState } from 'react';
import RegisteredService from '@pages/Registered/registered.service';
import { useStateMachine } from 'little-state-machine';
import { useParams } from 'react-router-dom';
import { SetModal, SocialNetwork } from '@pages/Registered/registered.type';
import ImportProofOfPurchase from '@common/components/form/ImportProofOfPurchase';
import QrCodeModal from '@common/components/modals/QrCodeModal';
import Modal from '@common/components/modals/Modal';
import { SaveSaleAdvisor } from '@pages/Registered/components/SaveSaleAdvisor';
import CtaOrWallpaperButtonOrNothing from '@pages/Registered/components/CtaOrWallpaperButtonOrNothing';
import LineLabel from '@pages/Registered/components/LineLabel';
import { useTranslation } from '@/services/hooks';
import Loader from '@/common/components/Loader';
import QueueManagementRedirectButton, { QueueCreationState } from '@/common/components/QueueManagementRedirectButton';
import { Customer, ProspectSource } from '@/types';
import lineLogoBlack from '@/assets/img/socialNetworks/Line_Logo_Black.svg';
import lineLogoWhite from '@/assets/img/socialNetworks/Line_Logo_White.svg';
import weChatLogoBlack from '@/assets/img/socialNetworks/Wechat_Logo_Black.svg';
import whiteLogoBlack from '@/assets/img/socialNetworks/Wechat_Logo_White.svg';
import { TypedErrorResponseBody } from '@/types/brandedTokenError';
import { CertificateOfCraftPage } from '@/types/enum/certificateOfCraftPage';
import { IResponseLineQrCodes } from '@/types/api';

type Props = {
  lineQrCode: string,
  setLineQrCode: Dispatch<SetStateAction<string>>,
  weChatQrCode: string,
  setWeChatQrCode: Dispatch<SetStateAction<string>>,
  staticCustomer: Customer,
  lineQrCodes?: IResponseLineQrCodes,
  actualToken: string,
  isPoPAlreadySent: boolean,
  updateErrorTypeOnError: (payload: TypedErrorResponseBody) => void,
  certificateOfCraftPage: CertificateOfCraftPage,
  setActiveCertificateOfCraftPage: React.Dispatch<React.SetStateAction<CertificateOfCraftPage>>,
  isCertificateOfCraftWholesalerActivated: boolean,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const SetupDisplayFromProspectSource: React.FC<Props> = ({
  lineQrCode,
  setLineQrCode,
  weChatQrCode,
  setWeChatQrCode,
  staticCustomer,
  lineQrCodes,
  actualToken,
  isPoPAlreadySent,
  updateErrorTypeOnError,
  certificateOfCraftPage,
  setActiveCertificateOfCraftPage,
  isCertificateOfCraftWholesalerActivated,
  setIsLoading
}) => {
  // Prepare translations
  const t = useTranslation();

  // Retrieve current state from store
  const { state } = useStateMachine();
  const { brand, store, salesAdvisor, prospectSource, event, featuresActivated } = state;
  const { defaultLogo, name, style: { colors } } = brand;

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // local states
  const lineLogo = Utils.isSocialNetworkLogosBlack(brand.code) ? lineLogoBlack : lineLogoWhite;
  const weChatLogo = Utils.isSocialNetworkLogosBlack(brand.code) ? weChatLogoBlack : whiteLogoBlack;
  const [modal, setModal] = useState<SetModal>({ open: false, socialNetwork: SocialNetwork.EMPTY });
  const [activeLineCountry, setActiveLineCountry] = useState<string>(store.countryCode);

  switch (prospectSource) {
    // Sa's phone
    case ProspectSource.LUCE_EVENT:
    case ProspectSource.LUCE_QUEUE:
    case ProspectSource.WEBDCC_SFSC:
    case ProspectSource.WEBDCC_PWF_APP:
    case ProspectSource.WEBDCC_PWF_CS:
    case ProspectSource.UPDATE_MARKETING_CONSENT:
      return <CtaOrWallpaperButtonOrNothing token={token} event={event} />;
    case ProspectSource.WEBDCC_QUEUE:
    case ProspectSource.WEBDCC_APPT:
      return <QueueManagementRedirectButton clientRegistrationStatus={QueueCreationState.SUCCESS} />;
    case ProspectSource.WIRE_BVCRAFT_WHLSLR:
    case ProspectSource.WIRE_BVCRAFT_GIFT:
      return (
        <ImportProofOfPurchase
          staticCustomer={staticCustomer}
          isPoPAlreadySent={isPoPAlreadySent}
          actualToken={actualToken}
          updateErrorTypeOnError={updateErrorTypeOnError}
          certificateOfCraftPage={certificateOfCraftPage}
          setActiveCertificateOfCraftPage={setActiveCertificateOfCraftPage}
          isCertificateOfCraftWholesalerActivated={isCertificateOfCraftWholesalerActivated}
          setIsLoading={setIsLoading}
        />
      );
    // customer's phone
    default:
      if (event.isCustomJourney) {
        return <CtaOrWallpaperButtonOrNothing token={token} event={event} />;
      }
      if (featuresActivated.ARE_JPTHTW_LINE_QRCODES_DISPLAYED || featuresActivated.IS_WECHAT_MODAL_DISPLAYED) {
        return (
          // qrcode
          <>
            { modal.open && (
              <QrCodeModal onClose={() => setModal({ open: false, socialNetwork: SocialNetwork.EMPTY })}>
                {modal.socialNetwork === SocialNetwork.Line && (lineQrCodes ? (
                  <LineOrWeChatQrCodeContainer>
                    <QrCodeTitle>
                      {t('web.scanQRCodeModalTitle')}
                    </QrCodeTitle>
                    <LineCountryLabelsWrapper>
                      <LineLabel country="JP" activeLineCountry={activeLineCountry} setActiveLineCountry={setActiveLineCountry} />
                      <LineLabel country="TH" activeLineCountry={activeLineCountry} setActiveLineCountry={setActiveLineCountry} />
                      <LineLabel country="TW" activeLineCountry={activeLineCountry} setActiveLineCountry={setActiveLineCountry} />
                    </LineCountryLabelsWrapper>
                    <QrCodeText id="lineAlertMessage">
                      {t('web.lineBindingConditions')}
                    </QrCodeText>
                    <LineQrCodeModalContainer>
                      { activeLineCountry === 'JP' && (<LineOrWeChatQrCodeImg id="lineQrCode" src={lineQrCodes?.jpQrCode} alt="lineQrCode" />)}
                      { activeLineCountry === 'TH' && (<LineOrWeChatQrCodeImg id="lineQrCode" src={lineQrCodes?.thQrCode} alt="lineQrCode" />)}
                      { activeLineCountry === 'TW' && (<LineOrWeChatQrCodeImg id="lineQrCode" src={lineQrCodes?.twQrCode} alt="lineQrCode" />)}
                    </LineQrCodeModalContainer>
                  </LineOrWeChatQrCodeContainer>
                ) : <Loader color={colors.COMMON.qrCodeLoader.color} />)}
                {modal.socialNetwork === SocialNetwork.WeChat && (weChatQrCode ? (
                  <LineOrWeChatQrCodeContainer>
                    <WeChatQrCodeTitle>
                      {t('web.scanQRCodeModalTitle')}
                    </WeChatQrCodeTitle>
                    <WeChatQrCodeText id="weChatAlertMessage">
                      {t('web.wechatBindingConditions')}
                    </WeChatQrCodeText>
                    <WeChatQrCodeModalContainer>
                      <WeChatQrCodeImg id="weChatQrCode" src={weChatQrCode} alt="weChatQrCode" />
                    </WeChatQrCodeModalContainer>
                  </LineOrWeChatQrCodeContainer>
                ) : <Loader color={colors.COMMON.qrCodeLoader.color} />)}
              </QrCodeModal>
            )}
            <SocialNetworks>
              <ConnectWithUsText>
                {featuresActivated.ARE_JPTHTW_LINE_QRCODES_DISPLAYED && t('web.shareLineBinding')}
                {featuresActivated.IS_WECHAT_MODAL_DISPLAYED && t('web.shareWechatBinding')}
              </ConnectWithUsText>
              {featuresActivated.ARE_JPTHTW_LINE_QRCODES_DISPLAYED && (Utils.isCountryJapanThailandOrTaiwan(activeLineCountry)
                ? (
                  <LineOrWeChatQrCodePreviewContainer>
                    <LineOrWeChatQrCodePreview id="lineLogo" alt="lineLogo" src={Utils.getLineQrCodeJapanThailandOrTaiwan(activeLineCountry, lineQrCodes)} onClick={() => RegisteredService.handleClickLine(setModal, lineQrCode, setLineQrCode, token)} />
                  </LineOrWeChatQrCodePreviewContainer>
                ) : null)}
              {featuresActivated.IS_WECHAT_MODAL_DISPLAYED && (weChatQrCode
                ? (
                  <WeChatQrCodePreviewContainer>
                    <WeChatQrCodePreview id="weChatLogo" alt="weChatLogo" src={weChatQrCode} onClick={() => RegisteredService.handleClickWeChat(setModal, weChatQrCode, setWeChatQrCode, token)} />
                  </WeChatQrCodePreviewContainer>
                ) : <Loader color={colors.COMMON.qrCodeLoader.color} />)}
            </SocialNetworks>
          </>
        );
      }
      if (featuresActivated.IS_WECHAT_QRCODE_DISPLAYED || featuresActivated.IS_LINE_QRCODE_DISPLAYED) {
        return (
          // qrcode
          <>
            { modal.open && (
              <Modal onClose={() => setModal({ open: false, socialNetwork: SocialNetwork.EMPTY })}>
                {modal.socialNetwork === SocialNetwork.Line && (lineQrCode ? (
                  <QrCodeContainer>
                    <QrCodeImg id="lineQrCode" src={lineQrCode} alt="lineQrCode" />
                  </QrCodeContainer>
                ) : <Loader color={colors.COMMON.qrCodeLoader.color} />)}
                {modal.socialNetwork === SocialNetwork.WeChat && (weChatQrCode ? (
                  <QrCodeContainer>
                    <QrCodeText id="weChatAlertMessage">
                      {t('web.weChatAlertMessage', false, staticCustomer)}
                    </QrCodeText>
                    <QrCodeImg id="weChatQrCode" src={weChatQrCode} alt="weChatQrCode" />
                  </QrCodeContainer>
                ) : <Loader color={colors.COMMON.qrCodeLoader.color} />)}
              </Modal>
            )}
            <SocialNetworks>
              <ConnectWithUsText>{t('web.connectWithUs')}</ConnectWithUsText>
              {featuresActivated.IS_LINE_QRCODE_DISPLAYED && <SocialNetworkLogo id="lineLogo" alt="lineLogo" src={lineLogo} onClick={() => RegisteredService.handleClickLine(setModal, lineQrCode, setLineQrCode, token)} />}
              {featuresActivated.IS_WECHAT_QRCODE_DISPLAYED && <SocialNetworkLogo id="weChatLogo" alt="weChatLogo" src={weChatLogo} onClick={() => RegisteredService.handleClickWeChat(setModal, weChatQrCode, setWeChatQrCode, token)} />}
            </SocialNetworks>
          </>
        );
      }
      // we propose to save SA if we have SA data, if IS_PREFERRED_SA_ACTIVATED and IS_JP_CUSTOM_CAROUSEL_ACTIVATED are not activated and if the navigator is not weChat nor Kakao
      if (!featuresActivated.IS_PREFERRED_SA_ACTIVATED && !featuresActivated.IS_JP_CUSTOM_CAROUSEL_ACTIVATED && salesAdvisor?.firstName && salesAdvisor?.lastName && !Utils.isWeChatBrowser() && !Utils.isKakaoBrowser()) {
        return (
          <SaveSaleAdvisor
            staticCustomer={staticCustomer}
            defaultLogo={defaultLogo}
            name={name}
            colors={colors}
          />
        );
      }
      return (
        <> </>
      );
  }
};
