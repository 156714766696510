import styled from 'styled-components/macro';
import ErrorMessage from './ErrorMessage';

const WarningMessage = styled(ErrorMessage)`
  font-size: 12pt;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.COMMON.warningMessage.color};
`;

export default WarningMessage;
