import React from 'react';
import utils from '@utils/utils';
import { useStateMachine } from 'little-state-machine';
import LanguagesDropdown from '@common/components/dropdown/LanguagesDropdown';
import { TYPE_DROPDOWN } from '@/types';
import { Title } from '@/common/components';
import { useTranslation } from '@/services/hooks';

type Props = {
  brandCode: string
}

export const PrivacyPolicyAndChangeLanguage: React.FC<Props> = ({ brandCode }) => {
  const t = useTranslation();
  const { state } = useStateMachine();
  const { privacy, privacyLanguages } = state;

  if (utils.isChangeLanguageReversed(brandCode)) {
    return (
      <>
        <Title>{t(privacy.titleTextKey, true)}</Title>
        <LanguagesDropdown languages={privacyLanguages} type={TYPE_DROPDOWN.PRIVACIES} />
      </>
    );
  }
  return (
    <>
      <LanguagesDropdown languages={privacyLanguages} type={TYPE_DROPDOWN.PRIVACIES} />
      <Title>{t(privacy.titleTextKey, true)}</Title>
    </>
  );
};
