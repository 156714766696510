import React from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';
import { useStateMachine } from 'little-state-machine';
import PageContainer from '@/common/components/containers/PageContainer';
import { Background, ProspectSource } from '@/types';

type Props = {
  className?: string,
  error?: boolean,
}
const BrandContainer : React.FC<Props> = ({ children, className, error }) => {
  const { state } = useStateMachine();
  return <Container background={state.backgrounds.currentBackground} className={className} prospectSource={state.prospectSource} error={error}>{children}</Container>;
};

export default BrandContainer;

type ContainerProps = {
  background: Background,
  desktopBackground: Background,
  className?: string,
  prospectSource: ProspectSource,
  error?: boolean
}

const Container = styled(PageContainer).attrs((props: ContainerProps) => ({
  background: props.background,
  desktopBackground: props.desktopBackground,
  prospectSource: props.prospectSource,
  error: props.error,
}))`
  position: relative;
  background-color: ${(props) => (props.error && props.theme.colors.COMMON.brandContainer.error.backgroundColor)};
  
  font-size: 14pt;
  text-align: center;
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    padding: 15px;
  }
  
  color: ${(props) => props.theme.colors.COMMON.brandContainer.color};
  background-position: ${(props) => props.background.backgroundPosition};
  background-image: ${(props) => (props.background.backgroundUrl ? `url(${props.background.backgroundUrl})` : 'none')};
  background-attachment: fixed;
  background-size: cover;
  
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    background-position: ${(props) => props.desktopBackground?.backgroundPosition};
    background-image: ${(props) => props.desktopBackground ? props.desktopBackground.backgroundUrl : 'none'};
  }

  ${theme('brand', {
    AMQ: css`
      font-family: "bemboregular", sans-serif;
      padding: 15px 20px;
    `,
    BAL: css`
      font-family: "universltcyr", sans-serif;
      padding: 17px 0;
      font-size: 12pt;
      line-height: 16px;
      background-color: ${(props) => props.theme.colors.COMMON.brandContainer.temporary.backgroundColor};
    `,
    BR: css`
      letter-spacing: 2px;
      font-family: "labgrotesque", sans-serif;
      padding: 15px 20px;
    `,
    BV: css`
      font-family: "bv", sans-serif;
      background-color: ${(props) => props.theme.colors.COMMON.brandContainer.temporary.backgroundColor};
    `,
    GUCCI: css`
      font-family: "guccisans", sans-serif;
      font-size: 17pt;
    `,
    YSL: css`
      padding: 20px 15px;
      font-family: "HelveNeu", sans-serif;
      text-transform: uppercase;
      background-color: ${(props) => props.theme.colors.COMMON.brandContainer.temporary.backgroundColor};
    `,
  })};
  // This line is at the end of the file so that it will override all the previous properties
  background-color: ${(props) => (props.theme.colors.COMMON.brandContainer.backgroundOverride?.backgroundColor || props.theme.colors.COMMON.brandContainer?.temporary?.backgroundColor || 'transparent')};
  @media only screen and (min-width : ${screenSizes.desktop.width}) {
    background-color: ${(props) => (props.theme.colors.COMMON.brandContainer.backgroundOverride?.desktop?.backgroundColor || props.theme.colors.COMMON.brandContainer?.temporary?.backgroundColor || 'transparent')};
  }
}`;
