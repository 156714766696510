import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { GlobalState } from 'little-state-machine';
import { UseFormMethods } from 'react-hook-form';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { Field } from '@common/components/form/Field';
import { ConnectForm, Label } from '@common/components/form';
import { InformationFormProps } from '@/types/form/informationFormProps';

export const SaComment: React.FC = () => (
  <ConnectForm>
    {({ register, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <>
        <NameComponentWrapper>
          <Label htmlFor="saCommentCreatedOn">{t('dcc.saCommentCreatedOn')}</Label>
          <Field
            id="saCommentCreatedOn"
            name="saComment.createdOn"
            ref={register()}
            disabled
          />
        </NameComponentWrapper>
        <NameComponentWrapper>
          <Label htmlFor="saCommentCreatedBy">{t('dcc.saCommentCreatedBy')}</Label>
          <Field
            id="saCommentCreatedBy"
            name="saComment.createdBy"
            ref={register()}
            disabled
          />
        </NameComponentWrapper>
        <NameComponentWrapper>
          <Label htmlFor="saCommentContent">{t('dcc.saCommentContent')}</Label>
          <Field
            id="saCommentContent"
            name="saComment.content"
            ref={register()}
            disabled
          />
        </NameComponentWrapper>
      </>
    )}
  </ConnectForm>
);
