import { useFormContext, UseFormMethods } from 'react-hook-form';
import { GlobalState, useStateMachine } from 'little-state-machine';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { ReactElement } from 'react';
import { useTranslation } from '@/services/hooks';
import { InformationFormProps } from '@/types/form/informationFormProps';

type ChildrenFunctionProps = UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction

type ConnectFormProps = {
  children: (props: ChildrenFunctionProps) => ReactElement
}
const ConnectForm = ({ children } : ConnectFormProps) => {
  const formMethods = useFormContext<InformationFormProps>();
  const { state } = useStateMachine();
  const t = useTranslation();

  return children({ ...formMethods, ...state, t });
};

export default ConnectForm;
