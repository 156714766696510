import { ButtonWrapper, ButtonWrapperPIN, WelcomeButton, WelcomeContainer, } from '@pages/Welcome/welcome.styles';
import { LogoAndChangeLanguage } from '@pages/Welcome/components/LogoAndChangeLanguage';
import Utils from '@utils/utils';
import React from 'react';
import { Invitation } from '@pages/Welcome/components/Invitation';
import { useStateMachine } from 'little-state-machine';
import { useNavigate, useParams } from 'react-router-dom';
import mp from '@/services/mixpanel/mixpanel.service';
import { useTranslation } from '@/services/hooks';
import FlowService from '@/services/flow.service';
import { PageID } from '@/types/enum/pageID';
import { ReCaptchaDisclaimerText } from '@/common/components/ReCaptchaDisclaimerText.style';

type Props = {
  brandCode: string
  handleClick: Function
}

const ButtonAndEventDisclaimerText: React.FC<Props> = ({ brandCode, handleClick }) => {
  const t = useTranslation();

  if (Utils.isRecaptchaDisclaimerTextAndButtonInverted(brandCode)) {
    return (
      <>
        <ReCaptchaDisclaimerText>{t('web.disclaimerReCaptcha')}</ReCaptchaDisclaimerText>
        <ButtonWrapper>
          <WelcomeButton
            id="welcomeButtonPin"
            name="welcomeButton"
            onClick={() => handleClick()}
          >
            {t('web.register')}
          </WelcomeButton>
        </ButtonWrapper>
      </>
    );
  }
  return (
    <>
      <ButtonWrapperPIN>
        <WelcomeButton
          id="welcomeButtonPin"
          name="welcomeButton"
          onClick={() => handleClick()}
        >
          {t('web.register')}
        </WelcomeButton>
      </ButtonWrapperPIN>
      <ReCaptchaDisclaimerText>{t('web.disclaimerReCaptcha')}</ReCaptchaDisclaimerText>
    </>
  );
};

export const TextAndButtonToDisplay: React.FC = () => {
  // Prepare translations
  const t = useTranslation();

  // Retrieve current state and actions from store
  const { state } = useStateMachine();
  const { brand, salesAdvisor, prospectSource, featuresActivated } = state;
  const { code } = brand;

  // current page
  const localStep = PageID.WELCOME;

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // Prepare navigation
  const navigate = useNavigate();

  const handleClick = async () => {
    mp.startRegister(salesAdvisor?.vendorId, featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED);
    const nextPage = FlowService.nextPage(state, localStep);
    navigate(`/${token}/${nextPage}`);
  };

  if (Utils.shouldDisplayRecaptcha(prospectSource)) {
    return (
      <WelcomeContainer>
        <LogoAndChangeLanguage brandCode={code} />
        <Invitation />
        <ButtonAndEventDisclaimerText brandCode={code} handleClick={handleClick} />
      </WelcomeContainer>
    );
  }
  return (
    <>
      <LogoAndChangeLanguage brandCode={code} />
      <Invitation />
      <ButtonWrapper>
        <WelcomeButton
          id="welcomeButtonDefault"
          name="welcomeButton"
          onClick={() => handleClick()}
        >
          {t('web.register')}
        </WelcomeButton>
      </ButtonWrapper>
    </>
  );
};
