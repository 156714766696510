import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';

export const StyledContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.ERROR.labelColor.backgroundColor};
`;

export const StyledText = styled.div`
  margin-top: 40%;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  ${theme('brand', {
    AMQ: css`
      color: ${(props) => props.theme.colors.ERROR.labelColor.color};
    `,
    BV: css`
      padding-top: 20px;
      border-top: 1px solid ${(props) => props.theme.colors.ERROR.labelColor.borderTopColor};
    `,
    BR: css`
      color: ${(props) => props.theme.colors.ERROR.labelColor.color};
    `,
    YSL: css`
      font-size: 12pt;
      margin-top: 10px;
      margin-left: 42%;
      width: 178px;
      text-align: left;
    `,
    BAL: css`
      margin-top: 18%;
      border-top: 1px solid ${(props) => props.theme.colors.ERROR.labelColor.borderTopColor};
      border-bottom: 1px solid ${(props) => props.theme.colors.ERROR.labelColor.borderBottomColor};
      padding: 40px 60px;
      text-align: left;
    `,
    GUCCI: css`
      color: ${(props) => props.theme.colors.ERROR.labelColor.color};
    `,
  })};
`;

export const EventStyledText = styled(StyledText)`
  color: ${(props) => props.theme.background?.textParameter?.fontColor || props.theme.colors.ERROR.labelColor.event.color};
  border-top-color: ${(props) => props.theme.background?.textParameter?.fontColor || props.theme.colors.ERROR.labelColor.event.borderTopColor};
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    color: ${(props) => props.theme.colors.ERROR.labelColor.event.desktop.color};
    border-top-color: ${(props) => props.theme.colors.ERROR.labelColor.event.desktop.borderTopColor};
  }
`;
