import styled from 'styled-components';

export const KakaoModalContainer = styled.div`
  overflow-y: hidden;
  position: fixed;
  height: 100vh;
  inset: 0;
`;

export const KakaoModalBody = styled.div`
  display: flex;
  background: white;
  border: white;
  width: 250px;
  height: 140px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const KakoBtnImg = styled.img`
  width: 220px;  
`;
