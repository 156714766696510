import React from 'react';
import styled from 'styled-components';
import { VideoComponent } from '@/types/videoComponent';

type Props = {
  video: Array<VideoComponent>
}
const VideoPlayer: React.FC<Props> = ({ video }) => (
  <VideoPlayerContainer>
    <video
      id="registeredVideo"
      controls
      controlsList="nodownload"
      playsInline
      disablePictureInPicture
      width="100%"
      poster={video[0].posterUrl}
      src={video[0].videoUrl}
    />
  </VideoPlayerContainer>
);
export default VideoPlayer;

export const VideoPlayerContainer = styled.div`
  margin: 20px auto;
`;
