import React from 'react';
import Utils from '@utils/utils';
import { useStateMachine } from 'little-state-machine';
import TextButtonToDisplay from '@common/components/containers/TextButtonToDisplay';
import { ButtonBottomWrapperPIN } from '@/common/components/containers/ButtonBottomWrapper';
import { FormButton, FormButtonPIN } from '@/common/components/form/Button';
import { ButtonBottomWrapper } from '@/common/components/containers/index';
import {
  IButtonContainerProps,
  IContactButtonContainerProps,
  ILegalButtonContainerProps
} from '@/types/form/buttonContainerProps';

const ButtonContainer = (props: IButtonContainerProps | ILegalButtonContainerProps | IContactButtonContainerProps) => {
  const { state } = useStateMachine();
  const { prospectSource } = state;
  const { isValid, isSubmitting, onSubmit, pageId } = props;

  return (
    Utils.shouldDisplayRecaptcha(prospectSource)
      ? (
        <ButtonBottomWrapperPIN>
          <FormButtonPIN
            id="nextButtonPin"
            name="nextButton"
            type="button"
            disabled={!isValid || isSubmitting}
            onClick={onSubmit}
          >
            <TextButtonToDisplay pageId={pageId} props={props} />
          </FormButtonPIN>
        </ButtonBottomWrapperPIN>
      )
      : (
        <ButtonBottomWrapper>
          <FormButton
            id="nextButtonDefault"
            name="nextButton"
            type="button"
            disabled={!isValid || isSubmitting}
            onClick={onSubmit}
          >
            <TextButtonToDisplay pageId={pageId} props={props} />
          </FormButton>
        </ButtonBottomWrapper>
      )
  );
};

export default ButtonContainer;
