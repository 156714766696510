import Utils from '@utils/utils';
import React from 'react';
import { useStateMachine } from 'little-state-machine';
import LanguagesDropdown from '@common/components/dropdown/LanguagesDropdown';
import { ImgLogo, Logo } from '@/common/components';
import { TYPE_DROPDOWN } from '@/types';

type Props = {
  brandCode: string
}

export const LogoAndChangeLanguage: React.FC<Props> = ({ brandCode }) => {
  // Retrieve current state from store
  const { state } = useStateMachine();
  const { brand, languages } = state;
  const { defaultLogo } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;

  if (Utils.isChangeLanguageReversed(brandCode)) {
    return (
      <>
        <Logo><ImgLogo id="brandLogo" src={defaultLogoUrl} alt="Logo" /></Logo>
        <LanguagesDropdown languages={languages} type={TYPE_DROPDOWN.LABELS} />
      </>
    );
  }
  return (
    <>
      <LanguagesDropdown languages={languages} type={TYPE_DROPDOWN.LABELS} />
      <Logo><ImgLogo id="brandLogo" src={defaultLogoUrl} alt="Logo" /></Logo>
    </>
  );
};
