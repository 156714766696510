import React from 'react';
import useScrollToTop from '@/services/hooks/useScrollToTop';
import Modal from '@/common/components/modals/Modal';
import { GifComponent } from '@/types/gifComponent';

interface Props {
  id: string,
  toggleFadeOut: boolean,
  gifComponent: GifComponent,
  setToggleFadeOut: Function,
  setModal: Function,
}

const GifModal: React.FC<Props> = ({
  id,
  toggleFadeOut,
  gifComponent,
  setToggleFadeOut,
  setModal,
}) => {
  useScrollToTop();
  return (
    <Modal
      id={id}
      onClose={() => {
        setToggleFadeOut(true);
        setTimeout(() => { setModal({ open: false }); }, 500);
      }}
      toggleFadeOut={toggleFadeOut}
      background={{
        backgroundUrl: gifComponent.gifUrl,
        backgroundPosition: gifComponent.gifPosition,
      }}
      timeout={gifComponent.displayTime}
    />
  );
};

export default GifModal;
