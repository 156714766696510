import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';

type FieldProps = {
  hasError?: boolean,
  maxLength?: number,
  autocomplete?: string,
  inputmode?: string,
  hasWarning?: boolean
}

export const Field = styled.input.attrs<FieldProps>((props) => ({
  hasError: props.hasError ?? false,
  maxLength: props.maxLength,
})) < FieldProps >`
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 15px;
  padding: 0 10px;
  font-size: 12pt;
  
  background-color: ${(props) => props.theme.colors.COMMON.field.backgroundColor};
    border: 1px solid ${(props) => {
    if (props.hasError) {
      return props.theme.colors.COMMON.field.error.borderColor;
    } if (props.hasWarning) {
      return props.theme.colors.COMMON.field.warning.borderColor;
    } return props.theme.colors.COMMON.field.borderColor;
  }};
  border-radius: 0;
  appearance: none;
  z-index: 0;
  // for the country field and phone field to be black and no border when disabled
  &:disabled, &:read-only {
    border: none;   
    // todo check to get the new color
    color: ${(props) => props.theme.colors.LEGAL.checkboxLabelDisabled.backgroundColor};
  }
`;

export const OptionalLegend = styled.legend`
  position: absolute;
  font-size: 8pt;
  font-style: italic;
  margin-top: -5px;
  margin-left: 5px;
  text-transform: capitalize;
  ${theme('language', {
    ar: css`
       margin-left: 0;
       margin-right: 5px;
    `,
  })};
  ${theme('brand', {
    YSL: css`
      text-transform: uppercase;
    `,
    AMQ: css`
      text-transform: initial;
      font-size: 10pt;
    `,
    BV: css`
      font-weight: normal;
      font-size: 10pt;
    `,
  })};
  background-color: ${(props) => props.theme.colors.COMMON.optionalLegend.backgroundColor};
  color: ${(props) => props.theme.colors.COMMON.optionalLegend.color};
  backface-visibility: hidden;
  padding: 0 1px;
  z-index: 1;
`;
