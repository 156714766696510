import { DownloadSlAppText, SlAppContainerLink, SLAppDownloadLogo } from '@pages/Registered/registered.styles';
import mp from '@services/mixpanel/mixpanel.service';
import React from 'react';
import { useTranslation } from '@services/hooks';
import Utils from '@utils/utils';
import GooglePlayLogo from '@/assets/img/ApplicationStore/GooglePlayLogo.svg';
import AppStoreLogo from '@/assets/img/ApplicationStore/AppStoreLogo.svg';

export const SLAppDownloadButton = () => {
  // Prepare translations
  const t = useTranslation();

  if (Utils.isIPhoneDevice() || Utils.isAndroidDevice()) {
    return (
      <>
        <DownloadSlAppText>
          {t('web.downloadSLAPP')}
        </DownloadSlAppText>
        <SlAppContainerLink
          id={Utils.isIPhoneDevice() ? 'SLAPPAppStoreLogo' : 'SLAPPGooglePlayLogo'}
          href={Utils.isIPhoneDevice() ? 'https://apps.apple.com/us/app/saint-laurent/id1623343549' : 'https://play.google.com/store/apps/details?id=com.kering.slp.saintlaurent'}
          onClick={() => {
            mp.SLAppDownload();
          }}
        >
          <SLAppDownloadLogo src={Utils.isIPhoneDevice() ? AppStoreLogo : GooglePlayLogo} alt={Utils.isIPhoneDevice() ? 'SLAPPAppStoreLogo' : 'SLAPPGooglePlayLogo'} />
        </SlAppContainerLink>
      </>
    );
  }
  return <></>;
};
