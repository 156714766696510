import { useStateMachine } from 'little-state-machine';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import Utils from '@utils/utils';
import IdentityService from '@pages/Identity/identity.service';
import KakaoUtils from '@utils/kakao.utils';
import { TYPE_DROPDOWN } from '@/types';
import {
  updateBrandCountryConfiguration,
  updateCustomerInformation,
  updateErrorTypeOnError,
  updateIsKakaoUserRetrieved,
  updateIsLoading,
} from '@/littleStateMachine/actions';
import { Title } from '@/common/components';
import { ButtonContainer, FormContainer, PageContainer } from '@/common/components/containers';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@/services/hooks';
import mp from '@/services/mixpanel/mixpanel.service';
import api from '@/api';
import { FullPageLoader } from '@/common/components/Loader';
import { PageID } from '@/types/enum/pageID';
import FlowService from '@/services/flow.service';
import { IdentityForm } from '@/common/components/form/information/IdentityForm';
import FormHeader from '@/common/components/form/FormHeader';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { ThemeType } from '@/types/enum/themeType';
import kakaoApiService from '@/services/kakao-api.service';
import { LogLevelEnum } from '@/types/enum/log-level.enum';
import { LogMessageEnum } from '@/types/enum/log-message.enum';

const Identity: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.IDENTITY);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state and actions from store
  const { actions, state } = useStateMachine({ updateCustomerInformation, updateBrandCountryConfiguration, updateIsLoading, updateErrorTypeOnError, updateIsKakaoUserRetrieved });
  const { brand, brandCountryConfiguration, isLoading, prospectSource, flow, translations, languages, actualToken, isKakaoUserRetrieved, mixPanelToken } = state;
  const { mandatoryYearOfBirth } = brandCountryConfiguration;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  useThemeColor(brand, ThemeType.FORM);

  // current page
  const localStep = PageID.IDENTITY;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Retrieve token from the URL

  // Prepare the form
  const formMethods = useForm<InformationFormProps>({ mode: 'onChange' });
  const { isSubmitting, isValid } = formMethods.formState;

  // local states
  const [showDateError, setShowDateError] = useState(false);

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
    (async function fetch() {
      if (brand.code && flow.authorizedPages.includes(localStep)) {
        const code = KakaoUtils.retrieveCode();
        if (code && !isKakaoUserRetrieved) {
          actions.updateIsLoading(true);
          try {
            actions.updateIsKakaoUserRetrieved(true);
            const response = await kakaoApiService.retrieveKakaoUser(actualToken, brand.code, code, `${window.location.origin}/kakao/identity`);
            KakaoUtils.prefillWithKakaoData(state, actions.updateCustomerInformation, response.data);
          } catch (err) {
            await api.logInformation(state.actualToken, LogLevelEnum.ERROR, LogMessageEnum.KAKAO_GET_USER_ERROR);
            actions.updateIsLoading(false);
          }
          actions.updateIsLoading(false);
          navigate(`/${actualToken}/${localStep.toLowerCase()}`);
        }
      } else {
        navigate(`/${actualToken}`);
      }
    }());
  }, [navigate, actualToken]);

  const onSubmit = formMethods.handleSubmit(async ({ title = '', firstName, lastName, furiganaFirstName, furiganaLastName, day, month, year, searchFieldNationality = '' }: InformationFormProps) => {
    const identityInfos = { title, firstName, lastName, furiganaFirstName, furiganaLastName, day: String(day), month: String(month), year: String(year), searchFieldNationality: IdentityService.getCountryCodeFromNationalityTranslation(searchFieldNationality, translations) };
    if (IdentityService.checkDateFormat(formMethods)) {
      actions.updateCustomerInformation(identityInfos);
      mp.saveIdentity(identityInfos);
      const nextPage = FlowService.nextPage(state, localStep);
      navigate(`/${actualToken}/${nextPage}`);
    } else {
      formMethods.setError('day', { type: 'dateFormatError' });
      setShowDateError(true);
    }
  });

  if (brand.code && flow.authorizedPages.includes(localStep)) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code, language: translations.lightLanguageCode, mandatory: mandatoryYearOfBirth, prospectSource }}>
        { isLoading ? <FullPageLoader />
          : (
            <PageContainer>
              <FormHeader numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} languages={languages} type={TYPE_DROPDOWN.DEFAULT} defaultLogoUrl={defaultLogoUrl} />
              <Title>{Utils.isLuceProspectSource(prospectSource) ? t('web.aboutClient') : t('web.aboutYourself')}</Title>
              <FormProvider {...formMethods}>
                <form>
                  <FormContainer onKeyDown={(event) => Utils.validateForm(event, isValid, onSubmit)}>
                    <IdentityForm formMethods={formMethods} setShowDateError={setShowDateError} showDateError={showDateError} />
                  </FormContainer>
                  <ButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.IDENTITY} onSubmit={onSubmit} />
                </form>
              </FormProvider>
            </PageContainer>
          )}
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${actualToken}`} replace />;
};

export default Identity;
