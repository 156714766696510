import { useEffect } from 'react';
import { useStateMachine } from 'little-state-machine';
import { Brand } from '@/types';
import { ThemeType } from '@/types/enum/themeType';

const useThemeColor = (brand: Brand, type: ThemeType) => {
  // Update theme color for safari
  const { state } = useStateMachine();
  const { brand: { style: { colors } } } = state;
  useEffect(() => {
    const themeColor: HTMLMetaElement | null = window.document.getElementById('theme-color') as HTMLMetaElement;
    if (themeColor && brand.code) {
      switch (type) {
        case ThemeType.WELCOME: {
          themeColor.content = colors.WELCOME.theme.color;
          break;
        }
        case ThemeType.FORM: {
          themeColor.content = colors.COMMON.form.theme.color;
          break;
        }
        default: {
          themeColor.content = colors.COMMON.brandContainer.theme.color;
          break;
        }
      }
    }
  }, [brand.code]);
};

export default useThemeColor;
