import React, { useCallback, useEffect, useState } from 'react';
import Utils from '@utils/utils';
import { FormProvider, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { useNavigate, useParams } from 'react-router-dom';
import { ClassicText, SaveButton } from '@pages/Registered/registered.styles';
import moment from 'moment';
import styled from 'styled-components/macro';
import { ButtonContainer, FormContainer } from '@/common/components/containers';
import { Label } from '@/common/components/form/index';
import { Field } from '@/common/components/form/Field';
import ErrorMessage from '@/common/components/form/ErrorMessage';
import { PageID } from '@/types/enum/pageID';
import { VerificationFormProps } from '@/types/form/verificationFormProps';
import api from '@/api';
import FlowService from '@/services/flow.service';
import {
  updateAlreadyExistingCustomer,
  updateAlreadyRegistered,
  updateCustomerInformation,
  updateDateSendingOtp,
  updateErrorTypeOnError
} from '@/littleStateMachine/actions';
import { useThemeColor, useTranslation } from '@/services/hooks';
import { ThemeType } from '@/types/enum/themeType';
import { contactMethods } from '@/types/enum/contactMethods.enum';
import mp from '@/services/mixpanel/mixpanel.service';
import { MixpanelCustomerFlow } from '@/types/enum/customerFlow';
import { ErrorType } from '@/types/brandedTokenError';

export type OtpFormProps = {
  isOnRegisteredPage?: boolean,
  setOtpVerified?: Function,
  otpTextPrefix: contactMethods
}

const OtpForm: React.FunctionComponent<OtpFormProps> = ({ isOnRegisteredPage, setOtpVerified, otpTextPrefix }) => {
  // global states
  const { actions, state } = useStateMachine({ updateAlreadyExistingCustomer, updateErrorTypeOnError, updateAlreadyRegistered, updateCustomerInformation, updateDateSendingOtp });
  const { brand, customer, dateSendingOtp, translations, featuresActivated } = state;

  // local states
  const [canResendOtp, setCanResendOtp] = useState<boolean>(true);
  const [resendEnabled, setResendEnabled] = useState<boolean>(false);
  const [timerRef, setTimerRef] = useState<ReturnType<typeof setTimeout>[]>([]);
  useThemeColor(brand, ThemeType.FORM);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve token from the URL
  const { token } = useParams<{ token: string }>();

  // Prepare the form
  const formMethods = useForm<VerificationFormProps>({ mode: 'onChange' });
  const { isSubmitting, isValid } = formMethods.formState;

  const requestOTP = useCallback(async () => {
    if (!dateSendingOtp || moment().valueOf() - moment(dateSendingOtp).valueOf() > 31000) {
      setResendEnabled(false);
      try {
        const response = isOnRegisteredPage
          ? await api.registeredPageRequestOTP(customer.customerContact.preferred, customer.customerContact.phone, customer.customerContact.email, translations.lightLanguageCode, token)
          : await api.requestOTP(customer.customerContact.preferred, customer.customerContact.phone, customer.customerContact.email, translations.lightLanguageCode, token);
        actions.updateDateSendingOtp(moment().format());
        if (response?.data && !response.data.canResendOtp) {
          setCanResendOtp(false);
        }
        setTimerRef((prevTimerRef) => {
          const timer = setTimeout(() => {
            setResendEnabled(true);
          }, 31000);
          prevTimerRef.push(timer);
          return prevTimerRef;
        });
      } catch (err) {
        formMethods.setError('otpRequestFailed', {
          type: '',
          message: t('web.otpRequestFailed')
        });
      }
    }
  }, []);

  const onSubmitVerificationPage = formMethods.handleSubmit(async ({ otp }: VerificationFormProps) => {
    const localStep = PageID.VERIFICATION;
    try {
      // todo improve security on this method
      const { data: { customerLight, isLegalPageNotDisplayed } } = await api.validateOTP(customer.customerContact.preferred, otp, customer.customerContact.phone, customer.customerContact.email, brand.codeSiebel, customer.customerContact.socialAccounts.okta, token);
      if (customerLight) {
        mp.otpVerified(MixpanelCustomerFlow.UPDATE);
        // customer already exists, we autofill next forms with his data
        actions.updateAlreadyRegistered(true);
        actions.updateAlreadyExistingCustomer(customerLight);
        if ((featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED && !featuresActivated.IS_WIRE_EDIT_PHASE_2_ACTIVATED) || isLegalPageNotDisplayed) {
          FlowService.removePageFromFlow(state, PageID.LEGAL);
        }
      } else {
        // customer is new, we prefill his country based on his phone prefix
        actions.updateCustomerInformation({ country: customer.customerContact.phone.prefixCountry });
      }
      FlowService.addPageInAuthorizedPages(state, PageID.COUNTRY);
      FlowService.addPageInAuthorizedPages(state, PageID.STATE);
      const nextPage = FlowService.nextPage(state, localStep);
      timerRef.forEach((timer) => clearTimeout(timer));
      navigate(`/${token}/${nextPage}`);
    } catch (err) {
      if (err.response?.data?.errorType === ErrorType.TOO_MANY_OTP_ATTEMPTS) {
        actions.updateErrorTypeOnError(err.response.data);
        navigate('/error');
      }
      formMethods.setError('otpInvalid', { type: '', message: t('web.invalidOtp') });
    }
  });

  const onSubmitRegistrationPage = formMethods.handleSubmit(async ({ otp }: VerificationFormProps) => {
    try {
      await api.registeredPageValidateOTP(customer.customerContact.preferred, otp, customer.customerContact.phone, customer.customerContact.email, brand.codeSiebel, customer.customerContact.socialAccounts.okta, token);
      mp.otpVerified(MixpanelCustomerFlow.CREATION);
      if (setOtpVerified) setOtpVerified(true);
    } catch (err) {
      formMethods.setError('otpInvalid', { type: '', message: t('web.invalidOtp') });
    }
  });

  useEffect(() => {
    (async function fetch() {
      await requestOTP();
    }());
  }, [requestOTP]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={(e) => e.preventDefault()}>
        <FormContainer isOnRegisteredPage={isOnRegisteredPage} onKeyDown={(event) => Utils.validateForm(event, isValid, isOnRegisteredPage ? onSubmitRegistrationPage : onSubmitVerificationPage)}>
          <ClassicText isOnFormPage={!isOnRegisteredPage}>{t(`web.${otpTextPrefix}Verification.invitation`)}</ClassicText>
          <ClassicText isOnFormPage={!isOnRegisteredPage}>{t(`web.${otpTextPrefix}ValidationBenefits.create`)}</ClassicText>
          <br />
          <Label htmlFor="verification" isOnRegisteredPage={isOnRegisteredPage}>{t('web.verificationPin')}</Label>
          <Field
            name="otp"
            id="otp"
            ref={formMethods.register({ required: true })}
            maxLength={6}
            autocomplete="one-time-code"
            inputmode="numeric"
            onFocus={() => { formMethods.clearErrors('otpInvalid'); formMethods.clearErrors('otpRequestFailed'); }}
          />
          {formMethods.errors.otpRequestFailed?.message && <ErrorMessage>{formMethods.errors.otpRequestFailed.message}</ErrorMessage>}
          {formMethods.errors.otpInvalid?.message && <ErrorMessage>{formMethods.errors.otpInvalid.message}</ErrorMessage>}
          {canResendOtp && resendEnabled && (
          <>
            <OtpStateInformation>{t(`web.${otpTextPrefix}NotReceived`)}</OtpStateInformation>
            <OtpResend id="resendOtp" onClick={requestOTP}>{t(`web.${otpTextPrefix}Resend`)}</OtpResend>
          </>
          )}
          {canResendOtp && !resendEnabled && <OtpStateInformation id="wait30seconds">{t('web.wait30secondsBeforeResend')}</OtpStateInformation>}
          {!canResendOtp && <OtpStateInformation id="tooManyOtpChallenges">{t('web.tooManyOtpChallenges')}</OtpStateInformation>}
        </FormContainer>
        {
          isOnRegisteredPage
            ? <SaveButton disabled={!isValid || isSubmitting} name="nextButton" id="saveButton" onClick={onSubmitRegistrationPage}>{t('dcc.next')}</SaveButton>
            : <ButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.VERIFICATION} onSubmit={onSubmitVerificationPage} />
        }
      </form>
    </FormProvider>
  );
};

export default OtpForm;

const OtpResend = styled.p`
  display: inline;
  margin-left: 5px;
  font-size: 7pt;
  color: ${(props) => props.theme.colors.COMMON.form.otp.resend};
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const OtpStateInformation = styled.p`
  display: inline;
  font-size: 7pt;
  color: ${(props) => props.theme.colors.COMMON.form.otp.statusInformation};
`;
