import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';

export const Button = styled.button`
  display: block;
  width: 70%;
  min-height: 40px;
  margin: 0 auto;
  cursor: pointer;
  font-size: 14pt;
  color: ${(props) => props.theme.colors.COMMON.button.color};
  background-color: ${(props) => props.theme.colors.COMMON.button.backgroundColor};
  border: 1px solid ${(props) => props.theme.colors.COMMON.button.borderColor};
  text-transform: uppercase;
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    width: 40%;
  }
  
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.COMMON.button.color};
  }
  
  &:disabled {
    background-color: ${(props) => props.theme.colors.COMMON.button.disabled.backgroundColor};
    color: ${(props) => props.theme.colors.COMMON.button.disabled.color};
    border-color: ${(props) => props.theme.colors.COMMON.button.disabled.borderColor};
  }

  ${theme('brand', {
    AMQ: css`
    font-size: 11pt;
    `,
    BAL: css`
      left: 0;
      width: 100%;
      height: 53px;
      font-size: 12pt;
      border-right: none;
      border-left: none;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        width: 100%;
      }
    `,
    BR: css`
      font-size: 11pt;
      letter-spacing: 2.6px;
    `,
    BV: css`
      height: 36px;
      font-size: 11pt;
    `,
    GUCCI: css`
      font-size: 11pt;
    `,
    YSL: css`
      left: 0;
      width: 100%;
      height: 53px;
      font-size: 10pt;
      border-right: none;
      border-left: none;
      border-bottom: 1px solid ${(props) => props.theme.colors.COMMON.button.borderBottomColor};
      border-top: 1px solid ${(props) => props.theme.colors.COMMON.button.borderTopColor};
      background-color: transparent;
      font-weight: bold;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        width: 100%;
      }
    `,
  })};
`;

export const FormButton = styled(Button)`
  ${theme('brand', {
    BAL: css`
      border-top: 1px solid ${(props) => props.theme.colors.COMMON.button.form.borderTopColor};
      border-bottom: 1px solid ${(props) => props.theme.colors.COMMON.button.form.borderBottomColor};
    `,
    BR: css`
      // because the privacy labels contain a strong tag, we need to cancel the bold from the strong tags
      div > strong {
        font-weight: normal;
      }
    `,
    YSL: css`
      height: 53px;
      color: ${(props) => props.theme.colors.COMMON.button.form.color};
      border-top: 1px solid ${(props) => props.theme.colors.COMMON.button.form.borderTopColor};
      border-bottom: 1px solid ${(props) => props.theme.colors.COMMON.button.form.borderBottomColor};
      
      &:disabled {
        color: ${(props) => props.theme.colors.COMMON.button.form.disabled.color};
        background-color: ${(props) => props.theme.colors.COMMON.button.form.disabled.backgroundColor};
        border-right: none;
        border-left: none;
  }
    `,
  })};
`;

export const FormButtonPIN = styled(FormButton)`
  margin-bottom: 85px; //To be above the reCaptcha badge
`;
