import Utils from '@utils/utils';
import React from 'react';
import { NameComponentWrapper } from '@pages/Identity/identity.styles';
import { GlobalState } from 'little-state-machine';
import { UseFormMethods } from 'react-hook-form';
import { TranslationFunction } from '@services/hooks/useTranslation';
import { Field } from '@common/components/form/Field';
import { ConnectForm, Label } from '@common/components/form';
import { InformationFormProps } from '@/types/form/informationFormProps';

export const CompanyName: React.FC = () => (
  <ConnectForm>
    {({ register, prospectSource, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
      <NameComponentWrapper>
        <Label htmlFor="companyName">{t('dcc.companyName')}</Label>
        <Field
          id="companyName"
          name="companyName"
          ref={register}
          autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'organization'}
        />
      </NameComponentWrapper>
    )}
  </ConnectForm>
);
