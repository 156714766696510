import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { ConnectForm, Label } from '@/common/components/form/index';
import { OptionalLegend } from '@/common/components/form/Field';
import { Country } from '@/types';
import { SelectSearch } from '@/common/components/form/Select';
import { Widget } from '@/common/components/SearchBar';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  translatedCountries: Country[],
  displayNationality: boolean,
  mandatoryNationality: boolean,
  defaultNationality: string
}

type SelectSearchOptions = {
  value: string,
  name: string
}

export const Nationality: React.FC<Props> = ({ translatedCountries, displayNationality, mandatoryNationality, defaultNationality }) => {
  const defaultNationalityToDisplayFirst = translatedCountries.find((country: Country) => country.code === defaultNationality);
  const selectSearchOptions = translatedCountries.filter((country: Country) => country.code !== defaultNationality);
  if (defaultNationalityToDisplayFirst) {
    selectSearchOptions.unshift(defaultNationalityToDisplayFirst);
  }
  const selectSearchOptionsObject = selectSearchOptions.map((country: Country) => ({ value: country.code, name: country.name }));
  // add none value
  selectSearchOptionsObject.push({ value: '', name: '' });
  const selectOptionsValues = selectSearchOptionsObject.map((option: SelectSearchOptions) => option.name);
  return (
    <ConnectForm>
      {({ register, t, setValue, getValues, trigger, customer, translations }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
        const nationalityCode = customer.customerInfo.nationality.toLowerCase();
        const nationality = nationalityCode ? translations.lightLabels[`country.${nationalityCode}`] : '';
        return (
          <>
            { // display or not the nationality field base on brand/country conf
                  displayNationality
                  && (
                  <>
                    <Label htmlFor="searchFieldNationality">{t('dcc.nationality')}</Label>
                    {!mandatoryNationality && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
                    <SelectSearch
                      name="searchFieldNationality"
                      id="searchFieldNationality"
                      parentRef={register({
                        required: mandatoryNationality,
                        validate: {
                          doValueExist: (value: string) => selectOptionsValues.includes(value),
                        },
                      })}
                      defaultValue={nationality}
                      autoComplete="off"
                      selectOptions={selectSearchOptionsObject}
                      selectOptionsValues={selectOptionsValues}
                      setFormValue={(newValue: string) => setValue('searchFieldNationality', newValue)}
                      getFormValue={() => getValues('searchFieldNationality')}
                      triggerFormValidation={trigger}
                      widget={Widget.CROSS}
                      disabledPrefix={false}
                    />
                  </>
                  )
                }
          </>
        );
      }}
    </ConnectForm>
  );
};
