import React from 'react';
import Utils from '@utils/utils';
import { SaveWallpaperButton, StyledLink } from '@pages/Registered/registered.styles';
import RegisteredService from '@pages/Registered/registered.service';
import mp from '@services/mixpanel/mixpanel.service';
import { useStateMachine } from 'little-state-machine';
import { useTranslation } from '@services/hooks';
import { EventType } from '@/types/Event';
import { IJpCustomCarousel } from '@/types/form/jpCustomCarousel';

type Props = {
  token: string | undefined,
  event: EventType,
  isJpCustomCarouselActivated?: boolean | undefined,
  jpCustomCarousel?: IJpCustomCarousel
}

const CtaOrWallpaperButtonOrNothing: React.FC<Props> = ({ token, event, isJpCustomCarouselActivated = false, jpCustomCarousel }) => {
  // Retrieve current state from store
  const { state } = useStateMachine();
  const { wallpaperComponent, ctaComponent } = event;

  // Prepare translations
  const t = useTranslation();

  if (isJpCustomCarouselActivated && jpCustomCarousel) {
    return <SaveWallpaperButton><StyledLink id="jpCustomCarousel" href={`/api/DownloadFile/${token}?url=${jpCustomCarousel.imageUrl}&name=${jpCustomCarousel.imageName}`} onClick={RegisteredService.handleDownloadClicked}>{t(`web.pin.cta.${jpCustomCarousel.translationKey}`)}</StyledLink></SaveWallpaperButton>;
  }
  if (wallpaperComponent?.imageUrl && !(Utils.isWeChatBrowser() && Utils.isAndroidDevice())) {
    return <SaveWallpaperButton><StyledLink id="downloadWallpaper" href={`/api/DownloadFile/${token}?url=${wallpaperComponent?.imageUrl}&name=${wallpaperComponent?.imageName}`} onClick={RegisteredService.handleDownloadClicked}>{t('web.save')}</StyledLink></SaveWallpaperButton>;
  }
  if (ctaComponent?.ctaUrl?.length && ctaComponent?.translationKey?.length) {
    return <SaveWallpaperButton id="ctaButton" onClick={async () => { window.open(Utils.placeHolderComponentInterpolation(ctaComponent.ctaUrl, state, ctaComponent.placeHolderComponent)); mp.clickCta(); }}>{t(ctaComponent.translationKey)}</SaveWallpaperButton>;
  }
  return null;
};

export default CtaOrWallpaperButtonOrNothing;
