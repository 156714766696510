import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import Utils from '@utils/utils';
import { TYPE_DROPDOWN } from '@/types';

type SelectProps = {
  type: string
}

export const DropdownWelcomePage = styled.div`
  position: relative;
  color: ${(props) => props.theme.colors.COMMON.languageDropDown.container.color};
  text-align: ${(props) => props.theme.language === 'ar' ? 'left' : 'right'};
  z-index: 100;
  width: 100%;
  
  &:after {
    content: "";
    width: 15px;
    height: 15px;
  }
  
  ${theme('brand', {
    AMQ: css`
      font-size: 12pt;
    `,
    BAL: css`
      padding: 17px 16px;
      margin: 17px 0 17px 0;
      text-align: ${(props) => props.theme.language === 'ar' ? 'right' : 'left'};
      border-top: 1px solid ${(props) => props.theme.colors.COMMON.languageDropDown.container.borderTopColor};
      border-bottom: 1px solid ${(props) => props.theme.colors.COMMON.languageDropDown.container.borderBottomColor};
    `,
    BV: css`
      margin-right: 25px;
      font-size: 10pt;
    `,
    YSL: css`
      font-size: 10pt;
    `,
    GUCCI: css`
      font-size: 12pt;
    `,
    BR: css`
      font-size: 10pt;
    `,
  })};
`;

export const DropdownLegalPage = styled(DropdownWelcomePage)`
  ${theme('brand', {
    AMQ: css`
      font-size: 9pt;
    `,
    BAL: css`
      // override page container padding
      margin: 12px -15px 10px -15px;
      width: auto;
    `,
    GUCCI: css`
      font-size: 10pt;
    `,
  })};
`;

export const Select = styled.select < SelectProps >`
  line-height: 30px;
  border: none;
  background-color: transparent;
  text-align: ${(props) => props.theme.language === 'ar' ? 'left' : 'right'};
  appearance: none;
  cursor: pointer;
  color: ${(props) => (props.type === TYPE_DROPDOWN.LABELS
    ? Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.colors.COMMON.languageDropDown.labels.color, props.theme.colors.COMMON.languageDropDown.labels.desktop.color)
    : Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.colors.COMMON.languageDropDown.color, props.theme.colors.COMMON.languageDropDown.desktop.color))};
  
  direction: ${(props) => props.theme.language === 'ar' ? 'ltr' : 'rtl'};
  & option {
    direction: ltr;
    color: ${(props) => props.theme.colors.COMMON.languageDropDown.option.color};
  }
  ${theme('brand', {
    BV: css`
      color: ${(props) => (Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.COMMON.languageDropDown.labels.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.COMMON.languageDropDown.labels.desktop.color))};
    `,
    YSL: css`
      text-transform: uppercase;
      font-weight: bold;
    `,
    BAL: css`
      font-size: 12pt;
      line-height: 17px;
      height: 18px;
    `,
    BR: css`
      letter-spacing: 2px;
    `
  })};
`;
