import React from 'react';
import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';

type Props = {
  className?: string,
  currentStep: number,
  numberOfSteps: number,
}

type StepProps = {
  isCurrentStep: boolean,
  isActive: boolean,
}

const ProgressBar : React.FC<Props> = ({ className, currentStep, numberOfSteps }) => (
  <Wrapper className={className} id="progressBar">
    { [...Array(numberOfSteps)].map((step, i) => (<Step isCurrentStep={i + 1 === currentStep} isActive={currentStep >= (i + 1)} key={`step${i}`}> </Step>)) }
  </Wrapper>
);

export default ProgressBar;

const Step = styled.div < StepProps >`
  display: inline-block;
  width: 100%;
  height: ${(props) => (props.isCurrentStep ? '6px' : '4px')};
  background-color: ${(props) => (props.isActive ? props.theme.colors.COMMON.progressBar.active.backgroundColor : props.theme.colors.COMMON.progressBar.inactive.backgroundColor)};
  margin: 0 2px;
  border-radius: 7px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 35px;
  align-items: center;

    ${theme('brand', {
    YSL: css`
      margin-bottom: 45px;
    `,
  })};
`;
