import React, { Dispatch, SetStateAction } from 'react';

export default class UpdateAddressService {
  static async onBlur(setError: Dispatch<SetStateAction<boolean>>, event: React.FocusEvent<HTMLInputElement>) {
    if (setError && event.target.value.length === 0) {
      setError(false);
    }
    if (setError && event.target.value.length > 0) {
      setError(true);
    }
  }
}
