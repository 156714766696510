import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';

export type FormContainerProps = {
  isOnRegisteredPage?: boolean
}

const FormContainer = styled.div<FormContainerProps>`
  ${theme('language', {
    ar: css`
      text-align: right;
    `
  })};
  position: relative;
  // margin bottom should be > than the height of ButtonBottomWrapper component + his bottom position to prevent overlaps from next component
  margin: ${(props) => props.isOnRegisteredPage ? '50px auto 120px' : '50px 0 120px'};
  input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 1000px white inset;
  }
  width: ${(props) => props.isOnRegisteredPage && '70%'};
  
  @media only screen and (max-width : ${screenSizes.desktop.width}){
    // TODO: change the color by adding a new one to the model
    color: ${(props) => props.isOnRegisteredPage && props.theme.colors.REGISTERED.labelColor.color};
  }
  
  ${theme('brand', {
    BV: css`
      margin-top: 30px;
    `,
  })};
`;

export default FormContainer;
