import React from 'react';
import styled, { css } from 'styled-components/macro';
import screenSizes from '@utils/screenSizes';
import Utils from '@utils/utils';
import theme from 'styled-theming';
import { TYPE_DROPDOWN } from '@/types';

type Props = {
  className?: string,
  color?: string,
  type?: string
}

const Caret = ({ className, color, type }: Props) => (
  <StyledCaret
    type={type}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="9"
    viewBox="0 0 13 9"
    className={className}
    color={color}
  >
    <g fill={color} fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-162 -37)">
        <g>
          <path
            d="M164.5 36.5L166 35 172.5 41.5 166 48 164.5 46.5 169.5 41.5z"
            transform="rotate(90 168.5 41.5)"
          />
        </g>
      </g>
    </g>
  </StyledCaret>
);

export default Caret;

const StyledCaret = styled.svg < Props >`
  margin-left: ${(props) => props.theme.language === 'ar' ? '0px' : '5px'};
  margin-right: ${(props) => props.theme.language === 'ar' ? '5px' : '0px'} ;
  g {
    fill: ${(props) => props.color || (props.type === TYPE_DROPDOWN.LABELS
    ? Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.colors.COMMON.caret.labels.color, props.theme.colors.COMMON.caret.labels.desktop.color)
    : Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.colors.COMMON.caret.color, props.theme.colors.COMMON.caret.desktop.color))};
  }

  ${theme('brand', {
    BV: css`
      g {
        fill: ${(props) => (props.theme.colors.COMMON.caret.labels.color)};
      }
      @media only screen and (max-width : ${screenSizes.desktop.width}){
        g {
          fill: ${(props) => (props.theme.background?.textParameter?.fontColor || props.theme.colors.COMMON.caret.labels.color)};
        }
      }
    `,
  })};
`;
