import { EmailValidityStatusEnum } from '@/types/enum/email-validity-status.enum';

export default class EmailValidityUtils {
  static getEmailValidityErrorKey(emailValidity: EmailValidityStatusEnum, isLuceProspectSource: boolean): string {
    switch (emailValidity) {
      case EmailValidityStatusEnum.EMAIL_ADDRESS_INVALID:
        return isLuceProspectSource ? 'web.emailFormatInvalid' : 'dcc.emailFormatInvalid';
      case EmailValidityStatusEnum.EMAIL_DOMAIN_INVALID:
        return isLuceProspectSource ? 'web.emailDomainInvalid' : 'dcc.emailDomainInvalid';
      case EmailValidityStatusEnum.EMAIL_ACCOUNT_INVALID:
        return isLuceProspectSource ? 'web.emailAccountInvalid' : 'dcc.emailAccountInvalid';
      case EmailValidityStatusEnum.MAILBOX_FULL:
        return isLuceProspectSource ? 'web.mailboxFull' : 'dcc.mailboxFull';
      case EmailValidityStatusEnum.DISPOSABLE:
        return isLuceProspectSource ? 'web.emailDisposable' : 'dcc.emailDisposable';
      case EmailValidityStatusEnum.ROLE_ADDRESS:
        return isLuceProspectSource ? 'web.emailRoleAddress' : 'dcc.emailRoleAddress';
      case EmailValidityStatusEnum.UNKOWN:
        return isLuceProspectSource ? 'web.unknownEmailError' : 'dcc.unknownEmailError';
      default:
        return isLuceProspectSource ? 'web.invalidEmail' : 'dcc.invalidEmail';
    }
  }

  static isEmailValidityStatusNotAccepted(emailValidity: EmailValidityStatusEnum) {
    return [EmailValidityStatusEnum.EMAIL_ACCOUNT_INVALID, EmailValidityStatusEnum.EMAIL_ADDRESS_INVALID, EmailValidityStatusEnum.EMAIL_DOMAIN_INVALID].includes(emailValidity);
  }

  static isEmailValidityStatusToBeConfirmed(emailValidity: EmailValidityStatusEnum) {
    return [EmailValidityStatusEnum.UNKOWN, EmailValidityStatusEnum.DISPOSABLE, EmailValidityStatusEnum.MAILBOX_FULL, EmailValidityStatusEnum.ROLE_ADDRESS].includes(emailValidity);
  }
}
