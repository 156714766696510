export type Language = {
  code: string,
  name: string,
  displayOrder: number,
}

export const TYPE_DROPDOWN = {
  LABELS: 'labels',
  PRIVACIES: 'privacies',
  DEFAULT: 'default',
};
