import Utils from '@utils/utils';
import React from 'react';
import { StyledSelect } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { ConnectForm, Label } from '@/common/components/form/index';
import { InformationFormProps } from '@/types/form/informationFormProps';

export const CivilTitle: React.FC = () => (
  <ConnectForm>
    {({ register, customer, brand, brandCountryConfiguration, t }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => {
      const { customerContact: { address: { country } } } = customer;
      const { code } = brand;
      return (
        <>
          <Label htmlFor="title">{Utils.isGenderDisplayedInsteadOfSalutation(country, code) ? t('gender.title') : t('dcc.salutation')}</Label>
          <StyledSelect
            name="title"
            id="title"
            parentRef={register({ required: true })}
            defaultValue={customer.customerInfo.title}
            autoComplete="honorific-prefix"
          >
            <option value="">-</option>
            { !Utils.isGenderDisplayedInsteadOfSalutation(country, code)
              ? brandCountryConfiguration.courtesies.map((courtesy: string) => (
                <option value={courtesy} key={courtesy}>{t(`courtesy.${courtesy}`)}</option>
              ))
              : (
                <>
                  <option value="MR.">{t('gender.male')}</option>
                  <option value="MS.">{t('gender.female')}</option>
                  {brandCountryConfiguration.courtesies.includes('ABSENT') && <option value="ABSENT">{t('courtesy.ABSENT')}</option>}
                  {brandCountryConfiguration.courtesies.includes('PREFER NOT SAY') && <option value="PREFER NOT SAY">{t('courtesy.PREFER NOT SAY')}</option>}
                </>
              )}
          </StyledSelect>
        </>
      );
    }}
  </ConnectForm>
);
