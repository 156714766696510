import React, { Dispatch, SetStateAction } from 'react';
import { FieldDate } from '@pages/Identity/identity.styles';
import { UseFormMethods } from 'react-hook-form';
import IdentityService from '@pages/Identity/identity.service';
import { GlobalState, useStateMachine } from 'little-state-machine';
import { ConnectForm } from '@/common/components/form/index';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  formMethods: UseFormMethods<InformationFormProps>,
  setShowDateError: Dispatch<SetStateAction<boolean>>,
  showDateError: boolean,
}

export const Month: React.FC<Props> = ({ formMethods, setShowDateError, showDateError }) => {
  const { state } = useStateMachine();
  const { brandCountryConfiguration } = state;
  return (
    <ConnectForm>
      {({ register, customer, errors, t, getValues, trigger }: UseFormMethods<InformationFormProps> & GlobalState & TranslationFunction) => (
        <div>
          <FieldDate
            name="month"
            id="monthBirth"
            pattern="[0-9]*"
            ref={register({
              required: true,
              validate: {
                dateFormatError: () => IdentityService.checkDateFormat(formMethods),
                minimalAgeError: () => IdentityService.checkMinimalAge(formMethods, brandCountryConfiguration.minimalAge),
              },
            })}
            defaultValue={customer.customerInfo.month}
            placeholder={t('web.mm')}
            autoComplete="off"
            maxLength={2}
            onKeyDown={(event) => {
              IdentityService.avoidAlphaCharacters(event);
              IdentityService.avoidInputIfTooLong(event, getValues().month, 2);
            }}
            hasError={showDateError && errors.day && errors.day.type !== 'required'}
            onChange={(event) => IdentityService.removeAlphaCharactersAndFocusNextElementOnMaxLength(event, 2, 'month', formMethods, setShowDateError)}
            onBlur={async () => {
              IdentityService.fillMinimalLength('month', getValues().month, 2, formMethods);
              setShowDateError(true);
              // so that day, month, year will always get the same error object if an error occurred on one of them
              await trigger();
            }}
          />
        </div>
      )}
    </ConnectForm>
  );
};
