import { Dispatch, SetStateAction } from 'react';
import { SetModal, SocialNetwork } from '@pages/Registered/registered.type';
import { AxiosResponse } from 'axios';
import utils from '@utils/utils';
import RequestService from '@/services/request.service';
import mp from '@/services/mixpanel/mixpanel.service';
import api from '@/api';
import { Customer, PreferredMethodOfContact, Privacy, SalesAdvisor } from '@/types';
import { IResponseQRCode } from '@/types/api';
import { VCard } from '@/types/vcard';
import { MixpanelCustomerFlow } from '@/types/enum/customerFlow';

export default class RegisteredService {
  static async tryToFetchAndSetQrCode(fetchFunction: (token: string) => Promise<AxiosResponse<IResponseQRCode>>, setFunction: Dispatch<SetStateAction<string>>, urlToken: string | undefined) {
    const qrCodePayload = await RequestService.tryToFetchQrCode(fetchFunction, 5, 1000, urlToken);
    setFunction(qrCodePayload ? qrCodePayload.image : '');
  }

  static async fetchLineQrCodes(token: string) {
    return api.fetchLineJpThTwQrCodes(token);
  }

  static async handleClickSaveSA() {
    mp.saveSA();
  }

  static async handleClickLine(setModal: Dispatch<SetStateAction<SetModal>>, lineQrCode: string, setLineQrCode: Dispatch<SetStateAction<string>>, token: string | undefined) {
    setModal({ open: true, socialNetwork: SocialNetwork.Line });
    mp.connectLine();
    if (!lineQrCode) {
      await RegisteredService.tryToFetchAndSetQrCode(api.fetchLineQrCode, setLineQrCode, token);
    }
  }

  static async handleClickWeChat(setModal: Dispatch<SetStateAction<SetModal>>, weChatQrCode: string, setWeChatQrCode: Dispatch<SetStateAction<string>>, token: string | undefined) {
    setModal({ open: true, socialNetwork: SocialNetwork.WeChat });
    mp.connectWeChat();
    if (!weChatQrCode) {
      await RegisteredService.tryToFetchAndSetQrCode(api.fetchWeChatQrCode, setWeChatQrCode, token);
    }
  }

  static async handleClickVCardQrCode(setModal: Dispatch<SetStateAction<boolean>>, setVCardQrCodeImgSrc: Dispatch<SetStateAction<string>>, token: string | undefined) {
    setModal(true);
    const { data: vCardFormattedString } = await api.getVCard(token);
    const qrCodePayload = await RequestService.tryToFetchQrCodeWithBody(api.fetchVCardQrCode, { vCardFormattedString }, 5, 1000, token);
    setVCardQrCodeImgSrc(qrCodePayload?.image ?? '');
  }

  static async handleDownloadClicked() {
    mp.backgroundDownload();
  }

  static async handleCloseModalToDownload(setModalToDownload: Dispatch<SetStateAction<boolean>>) {
    setModalToDownload(false);
  }

  static locallySaveCustomerData(staticCustomer: Customer, setStaticCustomerFunction: Dispatch<SetStateAction<Customer>>, customer: Customer) {
    setStaticCustomerFunction({
      ...staticCustomer,
      customerInfo: {
        ...staticCustomer.customerInfo,
        firstname: customer.customerInfo.firstname,
        lastname: customer.customerInfo.lastname,
      },
      customerContact: {
        ...staticCustomer.customerContact,
        preferred: customer.customerContact.preferred,
        ...(customer.customerContact.preferred === PreferredMethodOfContact.EMAIL && {
          email: customer.customerContact.email
        }),
        ...(customer.customerContact.preferred === PreferredMethodOfContact.PHONE && {
          phone: customer.customerContact.phone
        })
      },
    });
  }

  static saveRegistrationCompletedEvent(customer: Customer, privacy: Privacy, isOktaCustomerCreated: boolean, alreadyRegistered: boolean, salesAdvisor: SalesAdvisor) {
    const { customerInfo, customerContact } = customer;
    const { furigana_lastname, furigana_firstname, lastname, month, nationality, title, year, day, firstname } = customerInfo;
    const { address, phone, email, preferred, privacies } = customerContact;
    const { city, pobox, street, zipcode } = address;
    const { number, prefix, prefixCountry } = phone;

    const customerFlow = alreadyRegistered ? MixpanelCustomerFlow.UPDATE : MixpanelCustomerFlow.CREATION;
    const privacyforMarketingActivities = utils.isCustomerMarketingConsentChecked(privacies, privacy.checkboxes);
    const fieldInfos = { day, firstName: firstname, furiganaLastName: furigana_lastname, furiganaFirstName: furigana_firstname, lastName: lastname, month, searchFieldNationality: nationality, title, year, city, phoneNum: number, phonePrefix: prefix, pobox, prefixCountry, street, zipcode, email, preferredMethodOfContact: preferred
    };

    mp.registrationCompleted(fieldInfos, isOktaCustomerCreated, customerFlow, privacyforMarketingActivities, salesAdvisor);
  }
}
