const screenSizes: {
  [key: string]: { width: string, widthNumber: number }
} = {
  ipad: {
    width: '744px',
    widthNumber: 744,
  },
  iphone5: {
    width: '320px',
    widthNumber: 320,
  },
  galaxyFold: {
    width: '280px',
    widthNumber: 280,
  },
  desktop: {
    width: '1025px',
    widthNumber: 1025,
  },
};

export default screenSizes;
