import React from 'react';
import { useTranslation } from '@services/hooks';
import { FormButton } from '@common/components/form/Button';
import ButtonBottomWrapper from '@common/components/containers/ButtonBottomWrapper';

type RedirectUrlButtonProps = {
    redirectUrl: string
}

const RedirectUrlButton = ({ redirectUrl }: RedirectUrlButtonProps) => {
  const t = useTranslation();

  return (
    <ButtonBottomWrapper>
      <FormButton
        id="redirectUrlButton"
        name="redirectUrlButton"
        type="button"
        onClick={() => {
          window.location.href = redirectUrl;
        }}
      >
        {t('web.redirectUrl')}
      </FormButton>
    </ButtonBottomWrapper>
  );
};

export default RedirectUrlButton;
