import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useStateMachine } from 'little-state-machine';
import { KakaoModalBody, KakaoModalContainer } from '@pages/CountryPage/components/kakaoModal.style';
import kakaoApiService from '@services/kakao-api.service';
import useExternalScripts from '@services/hooks/useExternalScripts';
import Modal from '@common/components/modals/Modal';
import ContinueWithWireButton from '@pages/CountryPage/components/ContinueWithWireButton';
import KakaoButton from '@pages/CountryPage/components/KakaoButton';
import { FullPageLoader } from '@common/components/Loader';
import { LogLevelEnum } from '@/types/enum/log-level.enum';
import { LogMessageEnum } from '@/types/enum/log-message.enum';
import api from '@/api';
import { PageID } from '@/types/enum/pageID';
import { updateIsLoading } from '@/littleStateMachine/actions';

interface Props {
  localStep: PageID,
  setKakaoModalDisplayed: Dispatch<SetStateAction<boolean>>,
}

const KakaoModal: React.FC<Props> = ({
  setKakaoModalDisplayed,
  localStep,
}) => {
  const { state } = useStateMachine({ updateIsLoading });
  const { brand, actualToken } = state;
  const [kakaoJsKey, setKakaoJsKey] = useState('');

  useExternalScripts('https://t1.kakaocdn.net/kakao_js_sdk/2.0.0/kakao.min.js', 'sha384-PFHeU/4gvSH8kpvhrigAPfZGBDPs372JceJq3jAXce11bVA6rMvGWzvP4fMQuBGL', 'anonymous');

  useEffect(() => {
    (async function fetch() {
      try {
        setKakaoJsKey(await kakaoApiService.retrieveKakaoJsKey(actualToken, brand.code));
      } catch (err) {
        await api.logInformation(actualToken, LogLevelEnum.ERROR, LogMessageEnum.KAKAO_AUTH_ERROR);
      }
    }());
  });

  return kakaoJsKey ? (
    <KakaoModalContainer>
      <Modal onClose={() => setKakaoModalDisplayed(false)}>
        <KakaoModalBody>
          <KakaoButton localStep={localStep} kakaoJsKey={kakaoJsKey} />
          <ContinueWithWireButton localStep={localStep} />
        </KakaoModalBody>
      </Modal>
    </KakaoModalContainer>
  ) : <FullPageLoader />;
};

export default KakaoModal;
