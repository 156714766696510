import regex from '@utils/regex';
import Utils from '@utils/utils';
import React, { Dispatch, SetStateAction } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import UpdateAddressService from '@pages/UpdateAddress/updateAddress.service';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { ProspectSource } from '@/types';
import { Field, OptionalLegend } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form';

type Props = {
  prospectSource: ProspectSource,
  showStreetError?: boolean,
  setShowStreetError?: Dispatch<SetStateAction<boolean>>,
}

export const Street: React.FC<Props> = ({ prospectSource, showStreetError, setShowStreetError }) => (
  <ConnectForm>
    {({ register, customer, errors, t }: UseFormMethods & GlobalState & TranslationFunction) => (
      <>
        <Label htmlFor="street">{t('dcc.street')}</Label>
        {prospectSource !== ProspectSource.UPDATE_ADDRESS && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
        <Field
          name="street"
          id="street"
          ref={register({
            pattern: regex.street,
            required: prospectSource === ProspectSource.UPDATE_ADDRESS,
          })}
          defaultValue={customer.customerContact.address.street}
          autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'street-address'}
          hasError={showStreetError && errors?.street?.type}
          onChange={() => setShowStreetError?.(false)}
          onBlur={(event) => setShowStreetError && UpdateAddressService.onBlur(setShowStreetError, event)}
          maxLength={200}
        />
      </>
    )}
  </ConnectForm>
);
