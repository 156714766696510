import React, { Dispatch, SetStateAction } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { LineCountryBtn } from '@pages/Registered/registered.styles';
import { useTranslation } from '@services/hooks';

type Props = {
  country: string;
  activeLineCountry: string;
  setActiveLineCountry: Dispatch<SetStateAction<string>>;
}

const LineLabel = ({ country, activeLineCountry, setActiveLineCountry }: Props) => {
  const t = useTranslation();
  const handleLineLabelClick = async (whichCountry: string) => {
    setActiveLineCountry(whichCountry);
  };

  return (
    <ThemeProvider theme={{ isActive: activeLineCountry === country }}>
      <LineCountryBtn id={`${country.toLowerCase()}Tab`} onClick={() => handleLineLabelClick(country)} type="button">
        {t(`country.${country.toLocaleLowerCase()}`)}
      </LineCountryBtn>
    </ThemeProvider>
  );
};

export default LineLabel;
