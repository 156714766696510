import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { createStore, GlobalState, StateMachineProvider } from 'little-state-machine';

import Welcome from '@pages/Welcome';
import CountryPage from '@pages/CountryPage';
import StatePage from '@pages/StatePage';
import Identity from '@pages/Identity';
import Contact from '@pages/Contact';
import Address from '@pages/Address';
import Legal from '@pages/Legal';
import Registered from '@pages/Registered';
import Error from '@pages/Error';
import SocialAccounts from '@pages/SocialAccounts';
import Utils from '@utils/utils';
import Verification from '@pages/Verification';
import Information from '@pages/Information';
import UpdateAddress from '@pages/UpdateAddress';
import UpdateMarketingConsent from '@pages/UpdateMarketingConsent/updateMarketingConsent';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import UpdateMarketingConsentThankYou from '@pages/UpdateMarketingConsentThankYou/updateMarketingConsentThankYou';
import CustomerName from '@pages/CustomerName';
import UpdateNewPosCustomer from '@pages/UpdateNewPosCustomer/UpdateNewPosCustomer';
import { ProspectSource } from '@/types';
import api from '@/api';
import store from '@/littleStateMachine/state';

type Props = {
  prospectSource: ProspectSource,
  reCaptchaFrontKey: string,
}

createStore({
  ...store,
});

const App = () => {
  const state: GlobalState | null = JSON.parse(window.sessionStorage.getItem('__LSM__') || 'null');
  const token = state?.actualToken || Utils.getTokenFromUrl(document.location);
  const lineCode = Utils.getLineCodeFromUrl(document.location);
  const [reCaptchaFrontKey, setReCaptchaFrontKey] = useState('');
  const [prospectSource, setProspectSource] = useState(ProspectSource.DEFAULT);

  useEffect(() => {
    (async function fetch() {
      const { data: payload } = await api.getProspectSourceAndReCaptchaFrontKey(token);
      setReCaptchaFrontKey(payload.reCaptchaFrontKey);
      setProspectSource(payload.prospectSource);
    }());
  }, []);

  return (
    <StateMachineProvider>
      <Router>
        <Routes>
          <Route path="/error" element={<Error />} />
          <Route path="/:token" element={<Welcome />} />
          <Route path="/:token/*" element={<ChildrenRoutes prospectSource={prospectSource} reCaptchaFrontKey={reCaptchaFrontKey} />} />
          <Route path="/:token/registered" element={<Registered />} />
          <Route path="/line" element={<Navigate to={`/${token}?lineCode=${lineCode}`} replace />} />
        </Routes>
      </Router>
    </StateMachineProvider>
  );
};

const ChildrenRoutes : React.FC<Props> = ({ prospectSource, reCaptchaFrontKey }) => {
  if (Utils.shouldDisplayRecaptcha(prospectSource)) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={reCaptchaFrontKey}
        language={navigator.language}
        useRecaptchaNet
        useEnterprise
      >
        <FormRoutes />
      </GoogleReCaptchaProvider>
    );
  }
  return <FormRoutes />;
};

const FormRoutes = () => (
  <Routes>
    <Route path="update_new_pos_customer" element={<UpdateNewPosCustomer />} />
    <Route path="verification" element={<Verification />} />
    <Route path="country" element={<CountryPage />} />
    <Route path="state" element={<StatePage />} />
    <Route path="identity" element={<Identity />} />
    <Route path="information" element={<Information />} />
    <Route path="customer_name" element={<CustomerName />} />
    <Route path="contact" element={<Contact />} />
    <Route path="address" element={<Address />} />
    <Route path="update_address" element={<UpdateAddress />} />
    <Route path="social" element={<SocialAccounts />} />
    <Route path="legal" element={<Legal />} />
    <Route path="update_marketing_consent" element={<UpdateMarketingConsent />} />
    <Route path="update_marketing_consent_thank_you" element={<UpdateMarketingConsentThankYou />} />
  </Routes>
);

export default App;
