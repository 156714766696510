export type PreciselyAuth = {
  authToken: string;
  instanceKey: string;
}

export type PreciselySearchAddressResponseEntry = {
  // Contains the initial search value
  // ex: 14 Rue Auber
  AddressLine1: string;
  // ex: Paris
  Locality: string,
  // ex: Île-de-france
  StateProvince: string,
  // ex: 75009
  PostalCode: string,
  // ex: Paris
  City: string,
  // ex: GTA
  ProcessedBy: string,
  // key to pass on next requests to maintain a precisely user session
  // ex: ACUjMzMTY1MjU1NzM4MTAwMgND01MT
  InstanceKey: string,
  // ex: 14 Rue Auber, Paris, Île-de-france 75009
  FormattedAddress: string,
  // ex: []
  Ranges: [],
  // ex: FRA
  Country: string,
  // ex: []
  user_fields: []

  // ex: F
  Status?: string
  // ex: NotEnoughInput
  'Status.Code'?: string
  'Status.Description'?: string
}

export const NOT_ENOUGH_INPUT = 'NotEnoughInput';

export type PreciselySearchAddressResponse = {
  Output: PreciselySearchAddressResponseEntry[]
}
