import React from 'react';
import FlowService from '@services/flow.service';
import kakaoApiService from '@services/kakao-api.service';
import { StyledLink } from '@pages/Registered/registered.styles';
import { KakoBtnImg } from '@pages/CountryPage/components/kakaoModal.style';
import Utils from '@utils/utils';
import mp from '@services/mixpanel/mixpanel.service';
import kakaoButtonKorean from '@assets/img/socialNetworks/kakao_button-ko.png';
import kakaoButtonEnglish from '@assets/img/socialNetworks/kakao_button-en.png';
import { useStateMachine } from 'little-state-machine';
import { useNavigate } from 'react-router-dom';
import { PageID } from '@/types/enum/pageID';
import { updateIsLoading } from '@/littleStateMachine/actions';
import { LogMessageEnum } from '@/types/enum/log-message.enum';
import { LogLevelEnum } from '@/types/enum/log-level.enum';
import api from '@/api';

interface Props {
  localStep: PageID,
  kakaoJsKey: string,
}

const KakaoButton: React.FC<Props> = ({ localStep, kakaoJsKey }) => {
  const { state, actions } = useStateMachine({ updateIsLoading });
  const { featuresActivated } = state;
  const navigate = useNavigate();

  const onKakaoBtnClick = async () => {
    try {
      mp.clickKakaoCta();
      actions.updateIsLoading(true);
      FlowService.nextPage(state, localStep);
      window.sessionStorage.setItem('__LSM__', JSON.stringify(state));
      kakaoApiService.refreshKakaoToken(kakaoJsKey);
      kakaoApiService.loginKakaoV2(`${window.location.origin}/kakao/${featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED ? 'contact' : 'identity'}`);
      actions.updateIsLoading(false);
    } catch (err) {
      await api.logInformation(state.actualToken, LogLevelEnum.ERROR, LogMessageEnum.KAKAO_INIT_ERROR);
      actions.updateIsLoading(false);
      navigate('/error');
    }
  };

  return (
    <StyledLink id="continueWithKakaoBtn" onClick={onKakaoBtnClick}>
      <KakoBtnImg
        id="kakaoBtnImage"
        src={Utils.isKoreanLanguage(state.translations.lightLanguageCode) ? kakaoButtonKorean : kakaoButtonEnglish}
        alt="Kakao Login button"
      />
    </StyledLink>
  );
};

export default KakaoButton;
