import styled from 'styled-components/macro';
import screenSizes from '@utils/screenSizes';
import { Field } from '@/common/components/form/Field';

export const StyledField = styled(Field)`
  width: 50%;
`;

export const SearchOptionsContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: 70px; // <!> Should not be lower than StyledSelectSearchCustom height component. Otherwise this component will overlay it <!>
  width: 100%;
  max-height: 150px;
  padding-left: 10px;
  overflow: scroll;
  background-color: ${(props) => props.theme.colors.COMMON.form.select.optionContainer.backgroundColor};
  border: 1px solid ${(props) => props.theme.colors.COMMON.form.select.optionContainer.borderColor};
  border-radius: 0;
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    max-height: 315px;
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
`;
