import React, { useEffect } from 'react';
import { useLastPageVisited, useScrollToTop, useThemeColor, useTranslation } from '@services/hooks';
import { Navigate, useNavigate } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import FlowService from '@services/flow.service';
import { FormProvider, useForm } from 'react-hook-form';
import { ThemeProvider } from 'styled-components/macro';
import { ButtonContainer, FormContainer, PageContainer } from '@common/components/containers';
import FormHeader from '@common/components/form/FormHeader';
import { Title } from '@common/components';
import Utils from '@utils/utils';
import { NamesWrapper } from '@pages/Identity/identity.styles';
import { LastName } from '@common/components/form/information/LastName';
import { FirstName } from '@common/components/form/information/FirstName';
import moment from 'moment/moment';
import { TYPE_DROPDOWN } from '@/types';
import { InformationFormProps } from '@/types/form/informationFormProps';
import { ThemeType } from '@/types/enum/themeType';
import { PageID } from '@/types/enum/pageID';
import { CheckCustomerData, CustomerNameFormProps } from '@/types/form/customer-name-form.props';
import api from '@/api';
import {
  updateAlreadyExistingCustomer,
  updateAlreadyRegistered,
  updateCustomerInformation,
  updateErrorTypeOnError
} from '@/littleStateMachine/actions';
import { CustomerCheckResponseEnum } from '@/types/api';
import mp from '@/services/mixpanel/mixpanel.service';

const CustomerName: React.FunctionComponent = () => {
  useScrollToTop();
  useLastPageVisited(PageID.CUSTOMER_NAME);

  // Prepare navigation
  const navigate = useNavigate();

  // Prepare translations
  const t = useTranslation();

  // Retrieve current state and actions from store
  const { actions, state } = useStateMachine({ updateAlreadyRegistered, updateAlreadyExistingCustomer, updateErrorTypeOnError, updateCustomerInformation });
  const { brand, prospectSource, actualToken, languages, translations, customer, featuresActivated, flow, mixPanelToken } = state;
  const { customerContact } = customer;
  const { email, phone: { prefix, number } } = customerContact;
  const { defaultLogo, style: { colors } } = brand;
  const defaultLogoUrl = defaultLogo.logoUrl;
  useThemeColor(brand, ThemeType.FORM);

  // current page
  const localStep = PageID.CUSTOMER_NAME;
  const currentStep = FlowService.getCurrentStepNumber(state, localStep);

  // Prepare the form
  const formMethods = useForm<InformationFormProps>({ mode: 'onChange' });
  const { isSubmitting, isValid } = formMethods.formState;

  useEffect(() => {
    mp.init(mixPanelToken, actualToken, null);
    mp.pageView(localStep);
  }, [navigate]);

  const onSubmit = formMethods.handleSubmit(async ({ firstName, lastName }: CustomerNameFormProps) => {
    const customerData: CheckCustomerData = {
      firstName,
      lastName,
      phone: { prefix, number },
      email
    };
    actions.updateCustomerInformation({ firstName, lastName });
    try {
      const { data: { checkByNameAndContactResult, oktaLogin } } = await api.checkCustomerByNameAndContact(customerData, actualToken);

      // Avoid to trigger error in case of exhausted customer call
      if (checkByNameAndContactResult !== CustomerCheckResponseEnum.DUPLICATION_ALERT) {
        // Update mix-panel super properties
        await mp.updateCustomerFlowGlobally(actualToken);
      }

      if (checkByNameAndContactResult === CustomerCheckResponseEnum.UPDATE_AUTHORIZED) {
        actions.updateAlreadyRegistered(true);
        if (!oktaLogin) {
          const oktaCustomerData = {
            ...state.customer,
            customerInfo: {
              ...state.customer.customerInfo,
              firstname: firstName,
              lastname: lastName
            },
            language: translations.privacyLanguageCode, // push the privacy language even if customer.language is another language (application language)
            registrationDateInStore: moment().utc().format(),
            registrationSACode: state.salesAdvisor?.vendorId
          };
          const { data: oktaResponse } = await api.createOktaCustomer(oktaCustomerData, actualToken);
          // store okta id to send it in validateOtp request
          actions.updateCustomerInformation({ okta: oktaResponse.oktaLogin });
        } else {
          actions.updateCustomerInformation({ okta: oktaLogin });
        }
        const nextPage = FlowService.nextPage(state, localStep);
        navigate(`/${actualToken}/${nextPage}`);
      } else {
        actions.updateAlreadyRegistered(false);
        const nextPage = FlowService.nextPage(state, localStep, PageID.INFORMATION);
        flow.authorizedPages = flow.authorizedPages.filter((page) => page !== PageID.VERIFICATION);
        navigate(`/${actualToken}/${nextPage}`);
      }
    } catch (err) {
      actions.updateErrorTypeOnError(err.response?.data);
      navigate('/error');
    }
  });

  if (brand.code && featuresActivated.IS_WIRE_EDIT_PHASE_2_ACTIVATED) {
    return (
      <ThemeProvider theme={{ colors, brand: brand.code }}>
        <PageContainer>
          <FormHeader numberOfSteps={FlowService.getTotalNumberOfSteps(state)} currentStep={currentStep} languages={languages} type={TYPE_DROPDOWN.DEFAULT} defaultLogoUrl={defaultLogoUrl} />
          <Title>{Utils.isLuceProspectSource(prospectSource) ? t('web.aboutClient') : t('web.aboutYourself')}</Title>
          <FormProvider {...formMethods}>
            <form>
              <FormContainer onKeyDown={(event) => Utils.validateForm(event, isValid, onSubmit)}>
                {
                  Utils.isFirstNameAndLastNameReversed(translations.lightLanguageCode, customer.customerContact.address.country)
                    ? (
                      <NamesWrapper>
                        <LastName prospectSource={prospectSource} isDisabled={false} />
                        <FirstName prospectSource={prospectSource} isDisabled={false} />
                      </NamesWrapper>
                    ) : (
                      <NamesWrapper>
                        <FirstName prospectSource={prospectSource} isDisabled={false} />
                        <LastName prospectSource={prospectSource} isDisabled={false} />
                      </NamesWrapper>
                    )
                }
              </FormContainer>
              <ButtonContainer isValid={isValid} isSubmitting={isSubmitting} pageId={PageID.CUSTOMER_NAME} onSubmit={onSubmit} />
            </form>
          </FormProvider>
        </PageContainer>
      </ThemeProvider>
    );
  }
  return <Navigate to={`/${actualToken}`} replace />;
};

export default CustomerName;
