import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import Utils from '@utils/utils';

export type TextProps = {
  isOnRegisteredPage?: boolean
}

const Label = styled.label<TextProps>`
  display: flex;
  width: 100%;
  margin-bottom: 7px;
  font-size: 11pt;
  color: ${(props) => props.isOnRegisteredPage ? Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.desktop.color) : props.theme.colors.COMMON.label.color};
  
  ${theme('language', {
    ar: css`
       direction: rtl;
      `,
  })};
  
  ${theme('brand', {
    AMQ: css`
      text-transform: uppercase;
      font-size: 9pt;
    `,
    BV: css`
      font-size: 9pt;
    `,
    GUCCI: css`
      text-transform: uppercase;
      font-size: 9pt;
    `,
    YSL: css`
      text-transform: uppercase;
      font-size: 12pt;
      font-weight: bold;
    `,
    BR: css`
      text-transform: uppercase;
      font-size: 9pt;
    `,
  })};
`;

export default Label;
