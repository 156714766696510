import Utils from '@utils/utils';
import React, { Dispatch, SetStateAction } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { GlobalState } from 'little-state-machine';
import regex from '@utils/regex';
import UpdateAddressService from '@pages/UpdateAddress/updateAddress.service';
import { TranslationFunction } from '@/services/hooks/useTranslation';
import { ProspectSource } from '@/types';
import { Field, OptionalLegend } from '@/common/components/form/Field';
import { ConnectForm, Label } from '@/common/components/form';

type Props = {
  prospectSource: ProspectSource,
  showCityError?: boolean,
  setShowCityError?: Dispatch<SetStateAction<boolean>>,
}

export const City: React.FC<Props> = ({ prospectSource, setShowCityError, showCityError }) => (
  <ConnectForm>
    {({ register, customer, errors, t }: UseFormMethods & GlobalState & TranslationFunction) => (
      <>
        <Label htmlFor="city">{t('dcc.city')}</Label>
        {prospectSource !== ProspectSource.UPDATE_ADDRESS && <OptionalLegend>{t('dcc.optional')}</OptionalLegend>}
        <Field
          name="city"
          id="city"
          ref={register({
            pattern: regex.city,
            required: prospectSource === ProspectSource.UPDATE_ADDRESS,
          })}
          defaultValue={customer.customerContact.address.city}
          autoComplete={Utils.isAutoCompleteOff(prospectSource) ? 'off' : 'address-level2'}
          hasError={showCityError && errors?.city?.type}
          onChange={() => setShowCityError?.(false)}
          onBlur={(event) => setShowCityError && UpdateAddressService.onBlur(setShowCityError, event)}
          maxLength={50}
        />
      </>
    )}
  </ConnectForm>
);
