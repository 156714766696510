import { NavigateFunction } from 'react-router';
import mp from '@/services/mixpanel/mixpanel.service';
import { BackgroundEnum } from '@/types/enum/background';
import api from '@/api';
import { Background, InformationAction, LightConfiguration } from '@/types';
import { BrandedTokenErrorResponseBody, ErrorHttpResponse, TypedErrorResponseBody } from '@/types/brandedTokenError';
import { LineUserResponse } from '@/types/line';

export default class WelcomeService {
  static sendMpWelcomeEvent(payload: LightConfiguration, token: string) {
    mp.open(payload, token);
  }

  static sendMpStartRegisterEvent(salesAdvisorId: string, isWireEditActivated: boolean) {
    mp.startRegister(salesAdvisorId, isWireEditActivated);
  }

  static async handleCustomBackgrounds(updateBackgroundAction: (payload: {background: Background, backgroundName: BackgroundEnum}) => void, payload: LightConfiguration) {
    if (payload.brand?.backgrounds?.welcomePage?.backgroundUrl || payload.brand?.backgrounds?.welcomePage?.backgroundColor) {
      updateBackgroundAction({
        background: payload.brand.backgrounds.welcomePage,
        backgroundName: BackgroundEnum.currentBackground,
      });
      updateBackgroundAction({
        background: payload.brand.backgrounds.welcomePage,
        backgroundName: BackgroundEnum.welcomeBackground,
      });
    }
    if (payload.brand?.backgrounds?.registeredPage?.backgroundUrl || payload.brand?.backgrounds?.registeredPage?.backgroundColor) {
      updateBackgroundAction({
        background: payload.brand.backgrounds.registeredPage,
        backgroundName: BackgroundEnum.registeredBackground,
      });
    }
    if (payload.brand?.desktopBackgrounds?.welcomePage?.backgroundUrl || payload.brand?.desktopBackgrounds?.welcomePage?.backgroundColor) {
      updateBackgroundAction({
        background: payload.brand.desktopBackgrounds.welcomePage,
        backgroundName: BackgroundEnum.currentDesktopBackground,
      });
      updateBackgroundAction({
        background: payload.brand.desktopBackgrounds.welcomePage,
        backgroundName: BackgroundEnum.welcomeDesktopBackground,
      });
    }
    if (payload.brand?.desktopBackgrounds?.registeredPage?.backgroundUrl || payload.brand?.desktopBackgrounds?.registeredPage?.backgroundColor) {
      updateBackgroundAction({
        background: payload.brand.desktopBackgrounds.registeredPage,
        backgroundName: BackgroundEnum.registeredDesktopBackground,
      });
    }
  }

  static async loadConfAndSetLanguage(fetchConfAction: (payload: LightConfiguration) => void, payload: LightConfiguration) {
    if (payload.i18n.browserCode) {
      window.document.documentElement.lang = payload.i18n.browserCode;
    }
    fetchConfAction(payload);
  }

  static handlePrefilledData(prefillDataAction: (payload: LightConfiguration) => void, payload: LightConfiguration) {
    if (payload.prefilledClientData) {
      prefillDataAction(payload);
    }
  }

  static prepareForNewRegistration(resetStoreAction: (payload: undefined) => void, updateTokenAction: (payload: string) => void, token: string, actualToken: string) {
    if (actualToken && actualToken !== token) {
      resetStoreAction(undefined);
    }
    updateTokenAction(token || '');
  }

  static async handleStaticToken(resetStoreAction: (payload: undefined) => void, token: string, navigate: NavigateFunction, justPoseId?: string, requestedLanguage?: string): Promise<string> {
    resetStoreAction(undefined);
    const { data: requestDynamicTokenPayload } = await api.requestToken(token, justPoseId);
    token = requestDynamicTokenPayload.dynamicToken;
    navigate(requestedLanguage ? `/${token}?language=${requestedLanguage}` : `/${token}`);
    return token;
  }

  static handleError(
    err: ErrorHttpResponse,
    updateBrandOnErrorAction: (payload: BrandedTokenErrorResponseBody) => void,
    updateErrorTypeOnErrorAction: (payload: TypedErrorResponseBody) => void,
    fetchStoreConfiguration: (payload: LightConfiguration) => void,
    code: string,
    token: string
  ) {
    if (err.response?.data?.defaultLogo && !code) {
      updateBrandOnErrorAction(err.response.data);
    }
    if (err.response?.data?.mixPanelToken) {
      mp.init(err.response.data.mixPanelToken, token, null);
    }
    if (err.response?.data?.confLight) {
      fetchStoreConfiguration(err.response.data.confLight);
    }
    if (err.response) {
      updateErrorTypeOnErrorAction(err.response.data);
    }
  }

  static prefillWithLineData(updateCustomerInformation: (payload: InformationAction) => void, lineUser: LineUserResponse) {
    const user: InformationAction = {
      email: lineUser.email,
      line: lineUser.userId
    };
    updateCustomerInformation(user);
  }
}
