import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';

const Title = styled.h1`
  margin: 0;
  font-size: 20pt;
  color: ${(props) => props.theme.colors.COMMON.title.color};

  ${theme('brand', {
    AMQ: css`
      font-size: 16pt;
      text-transform: uppercase;
    `,
    BAL: css`
      font-size: 12pt;
      font-weight: normal;
      text-transform: uppercase;
    `,
    BV: css`
      font-size: 14pt;
      text-transform: uppercase;
    `,
    BR: css`
      letter-spacing: 2px;
      font-size: 14pt;
      text-transform: uppercase;
    `,
    GUCCI: css`
      font-weight: normal;
    `,
    YSL: css`
      text-transform: uppercase;
    `,
  })};
`;

export default Title;
