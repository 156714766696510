import React, { useEffect, useState } from 'react';
import { FullPageContainer } from '@common/components/containers/pageContainer.style';

type Props = {
  className?: string,
  kakaoModalDisplayed?: boolean,
}

const PageContainer : React.FC<Props> = ({ children, className, kakaoModalDisplayed }) => {
  const [minHeight, setMinHeight] = useState(window.innerHeight);
  useEffect(() => {
    // React doesn't have a resize event baked into it, but we can listen to the native browser window resize event from within our React component:
    window.addEventListener('resize', () => setMinHeight(window.innerHeight));
    return () => {
      window.removeEventListener('resize', () => setMinHeight(window.innerHeight));
    };
  });
  return <FullPageContainer id="container" className={className} minHeight={minHeight} kakaoModalDisplayed={kakaoModalDisplayed}>{children}</FullPageContainer>;
};

export default PageContainer;
