import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';
import Utils from '@utils/utils';
import { Field, OptionalLegend } from '@/common/components/form/Field';

export const FormGroup = styled.div`
  display: flex;
  & > div {
    flex-grow: 1;
  }

  & > input {
    flex-grow: 3;
    margin-left: 5px;
  }

  ${theme('language', {
    ar: css`
       direction: rtl;
       & > input {
        margin-left: 0;
        margin-right: 5px;
        }
      `,
  })};
`;

export const RadiosContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 20px;
  margin-bottom: 35px;
  
  ${theme('language', {
    ar: css`
       direction: rtl;
       margin-bottom: 35px;
      `,
  })};
`;

export const RadioStringLabel = styled.span`
  margin: 0 0 0 5px;
  font-size: 11pt;

  ${theme('language', {
    ar: css`
       margin: 0 5px 0 0;
      `,
  })};
`;

export const RadioLabel = styled.label`
  font-size: 14pt;
  display: flex;
  align-items: center;
  margin: 0 8px 0;
  cursor: pointer;
`;

export const CustomRadio = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  float: left;

  text-align: center;
  font-weight: bold;

  box-sizing: border-box;

  border: 1px solid ${(props) => props.theme.colors.CONTACT.labelColor.borderColor};
  background-color: ${(props) => props.theme.colors.CONTACT.labelColor.backgroundColor};
`;

export const StyledRadioInput = styled.input`
  width: 1px;
  height: 1px;
  display: none;
  
  &:checked + ${CustomRadio} {
    background-color: ${(props) => props.theme.colors.CONTACT.labelColor.checked.backgroundColor};
    &:before {
      content: '';
    }
  }
`;

export const FieldPhone = styled(Field)`
  @media only screen and (max-width : 695px) and (min-width : ${screenSizes.desktop.width}){
    width: 60%;
  }
  ${theme('language', {
    ar: css`
      text-align: end;
      direction: ltr !important;
    `,
  })};
`;

export const OptionalLegendPhone = styled(OptionalLegend)`
  margin-left: ${Utils.isAndroidDevice() ? '30%' : '40%'}; // minimum to match SelectSearchPrefixNumber size respectively on android and IOS
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    margin-left: 24%;
  }
`;
