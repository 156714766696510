import Utils from '@utils/utils';
import { Phone } from '@pages/Contact/components/Phone';
import { Email } from '@pages/Contact/components/Email';
import React, { Dispatch, SetStateAction } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { Country, PreferredMethodOfContact, ProspectSource } from '@/types';
import { PageID } from '@/types/enum/pageID';
import { InformationFormProps } from '@/types/form/informationFormProps';

type Props = {
  preferredMethodOfContact: PreferredMethodOfContact,
  prospectSource: ProspectSource,
  formMethods: UseFormMethods<InformationFormProps>,
  translatedCountries: Country[],
  phonePrefixLabel: string,
  setPhonePrefixLabel: Dispatch<SetStateAction<string>>,
  phoneUserInputValue: string,
  setPhoneUserInputValue: Dispatch<SetStateAction<string>>,
  emailUserInputValue: string,
  setEmailUserInputValue: Dispatch<SetStateAction<string>>,
  phoneError: boolean,
  setPhoneError: Dispatch<SetStateAction<boolean>>,
  emailError: boolean,
  setEmailError: Dispatch<SetStateAction<boolean>>,
  showPhoneError: boolean,
  setShowPhoneError: Dispatch<SetStateAction<boolean>>,
  showEmailError: boolean,
  setShowEmailError: Dispatch<SetStateAction<boolean>>,
  localStep: PageID,
  shouldEmailBeDisabled?: boolean,
  shouldPhoneBeDisabled?: boolean,
}

export const PhoneAndEmail: React.FC<Props> = ({
  preferredMethodOfContact,
  prospectSource,
  formMethods,
  translatedCountries,
  phonePrefixLabel,
  setPhonePrefixLabel,
  phoneUserInputValue,
  setPhoneUserInputValue,
  emailUserInputValue,
  setEmailUserInputValue,
  phoneError,
  setPhoneError,
  emailError,
  setEmailError,
  showPhoneError,
  setShowPhoneError,
  showEmailError,
  setShowEmailError,
  localStep,
  shouldEmailBeDisabled,
  shouldPhoneBeDisabled,
}) => {
  const { state } = useStateMachine();
  const { featuresActivated } = state;
  if (prospectSource === ProspectSource.NQP_WIRE) {
    return (
      <Email
        emailError={emailError}
        preferredMethodOfContact={preferredMethodOfContact}
        showEmailError={showEmailError}
        emailUserInputValue={emailUserInputValue}
        setEmailUserInputValue={setEmailUserInputValue}
        prospectSource={prospectSource}
        setShowEmailError={setShowEmailError}
        formMethods={formMethods}
        setPhoneError={setPhoneError}
        setEmailError={setEmailError}
        localStep={localStep}
        shouldBeDisabled={shouldPhoneBeDisabled}
      />
    );
  }
  if ((featuresActivated.IS_WIRE_EDIT_FLOW_ACTIVATED || featuresActivated.IS_GUCCI_CREATE_USED) && localStep === PageID.CONTACT) {
    return (
      preferredMethodOfContact === PreferredMethodOfContact.PHONE
        ? (
          <Phone
            phoneError={phoneError}
            preferredMethodOfContact={preferredMethodOfContact}
            phonePrefixLabel={phonePrefixLabel}
            setPhonePrefixLabel={setPhonePrefixLabel}
            translatedCountries={translatedCountries}
            showPhoneError={showPhoneError}
            phoneUserInputValue={phoneUserInputValue}
            setPhoneUserInputValue={setPhoneUserInputValue}
            formMethods={formMethods}
            setPhoneError={setPhoneError}
            setEmailError={setEmailError}
            setShowPhoneError={setShowPhoneError}
            localStep={localStep}
            shouldBeDisabled={shouldPhoneBeDisabled}
          />
        )
        : (
          <Email
            emailError={emailError}
            preferredMethodOfContact={preferredMethodOfContact}
            showEmailError={showEmailError}
            emailUserInputValue={emailUserInputValue}
            setEmailUserInputValue={setEmailUserInputValue}
            prospectSource={prospectSource}
            setShowEmailError={setShowEmailError}
            formMethods={formMethods}
            setPhoneError={setPhoneError}
            setEmailError={setEmailError}
            localStep={localStep}
            shouldBeDisabled={shouldEmailBeDisabled}
          />
        )
    );
  }
  return (
    Utils.isEmailAndPhoneReversed(preferredMethodOfContact)
      ? (
        <>
          <Phone
            phoneError={phoneError}
            preferredMethodOfContact={preferredMethodOfContact}
            phonePrefixLabel={phonePrefixLabel}
            setPhonePrefixLabel={setPhonePrefixLabel}
            translatedCountries={translatedCountries}
            showPhoneError={showPhoneError}
            phoneUserInputValue={phoneUserInputValue}
            setPhoneUserInputValue={setPhoneUserInputValue}
            formMethods={formMethods}
            setPhoneError={setPhoneError}
            setEmailError={setEmailError}
            setShowPhoneError={setShowPhoneError}
            localStep={localStep}
            shouldBeDisabled={shouldPhoneBeDisabled}
          />
          <Email
            emailError={emailError}
            preferredMethodOfContact={preferredMethodOfContact}
            showEmailError={showEmailError}
            emailUserInputValue={emailUserInputValue}
            setEmailUserInputValue={setEmailUserInputValue}
            prospectSource={prospectSource}
            setShowEmailError={setShowEmailError}
            formMethods={formMethods}
            setPhoneError={setPhoneError}
            setEmailError={setEmailError}
            localStep={localStep}
            shouldBeDisabled={shouldEmailBeDisabled}
          />
        </>
      )
      : (
        <>
          <Email
            emailError={emailError}
            preferredMethodOfContact={preferredMethodOfContact}
            showEmailError={showEmailError}
            emailUserInputValue={emailUserInputValue}
            setEmailUserInputValue={setEmailUserInputValue}
            prospectSource={prospectSource}
            setShowEmailError={setShowEmailError}
            formMethods={formMethods}
            setPhoneError={setPhoneError}
            setEmailError={setEmailError}
            localStep={localStep}
            shouldBeDisabled={shouldEmailBeDisabled}
          />
          <Phone
            phoneError={phoneError}
            preferredMethodOfContact={preferredMethodOfContact}
            phonePrefixLabel={phonePrefixLabel}
            setPhonePrefixLabel={setPhonePrefixLabel}
            translatedCountries={translatedCountries}
            showPhoneError={showPhoneError}
            phoneUserInputValue={phoneUserInputValue}
            setPhoneUserInputValue={setPhoneUserInputValue}
            formMethods={formMethods}
            setPhoneError={setPhoneError}
            setEmailError={setEmailError}
            setShowPhoneError={setShowPhoneError}
            localStep={localStep}
            shouldBeDisabled={shouldPhoneBeDisabled}
          />
        </>
      )
  );
};
