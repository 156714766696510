export enum LogMessageEnum {
  RECAPTCHA_SCRIPT_LOADED = 'Recaptcha script loaded',
  RECAPTCHA_SCRIPT_NOT_LOADED = 'Recaptcha script not loaded',
  RECAPTCHA_FUNCTION_READY = 'Execute Recaptcha function ready',
  RECAPTCHA_FUNCTION_NOT_READY = 'Execute Recaptcha function not ready',
  KAKAO_INIT_ERROR = 'Kakao Initialisation failed',
  KAKAO_AUTH_ERROR = 'Kakao Login error',
  KAKAO_GET_USER_ERROR = 'Kakao GET user failed',
  UNEXPECTED_CHECK_RESPONSE = 'Unexpected check response'
}
