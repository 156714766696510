import styled, { css } from 'styled-components/macro';
import theme from 'styled-theming';
import screenSizes from '@utils/screenSizes';
import { WelcomeButton } from '@pages/Welcome/welcome.styles';
import Utils from '@utils/utils';
import { Logo } from '@/common/components';
import Title from '@/common/components/Title';

export const StyledTitle = styled(Title)`
  margin-bottom: 0;
  color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.desktop.color)};
  
  ${theme('brand', {
    AMQ: css`
      font-size: 18pt;
      text-transform: uppercase;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        font-size: 20pt;
      }
    `,
    BV: css`
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        font-size: 30pt;
      }
    `,
    BAL: css`
      font-size: 12pt;
    `,
    GUCCI: css`
      font-size: 26pt;
    `,
    BR: css`
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        font-size: 30pt;
      }
    `,
    YSL: css`
      font-size: 18pt;
      width: 50%;
      text-align: left;
    `,
  })};
  ${theme('language', {
    ar: css`
      text-align: start;
    `,
  })};
`;

export const StyledTitleLuce = styled(StyledTitle)`
  ${theme('brand', {
    GUCCI: css`
      font-size: 22pt;
      color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.luce.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.desktop.color)};
    `,
  })};
`;

export const TextWrapper = styled.div`
  padding: 25px 0;
  
  ${theme('brand', {
    GUCCI: css`
      margin-top: 15vh;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 30vh;
      }
    `,
    AMQ: css`
      margin-top: 20vh;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 30vh;
      }
    `,
    BAL: css`
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 17px;
      margin-bottom: 17px;
      padding: 0 16px;
      text-align: left;
      border-top: 1px solid ${(props) => props.theme.colors.REGISTERED.textWrapper.borderTopColor};
      border-bottom: 1px solid ${(props) => props.theme.colors.REGISTERED.textWrapper.borderBottomColor};
      height: 88px;
    `,
    BR: css`
      margin-top: 20vh;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 30vh;
      }
    `,
    BV: css`
      width: 75%;
      padding: 0;
      margin: 20vh auto 0;
      text-align: center;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 30vh;
      }
    `,
    YSL: css`
      padding: 0 20%;
      margin-top: 25%;
      font-size: 34pt;
      width: 100%;
      text-align: left;
      font-weight: bold;
    `,
  })};
`;

export const UpdateMarketingConsentTextWrapper = styled(TextWrapper)`
  ${theme('brand', {
    BAL: css`
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 0;
      }
      border-top: none;
      border-bottom: none;
    `
  })};
`;

export type Props = {
  isOnFormPage?: boolean
}

export const ClassicText = styled.div<Props>`
  margin-top: 0;
  color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.desktop.color)};
  
  ${theme('brand', {
    AMQ: css`
      margin-top: 10px;
    `,
    BAL: css`
      margin-top: 10px;
    `,
    BV: css`
      margin-bottom: 30px;
    `,
    GUCCI: css`
      font-size: 16pt;
    `,
    YSL: css`
      font-size: 11pt;
      text-align: left;
      margin-top: 10px;
    `,
  })};
  ${theme('language', {
    ar: css`
      text-align: start;
    `,
  })};
`;

export const ThanksTextUpdateMarketingPage = styled(ClassicText)`
  margin-top: 50px;
  font-size: 14pt;
  ${theme('brand', {
    BAL: css`
      margin-top: 10px;
      font-size: 12pt;
    `
  })};
`;

type StyledEventTextProps = {
  visibility: string
}

export const StyledEventText = styled.div.attrs((props: StyledEventTextProps) => ({
  visibility: props.visibility,
}))`
  visibility: ${(props) => props.visibility};
  margin-top: 10px;
  color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.desktop.color)};
`;

export const ConnectWithUsText = styled(ClassicText)`
  ${theme('brand', {
    GUCCI: css`
      padding-right: 10px;
    `,
  })};
`;

export const SaveSalesText = styled.div`
  margin-bottom: 10px;
  color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.desktop.color)};

  ${theme('brand', {
    AMQ: css`
      position: absolute;
      left: 0;
      bottom: 18vh;
      width: 100%;
      padding: 0 50px;
    `,
    BAL: css`
      padding: 0 16px;
      text-align: left;
    `,
    BV: css`
      position: absolute;
      left: 0;
      bottom: 18vh;
      margin: 0 auto;
      width: 100%;
      text-align: center;
    `,
    BR: css`
      position: absolute;
      left: 0;
      bottom: 18vh;
      width: 100%;
      padding: 0 50px;
    `,
    GUCCI: css`
      font-size: 16pt;
      margin-top: 15%;
    `,
    YSL: css`
      text-align: left;
      margin-top: 10px;
      font-size: 11pt;
      font-weight: bold;
      padding: 0 20% 15% 20%;
    `,
  })};
  ${theme('language', {
    ar: css`
      text-align: start;
    `,
  })};
`;

export const SocialNetworks = styled(SaveSalesText)`
${theme('brand', {
    AMQ: css`
      bottom: 10vh;
    `,
    BAL: css`
  
    `,
    BV: css`
      bottom: 10vh;
    `,
    BR: css`
      bottom: 10vh;
    `,
    GUCCI: css`
      text-align: center;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        position: absolute;
        bottom: 30vh;
        margin: 0 auto;
        width: 100%;
      }
    `,
    YSL: css`
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        position: absolute;
        bottom: 5vh;
      }
    `,
  })};
`;

export const StyledLogo = styled(Logo)`
  @media only screen and (min-width : ${screenSizes.ipad.width}){
     margin-top: 0;
  }
  ${theme('brand', {
    YSL: css`
      bottom: 5%;
      font-size: 14pt;
      @media only screen and (min-width : ${screenSizes.ipad.width}){
        margin-top: 70px;
      }
    `,
  })};
`;

export const SaveButton = styled(WelcomeButton)`
  color: ${(props) => props.theme.colors.REGISTERED.saveButton.color};
  background-color: ${(props) => props.theme.colors.REGISTERED.saveButton.backgroundColor};
  ${theme('brand', {
    AMQ: css`
      position: absolute;
      bottom: 10vh;
      left: 0;
      right: 0;
    `,
    BAL: css`
      position: absolute;
      left: 0;
      bottom: 0;
    `,
    BR: css`
      position: absolute;
      bottom: 10vh;
      left: 0;
      right: 0;
      @media only screen and (min-width : ${screenSizes.desktop.width}){
        color: ${(props) => props.theme.colors.REGISTERED.saveButton.desktop.color};
        background-color: ${(props) => props.theme.colors.REGISTERED.saveButton.desktop.backgroundColor};
      }
    `,
    BV: css`
      width: 75%;
    `,
    YSL: css`
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      font-size: 10pt;
      font-weight: bold;
      @media only screen and (min-width : ${screenSizes.desktop.width}){
        left: 50%;
        transform: translateX(-50%);
        max-width: 1024px;
      }
      &:disabled {
        color: ${(props) => props.theme.colors.COMMON.button.form.disabled.color};
        background-color: ${(props) => props.theme.colors.REGISTERED.saveButton.backgroundColor};
        border-right: none;
        border-left: none;
      }
    `,
    GUCCI: css`
      color: ${(props) => props.theme.background?.buttonParameter?.fontColor};
      background-color: ${(props) => props.theme.background?.buttonParameter?.backgroundColor};
      @media only screen and (min-width : ${screenSizes.desktop.width}){
        color: ${(props) => props.theme.colors.REGISTERED.saveButton.desktop.color};
        background-color: ${(props) => props.theme.colors.REGISTERED.saveButton.desktop.backgroundColor};
      }
    `,
  })};
`;

export const RedirectQueueButton = styled(SaveButton)`
  position: relative;
  bottom: 0;
`;

export const SaveWallpaperButton = styled(SaveButton)`
  margin-top: 20px;
`;

export const SocialNetworkLogo = styled.img`
  cursor: pointer;
  height: 60px;
  width: auto;
  margin: 10px;
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    filter: invert(${(props) => ((!props.theme.hasDesktopBackground && !Utils.isSocialNetworkLogosBlack(props.theme.brand)) ? 1 : 0)});
    ${theme('brand', {
    YSL: css`
      filter: none
    ` })};
  }
`;

export const QrCodeContainer = styled.div`
  width: 250px;
  background-color: ${(props) => props.theme.colors.REGISTERED.qrCodeContainer.backgroundColor};
  color: ${(props) => props.theme.colors.REGISTERED.qrCodeContainer.color};
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    width: auto;
    img {
      width: auto;
    }
  }
`;

export const QrCodeImg = styled.img`
  height: auto;
  max-width: 25vh;
`;

export const SAQrCodeImg = styled(QrCodeImg)`
  height: auto;
  max-width: 75vw;
  @media only screen and (max-height : 850px ){
    max-width: 70vw;
  }
`;

export const QrCodeText = styled.div`
  position: relative;
  margin-top: 4vh;
  padding-right: 25px;
  padding-left: 25px;
  text-align: left;
  font-size: 2.1vh;
  line-height: 2.1vh;
  @media only screen and (max-height : 850px ){
    font-size: 2.3vh;
    line-height: 2.3vh;
  }
`;

export const WeChatQrCodeText = styled(QrCodeText)`
  font-size: 2.3vh;
  line-height: 2.3vh;
`;

export const StyledLink = styled.a`
  display: block;
  padding: 10px;
`;

export const DownloadSlAppText = styled.div`
  margin-bottom: 10px;
  color: ${(props) => Utils.setLabelColorBasedOnDevice(props.theme.hasDesktopBackground, props.theme.background?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.color, props.theme.desktopBackground?.textParameter?.fontColor || props.theme.colors.REGISTERED.labelColor.desktop.color)};
  text-align: left;
  margin-top: 50px;
  font-size: 11pt;
  font-weight: bold;
  padding: 0 20%;
`;

export const SlAppContainerLink = styled.a`
  margin: 0px 0px 40px 0px;
  padding: 0 20%;
`;

export const SLAppDownloadLogo = styled.img`
  cursor: pointer;
  height: auto;
  width: 100%;
  border-radius: 0;
`;

export const SAQrCodeContainer = styled.div`
  border-radius: 10px;
  padding: 10px;
  position: absolute;
  top: 10px;
  left: 15px;
  right: 15px;
  bottom: 10px;
  line-height: 30px;
  background-color: ${(props) => props.theme.colors.REGISTERED.qrCodeContainer.backgroundColor};
  color: ${(props) => props.theme.colors.REGISTERED.qrCodeContainer.color};
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    left: unset;
    right: unset;    
    min-width: 70vw;
    max-width: 90vw;
    img {
      width: auto;
    }
  }
  @media only screen and (min-width : ${screenSizes.desktop.width}){
    min-width: unset;
    max-width: unset;
    width: 975px;
    img {
      width: auto;
    }
  }
`;

export const StyledSAName = styled(Title)`
  padding-top: 5vh;
  font-size: 4vh;
  line-height: 4vh;
  text-align: left;
`;

export const SAContactInfoWrapper = styled.div`
  font-size: 2vh;
  color: gray;
  text-align: left;
  align-items: center;
  line-height: 2.5vh;
  padding-top: 3vh;
  padding-bottom: 5vh;
  @media only screen and (max-height : 850px ){
    padding-bottom: 1vh;
    padding-top: 2vh;
  }
`;

export const SAContactIcon = styled.img`
  max-width: 3vh;
  max-height: 3vh;
  padding-right: 5px;
  @media only screen and (min-width : ${screenSizes.ipad.width}){
    width: 1.5vh;
    height: 1.5vh;
  }
`;

export const VcardLogo = styled.img`
  text-align: left;
  display: block;
  padding: 2vh;
  position: absolute;
  bottom: 10px;
  max-width: 50vw;
  max-height: 10vh;
  filter: invert(${(props) => (props.theme.colors.COMMON.isWhiteLogo === true ? 1 : 0)});
`;

export const SaveSAText = styled.div`
  font-size: 2vh;
  padding-left: 2vh;
  padding-right: 2vh;
  padding-top: 1vh;
  line-height: initial;
  @media only screen and (max-height : 850px ){
    padding-top: 0vh;
  }
`;

export const SADescriptionWrapper = styled.div`
  text-align: left;
  align-items: center;
  line-height: 2.5vh;
  padding-top: 3vh;
  @media only screen and (max-height : 850px ){
    padding-top: 2vh;
  }
`;
export const SAClassicText = styled.div`
  font-size: 2vh;
  color: gray;
`;

export const LineOrWeChatQrCodeContainer = styled.div`
  border-radius: 10px;
  position: absolute;
  top: 20px;
  left: 15px;
  right: 15px;
  bottom: 20px;
  line-height: 30px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.REGISTERED.qrCodeContainer.backgroundColor};
  color: ${(props) => props.theme.colors.REGISTERED.qrCodeContainer.color};
`;

export const QrCodeTitle = styled.div`
  font-size: 2.3vh;
  position: relative;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
`;

export const WeChatQrCodeTitle = styled(QrCodeTitle)`
  font-size: 2.3vh;
`;

export const LineQrCodeModalContainer = styled.div`
  margin: auto;
  margin-top: 4vw;
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.colors.REGISTERED.qrCodeContainer.backgroundColor};
  @media only screen and (max-height : 650px ){
    margin-top: 2vw;
  }
`;

export const WeChatQrCodeModalContainer = styled.div`
  margin: auto;
  margin-top: 4vw;
  width: 100%;
  height: auto;
  @media only screen and (max-height : 650px ){
    margin-top: 2vw;
  }
`;

export const LineOrWeChatQrCodeImg = styled.img`
  max-height: 70vh;
  max-width: 70vw;
  padding-left: 2vw;
  padding-right: 2vw;
  @media only screen and (max-height : 650px ){
    max-height: 70vh;
    max-width: 70vw;
  }
`;

export const WeChatQrCodeImg = styled.img`
  max-height: 100%;
  max-width: 100%;
  @media only screen and (max-height : 650px ){
    max-height: 85vh;
    max-width: 85vw;
  }
`;

export const LineOrWeChatQrCodePreviewContainer = styled.div`
  margin: auto;
  margin-top: 10px;
  max-width: 20vw;
  max-height: 20vw;
  background-color: ${(props) => props.theme.colors.REGISTERED.qrCodeContainer.backgroundColor};
  display: flex;
  padding-top: 1vw;
  padding-bottom: 1vw;
`;

export const WeChatQrCodePreviewContainer = styled.div`
  margin: auto;
  margin-top: 10px;
  max-width: 20vw;
  max-height: 20vw;
`;

export const WeChatQrCodePreview = styled.img`
  margin: auto;
  max-width: 20vw;
  max-height: 20vw;
`;

export const LineOrWeChatQrCodePreview = styled.img`
  margin: auto;
  max-width: 18vw;
  max-height: 18vw;
`;

export const SAQrCodePreview = styled.img`
  max-width: 25vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const LineCountryLabelsWrapper = styled.div`
  padding-top: 2vh;
  position: relative;
  top: 2vh;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  @media only screen and (max-height : 650px){
    padding-top: 1vh;
  }
`;

export const LineCountryBtn = styled.button`
  padding-left: 2vw;
  border-style: none;
  background-color: white;
  color: ${(props) => !props.theme.isActive ? 'grey' : 'black'};
  text-decoration: ${(props) => props.theme.isActive && 'underline'};
  font-size: 1.8vh;
`;
