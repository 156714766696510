import React, { ReactNode } from 'react';
import { DropdownLegalPage, DropdownWelcomePage } from '@common/components/dropdown/dropdown.style';
import { TYPE_DROPDOWN } from '@/types';

type DropdownProps = {
  children: ReactNode
}

type Props = {
  children: React.ReactNode,
  type: string
}

const Dropdown: React.FC<Props> = ({ children }: DropdownProps, type) => {
  if (type !== TYPE_DROPDOWN.PRIVACIES) {
    return (
      <DropdownWelcomePage>
        {children}
      </DropdownWelcomePage>
    );
  }
  return (
    <DropdownLegalPage>
      {children}
    </DropdownLegalPage>
  );
};

export default Dropdown;
